<div class="new_UIstyle">
  <!-- <div class="topBarSupport">
    <p>Edu-collab is best supported in Google Chrome and Firefox Latest Version</p>
  </div> -->
  <header class="mainHeader header-shadow" Id="mainHeader" #headerSection>
    <nav class="navbar navbar-expand-xl">
      <div class="container">
        <div class="nav-elem headerLeft navbar-site-logo">
          <a class="navbar-brand" [routerLink]="['/']"><img src="../../assets/images/logo.png"
              alt="logo.png" /></a>
          <button class="navbar-toggler " (click)="mainMenuClickEvent()" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbarTogglerIcon"></span>
            <span class="navbarTogglerIcon"></span>
            <span class="navbarTogglerIcon"></span>
          </button>
        </div>


        <div class="nav-elem headerCenter collapse navbar-collapse" id="navbarSupportedContent"
          [ngClass]="sidebarMainMenu ? 'sidemenu-active' : 'sidemenu-deActive'">
          <button class="sidebar-close-btn d-block d-xl-none" (click)="mainMenuClickEvent()"><i
              class="fas fa-times"></i></button>
          <div class="nav-elem-mnu-list" *ngIf="loginFLagValue">
            <ul class="navbar-nav me-2">
              <li ngbDropdown class="dropdown nav-item" *ngIf="role == 'student'">
                <a class="category-link nav-link" ngbDropdownToggle id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false" [routerLinkActive]="['active']" routerLinkActive="active">Categories</a>
                <div ngbDropdownMenu class="dropdown-menu  dropdown-menu-regular dropdown-menu-start mt-xl-4 mt-0">
                  <ul class="dropdown-nav" >
                    <li ngbDropdown class="dropdown" [ngClass]="catItem.subChild.length == 0 ? '' : 'dropdown'"
                      *ngFor="let catItem of sotedCategory">
                      
                      <a ngbDropdownToggle data-bs-toggle="dropdown" class="dropdown_item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                        [ngClass]="catItem.subChild.length == 0 ? 'dropdown-toggle-none dropdown-item' : 'dropdown-toggle'" 
                        (click)="onTopChangeCategory(catItem.cat_id)">{{catItem.parentName }}</a>
                      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-start"
                        *ngIf="catItem.subChild.length > 0">
                        <ul>
                          <li ngbDropdown class="dropdown" *ngFor="let catSubItem of catItem.subChild"
                            [ngClass]="catSubItem.grandChild.length == 0 ? '' : ''">
                            
                            <a  ngbDropdownToggle data-bs-toggle="dropdown"  class="dropdown_item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                              [ngClass]="catSubItem.grandChild.length == 0 ? 'dropdown-toggle-none dropdown-item' : 'dropdown-toggle'" 
                              (click)="onTopChangeCategory(catSubItem.cat_id)">{{catSubItem.subChildName }}</a>
                            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-regular dropdown-menu-start"
                               *ngIf="catSubItem.grandChild.length > 0">
                              
                              <ul>
                                <li *ngFor="let catGrandItem of catSubItem.grandChild">                                  
                                  <a class="dropdown-item" (click)="onTopChangeCategory(catGrandItem.cat_id)">{{ catGrandItem.grandChildName }}</a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" *ngIf="role !== 'mentor'">
                <div class="category-search-block">
                  <form class="input-with-icon input-with-icon-right category-search-form">
                    <input type="search" (input)="onSearchTextChanged($event)" [(ngModel)]="search_text"
                      name="search_text" autocomplete="off" placeholder="What are you looking for ?"
                      class="form-control form-control-md" #searchInput />
                    <button class="input-with-icon-btn category-search-btn"><i class="fas fa-search"></i></button>
                  </form>
                </div>
              </li>
            </ul>
            <ul class="navbar-nav">

              <li ngbDropdown class="nav-item" *ngIf="role != 'student' && role !== 'mentor'">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle *ngIf="role == 'teacher'" [routerLinkActive]="['active']" routerLinkActive="active">My Courses</a>
                <a class="nav-link dropdown-toggle" ngbDropdownToggle *ngIf="role != 'student' && role != 'teacher'" [routerLinkActive]="['active']" routerLinkActive="active">Courses</a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" routerLink="/draft-courses" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Draft Courses</a>
                  <a class="dropdown-item" routerLink="/published-courses" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Published Courses</a>
                  <a class="dropdown-item" routerLink="/courses/ec-course-board" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Archived Courses</a>
                  <!-- <a *ngIf="!isProduction" class="dropdown-item" routerLink="/courses/add" routerLinkActive="active">Add Course</a> -->
                </div>
              </li>
              <li ngbDropdown class="nav-item" *ngIf="role == 'student'">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle [routerLinkActive]="['active']" routerLinkActive="active">Courses</a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" routerLink="/courses/ec-course-board" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Archived Courses</a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="navigate_to('/passion-projects')" routerLink="/passion-projects" [routerLinkActive]="['active']" routerLinkActive="active">The Mosaic</a>
              </li>
              <li class="nav-item" *ngIf="(role == 'student' || role === 'super_admin')">
                <a class="nav-link" routerLink="/mentorship" (click)="navigate_to('/mentorship')" [routerLinkActive]="['active']" routerLinkActive="active">The Counsel</a>
              </li>

              <li ngbDropdown class="nav-item dropdown" 
                *ngIf="findIndexOf(permissions, 'user_management') > -1 &&  role !== 'mentor'">
                <a class="nav-link dropdown-toggle" id="questDropdown" ngbDropdownToggle [routerLinkActive]="['active']" routerLinkActive="active">
                  Management
                </a>
                <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="questDropdown">
                  <a class="dropdown-item" routerLink="/manage-user" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Manage Users</a>
                  <a class="dropdown-item" routerLink="/manage-role" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Manage Roles</a>
                  <a class="dropdown-item" routerLink="/manage-categories" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Manage
                    Categories</a>
                </div>
              </li>

              <li ngbDropdown class="nav-item dropdown" routerLinkActive="active"
                *ngIf="findIndexOf(permissions, 'question_bank') > -1 &&  role !== 'mentor'">
                <a class="nav-link dropdown-toggle" id="questDropdown" ngbDropdownToggle [routerLinkActive]="['active']" routerLinkActive="active">
                  Resources
                </a>
                <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="questDropdown">
                  <a class="dropdown-item" routerLink="/question-bank" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Question Bank</a>
                  <a class="dropdown-item" routerLink="/retrieve-assessment" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Assessment</a>
                  <a class="dropdown-item" routerLink="/courses/search-coursework" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Course Work</a>
                  <a class="dropdown-item" routerLink="/resource-bank" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Resource Bank</a>
                </div>
              </li>

              <li class="nav-item"  *ngIf="findIndexOf(permissions, 'student_questions') > -1 &&  role !== 'mentor'" >
                <a class="nav-link"  routerLink="/Student-Questions"  [routerLinkActive]="['active']" routerLinkActive="active">Student Questions</a>
              </li>

              

              <li ngbDropdown class="nav-item dropdown student-dropdown" *ngIf="role == 'student'">
                <a class="nav-link" [routerLinkActive]="['active']" routerLinkActive="active" ngbDropdownToggle id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">Student Corner</a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-student dropdown-menu-end"
                  aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" (click)="navigate_to('/all-questions')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">All Questions</a>
                  <a class="dropdown-item" (click)="navigate_to('/recommended-questions')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Recommended Questions</a>
                  <a class="dropdown-item" (click)="navigate_to('/My-Questions')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">My Questions</a>
                  <a class="dropdown-item" (click)="navigate_to('/my-answers')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">My Answers</a>
               </div>
           </li>
           <li ngbDropdown class="nav-item dropdown" >
            <a class="nav-link dropdown-toggle" id="moreDropDown" ngbDropdownToggle [routerLinkActive]="['active']" routerLinkActive="active">More</a>
            <div class="dropdown-menu dropdown-menu-more dropdown-menu-end" ngbDropdownMenu>
              <!-- <a class="dropdown-item" routerLink="/addUser" routerLinkActive="active" *ngIf="role == 'super_admin'">Add User</a>
              <a class="dropdown-item" routerLink="/bulk-upload" routerLinkActive="active" *ngIf="role == 'super_admin'">Bulk Upload</a> -->
              <a class="dropdown-item" routerLink="/report" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Report</a>
              <a class="dropdown-item" routerLink="/report-list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="role == 'super_admin'">Report-List</a>
              <a class="dropdown-item" routerLink="/search_organisation" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="role == 'super_admin'">Organization</a>
            </div>
            </li>
            
          </ul>
        </div>
      </div>
      <div class="backlog-sidebar" [ngClass]="sidebarMainMenu ? 'backlog-active' : 'backlog-deActive'"></div>
      <div class="nav-elem headerRight">
        <a *ngIf="!loginFLagValue" routerLink="/login" 
          class="btn btn-primary btn-w-120 login-btn">Login</a>
        <div class="header-icon-list" *ngIf="loginFLagValue">
          <ul>
            <li *ngIf="role == 'student' || role == 'teacher' || role == 'mentor'"><a class="header-icon-link" [routerLink]="['/schedules']"><i class="fas fa-calendar-week"></i></a></li>
            <li><a class="header-icon-link" routerLink="/guftagu" (click)="resetMessageCount()"><i class="far fa-comment-dots"></i> 
            </a></li>
            <!-- Notification section |start -->
            <li ngbDropdown class="dropdown notification-dropdown toggle-none">
              <a href="javascript:void(0);" (click)="getLastestNotify();" class="header-icon-link" ngbDropdownToggle
                id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-bell"></i> <span
                  class="badge badge-round-vh-center badge-primary" *ngIf="notifyCount > 0">{{notifyCount > 99 ? '99+':notifyCount
                  }}</span></a>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-notification dropdown-menu-end"
                aria-labelledby="navbarDropdown">
                <div class="dropdown-title d-flex align-items-center justify-content-between">
                  <h6 class="primary-text mb-0 avenirBook">Notifications</h6>
                  <a [routerLink]="['/notifications']" class="blue-text dropdown-item no_style_dropdown_item"
                    *ngIf="currentUrl !== '/notifications'">See all</a>
                </div>
                <hr />
                <div class="drop-items-wrap notification-items-wrap ">
                  <div class="text-center" *ngIf="listLoader">
                    <div class="spinner-border text-warning formLoader" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="mb-1" *ngFor="let notification of notificationList; index as i;">
                    <div class="drop-item-list notification-items-list notification-items-unread"
                      [ngClass]="notification.read_status == false ? 'notification-items-unread':''">

                      <ng-container *ngIf="notification.type == 'chat_request'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_guftagu" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You have received a friend request from <a class="dropdown-item" href="javascript:void(0);"
                                  (click)="redirectTo(notification)"><b>{{notification.content.sender_name}}</b></a></p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'chat_invite'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_guftagu" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p><a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.sender_name}} </strong></a> has <span *ngIf="notification.subtype == 'reject'">rejected</span> <span *ngIf="notification.subtype == 'accept'">accepted</span> your request.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'TA_assignment'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You have been added Teaching Assistant to this <a href="javascript:void(0);" class="dropdown-item"
                                  (click)="redirectTo(notification)"><strong>{{notification.content.subject}}</strong></a> course.
                              </p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'topic'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Topic has added/updated to this course <a href="javascript:void(0);" class="dropdown-item"
                                  (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'question'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You have received an answer to your question <a href="javascript:void(0);"  class="dropdown-item"
                                  (click)="redirectTo(notification)"><strong>{{notification.content.question  | slice:0:13}}</strong></a>
                                {{notification.content.question.length > 10 ? '...':''}}</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'course_coursework'">
                        
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Coursework <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.coursework_name}}</strong></a> has been added/updated to this <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a> course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'coursework'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You have only one day left for submission for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.title}}</strong></a> in
                                <a href="javascript:void(0);"  (click)="redirectTo(notification)">
                                  <strong>{{notification.content.course_name}}</strong></a> course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'coursework_invite'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p *ngIf="notification.subtype === 'team_update'">Your <a href="javascript:void(0);"class="dropdown-item"  (click)="redirectTo(notification)"><strong>{{notification.content.courseworktitle}}</strong></a> team has been updated. </p>
                              <p *ngIf="notification.subtype === 'added'">You have been invited to join <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.courseworktitle}}</strong></a> group.</p>
                              <p *ngIf="notification.subtype === 'invite'">You have been invited to join <a href="javascript:void(0);" class="dropdown-item"  (click)="redirectTo(notification)"><strong>{{notification.content.courseworktitle}}</strong></a> group.</p>
                              <p *ngIf="notification.subtype === 'accept'"><a  class="noCursor"><strong>{{ notification.content.invite_to?.name || notification.content.invite_to?.username }}</strong></a> has accepted the request to join the  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.courseworktitle}}</strong></a> group.</p>
                              <p *ngIf="notification.subtype === 'reject'"><a class="noCursor"><strong>{{ notification.content.invite_to?.name || notification.content.invite_to?.username }}</strong></a> has rejected the request to join the  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.courseworktitle}}</strong></a> group.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'course_assessment'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Assessment <a href="javascript:void(0);" class="dropdown-item"  (click)="redirectTo(notification)"><strong>{{notification.content.assessment_name}}</strong></a> has been added/updated to this <a href="javascript:void(0);"  (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'assessment'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Assessment <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.title}}</strong></a> has been added/updated to this <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'announcement'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_course" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p><a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.announcement}}</strong></a>
                                announcement has been published in <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a>
                                course.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'mosaic_team'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_project" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Your  <a href="javascript:void(0);" class="dropdown-item"
                                (click)="redirectTo(notification)"><strong> {{notification.content.team_name}}</strong></a> Mosaic team has been added/updated.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="notification.type == 'changes_by_admin'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_profile" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Admin has updated your profile. Please  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>click</strong></a> to view your profile.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container  *ngIf="notification.type == 'TA_students'">
                        <div class="drop-item-list-link notification-items-list-link">
                        <div class="drop-item-list-left">
                          <figure class="drop-item-list-figure">
                            <img [src]="default_svg_course" alt="Avtar Image" />
                          </figure> 
                          <div class="drop-item-list-content">
                            <p>You have assigned students from <strong>{{notification.content.sender_name}}</strong> for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a> marking tasks.</p>
                            <p><small>{{notification.created_at | date:'short'}}</small></p>
                            <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                          </div>
                        </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'AI_assessment'">
                        <div class="drop-item-list-link notification-items-list-link">
                        <div class="drop-item-list-left">
                          <figure class="drop-item-list-figure">
                            <img [src]="default_svg_course" alt="Avtar Image" />
                          </figure> 
                          <div class="drop-item-list-content">
                            <p>AI-generated assessment <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.assessment_title}}</strong></a> has been created for  <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a> -> <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.topic_title}}/{{notification.content.session_title}}</strong></a> for your review.</p>
                            <p><small>{{notification.created_at | date:'short'}}</small></p>
                            <!-- <p><small>Trends in Cloud Computing and DevOps for 2022 Powered by Restream</small></p> -->
                          </div>
                        </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'marked_coursework'">
                        <div class="drop-item-list-link notification-items-list-link">
                        <div class="drop-item-list-left">
                          <figure class="drop-item-list-figure">
                            <img [src]="default_svg_course" alt="Avtar Image" />
                          </figure> 
                          <div class="drop-item-list-content">
                            <p>Your scores for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a> - <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.coursework_name}}</strong></a> Coursework are published.</p>
                            <p><small>{{notification.created_at | date:'short'}}</small></p>
                           </div>
                          </div>
                        </div>
                      </ng-container>


                      <ng-container  *ngIf="notification.type == 'marked_assessment'">
                        <div class="drop-item-list-link notification-items-list-link">
                        <div class="drop-item-list-left">
                          <figure class="drop-item-list-figure">
                            <img [src]="default_svg_course" alt="Avtar Image" />
                          </figure> 
                          <div class="drop-item-list-content">
                            <p>Your scores for <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.course_name}}</strong></a> - <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.assessment_name}}</strong></a> Assessment are published.</p>
                            <p><small>{{notification.created_at | date:'short'}}</small></p>
                           </div>
                          </div>
                        </div>
                      </ng-container>


                      <ng-container *ngIf="notification.type == 'calendar_reminders_or_tasks'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_calender" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You have received a notification for this event - <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>{{notification.content.title}}</strong></a></p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <!-- For notify mentor about session visibility -->
                      <ng-container *ngIf="notification.type == 'mantee_session_visibility'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_profile" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Your conversation recording on the topic <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)">
                                <strong>{{notification.content?.session_title}}</strong></a>
                                on <strong>{{notification.content?.sess_date}}</strong> at <strong>{{notification.content?.sess_time}}</strong>
                                has been set to <strong>{{notification.subtype == 'public' ? 'public' : 'private'}}</strong> by <strong>{{notification.content?.mantee_name}}</strong>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                       <!-- For notify mentor about mentee call request -->
                       <ng-container *ngIf="notification.type == 'mentor_session_booking'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_profile" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>A call request has been sent by <strong>{{notification.content?.mentee_name}}</strong> regarding 
                                <strong>{{notification.content?.title}}</strong> for  
                                <strong>{{notification.content?.start_date}}</strong>. 
                                Please check your <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>calendar</strong></a>.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                       <!-- For notify mentee about mentor reject the call request -->
                       <ng-container *ngIf="notification.type == 'mentor_session'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_profile" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>{{notification.content?.message}}</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                        <!-- For notify mentee/Mentor about call reminder -->
                        <ng-container *ngIf="notification.type == 'mentor_session_reminder'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_profile" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>Call with <b>{{role === 'student' ? notification.content?.mentor_name : (role === 'teacher' || role === 'mentor') ? notification.content?.mentee_name :'' }} </b> is about to start in 10 mins. Please check the <a href="javascript:void(0);" class="dropdown-item" (click)="redirectTo(notification)"><strong>calendar</strong></a> for the call link.</p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <!-- For notify mentee/Mentor about call reminder -->
                      <ng-container *ngIf="notification.type == 'mentor_session_feedback'">
                        <div class="drop-item-list-link notification-items-list-link">
                          <div class="drop-item-list-left">
                            <figure class="drop-item-list-figure">
                              <img [src]="default_svg_profile" alt="Avtar Image" />
                            </figure>
                            <div class="drop-item-list-content">
                              <p>You have received a feedback on <b>{{notification?.content?.title}}</b> conducted on <b>{{notification?.content?.date_time}}</b></p>
                              <p><small>{{notification.created_at | date:'short'}}</small></p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </div>

                  <div class="text-center" *ngIf="notificationList.length === 0">
                    <p><b>There is no notification available</b></p>
                  </div>

                </div>
              </div>
            </li>
            <!-- Notification section |end -->
            <li ngbDropdown class="dropdown account-profile-blk" >
              <a class="profile-fig header-profile-btn bg-primary" ngbDropdownToggle id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" (click)="setCoinEvent()">
                <img  *ngIf="userLogo.length > 0"  class="header-profile-img" [src]="userLogo" alt="" />
                <figcaption class="d-flex align-items-center justify-content-center h-100" 
                *ngIf="userLogo.length == 0">
                <h5 class="m-0 avenirBook primary-text">{{userFirstName[0] | uppercase}}</h5></figcaption>
              </a>

              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-user-profile dropdown-menu-end">
                <div class="drop-items-wrap user-profile-items-wrap user_right">
                  <div class="drop-item-list user-profile-items-list">
                    <div class="w-100 drop-item-list-left align-items-center">
                      <figure  class="drop-item-list-figure bg-primary">
                        <img *ngIf="userLogo.length > 0" [src]="userLogo" alt="" />
                        <figcaption class="d-flex align-items-center justify-content-center h-100" 
                        *ngIf="userLogo.length == 0">
                        <h4 class="m-0 avenirBook primary-text">{{userFirstName[0] | uppercase}}</h4></figcaption>
                      </figure>
                      <div class="drop-item-list-content">
                        <h6 class="primary-text mb-1 avenirBlack">{{userFirstName}} {{userLastName}}</h6>
                        <p><small>{{Email_ID}}</small></p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <ul class="dropdown-nav">
                    <!-- <li><a class="dropdown-item" routerLink="/courses"  *ngIf="role !== 'mentor'">My Learning</a></li> -->
                    <li><a class="dropdown-item" routerLink="/home" *ngIf="role !== 'mentor'">Dashboard</a></li>
                    <li *ngIf="findIndexOf(permissions, 'show_coins') > -1 &&  role !== 'mentor'">
                      <div class="coins-wrap"><strong class="black-text">Edu Coins</strong> <span class="coins-count"><strong
                            class="primary-text">{{allCoins}} </strong><img
                            src="../../assets/images/apps-icon/coins.png" alt="coins.png" /></span></div>
                    </li>
                  </ul>
                  <hr *ngIf="role !== 'mentor'"/>
                  <ul class="dropdown-nav">
                    <li><a class="dropdown-item" (click)="navigate_to('/guftagu')">Guftagu</a></li>
                  </ul>
                  <hr />
                  <ul class="dropdown-nav">
                    <li><a class="dropdown-item" style="cursor: pointer;" routerLink="/public-profile">Public Profile</a></li>
                    <li><a class="dropdown-item" style="cursor: pointer;" routerLink="/zoom-integration">Apps</a></li>
                  </ul>
                  <hr />
                  <ul class="dropdown-nav">
                    <li><a class="dropdown-item" (click)="signout()">Logout</a></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
</div>
</nav>
</header>
</div>

