<div class="cwBoxOuter projView" [ngClass]="{submitting : loader || zipDownloading}">

  <div class="spinner-border text-warning formLoader mb-2 mt-4" role="status"  *ngIf="loader || zipDownloading">
    <span class="sr-only">Loading...</span>
  </div>

  <!-- UPLOAD WORK -->
  <button type="button" class="btn p-0 hdrIco btn btn-grey-primary btn-shadow btn-icon-text btn_uplo mt-4"
  *ngIf="(isUploadWorkAllowed && !isCourseWorkExpired() && !isSubmissionLength && late_submission === false && !isCW_group && course_active)">
    <label for="imageUpload" class="upload-work mb-0">
      <i class="fas fa-cloud-upload-alt"></i>
      <strong class="ml-2">Upload Work</strong>
    </label>
    <input id="imageUpload" type="file" (change)="upload_resource($event,'course',courseworks.schedule_id,courseworks._id,showCourseWork.submission_report,courseworks.date,showCourseWork.days_of_completion)" class="d-none">
  </button>

    <!-- UPLOAD WORK -->
    <button type="button" class="btn p-0 hdrIco btn btn-grey-primary btn-shadow btn-icon-text btn_uplo mt-4"
    *ngIf="(isUploadWorkAllowed && isCourseWorkExpired() && (late_submission === true || viewSubmissionData.length === 0)  && !isCW_group && course_active)">
      <label for="imageUpload" class="upload-work mb-0">
        <i class="fas fa-cloud-upload-alt"></i>
        <strong class="ml-2">Upload Work</strong>
      </label>
      <input id="imageUpload" type="file" (change)="upload_resource($event,'course',courseworks.schedule_id,courseworks._id,showCourseWork.submission_report,courseworks.date,showCourseWork.days_of_completion)" class="d-none">
    </button>

  <button type="button" class="btn p-0 hdrIco btn btn-grey-primary btn-shadow btn-icon-text btn_uplo mt-4"
  *ngIf="isUploadWorkAllowed && isCW_group && course_active">
    <label for="imageUpload" class="upload-work mb-0">
      <i class="fas fa-cloud-upload-alt"></i>
      <strong class="ml-2">Upload Work</strong>
    </label>
    <input id="imageUpload" type="file" (change)="upload_resource($event,'course',courseworks.schedule_id,courseworks._id,showCourseWork.submission_report,courseworks.date,showCourseWork.days_of_completion)" class="d-none">
  </button>

  <!-- UPLOADED FILE PROGRESS -->
  <div class="uploadedItemTop" *ngIf="progressBar > 0">
      <div class="uploadedItemDetail">
          <p class="uploadedItemStatus">
            <small>{{progressBar}}% Complete</small>
          </p>
      </div>
      <div class="uploadedItemAction">
      </div>
  </div>
  <div class="uploadedItem">
    <div class="progress uploadedProgress" *ngIf="progressBar > 0">
      <div class="progress-bar uploadedProgressBlue"
      role="progressbar" [style.width.%]="progressBar" aria-valuenow="progressBar"
      aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>

  <!-- UPLOADED WORK -->
  <div class="learning-course-items-wrap learning-course-resources-wrap" *ngIf="uploadedWorkFiles.length > 0">
    <div class="text-right mt-4">
      <h6 class="primary-text avenirBlack mb-0 mt-2 float-left">Uploaded Work</h6>
      <button type="button" class="btn btn-grey-primary btn-icon-text pt-2 pb-2 mb-2" (click)="getZipFile()" [disabled]="zipDownloading == true || uploadedWorkFiles.length == 0">
        <i class="fa fa-download" aria-hidden="true"></i><strong class="ml-2">Download All</strong>
      </button>
    </div>

    <div class="learning-course-item"
        *ngFor="let submissions of uploadedWorkFiles; let i=index">
      <div class="learning-course-link">
        <div class="learning-course-elem-left">
            <p class="learning-course-link-title">
              <span class="title-icon"><i class="fas fa-folder"></i></span>
              <strong ngbTooltip="{{submissions.timestamp}}" placement="top">{{submissions.filename}}</strong>
            </p>
        </div>
        <div class="learning-course-elem-right">
          <ul class="list-icon-wrap">
            <li>
              <a class="learning-course-download" (click)="openUploadedWork(submissions.file_path)">
                <i class="fas fa-download"></i>
              </a>
            </li>
            <li *ngIf="isFileDeleteAllowed" (click)="deleteUploadedWork(courseId,courseworks.schedule_id,courseworks._id,submissions.file_id,showCourseWork.submission_report,reportIndex,courseworks.team_info[0]?.name || '')">
              <a><i class="fas fa-trash-alt"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>