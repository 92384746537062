import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import * as fileSaver from 'file-saver';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-course-work-test-review-peer',
  templateUrl: './course-work-test-review-peer.component.html',
  styleUrls: ['./course-work-test-review-peer.component.scss']
})
export class CourseWorkTestReviewPeerComponent implements OnInit {

  @Input() courseworks: any;
  @Input() peerSubmissionReportData: any;
  @Input() findedIndex: number;
  @Input() findedPeerIndex: number;
  @Input() viewSubmissionData: any[];
  @Input() submissionData: any;
  @Input() showCourseWork: any;
  @Input() courseId: any;
  @Input() course_active:any;
  @Output() sendPeerReviewSavedStatus = new EventEmitter();

  processLoader: boolean = false;
  role: string = '';
  loggedInUserId: string = '';

  peerReviewForm: FormGroup[] = [];
  peerSubmissionReportDetail: any[] = [];
  uploadedWorkFiles: any[] = [];
  peerReviewReport: any = '';
  submissionPublishStatus: boolean = false;
  editReviewType: string = '';
  editPeerReviewIndex: any[] = [];
  viewPeeerSubmissionData: any = {};

  zipDownloading: boolean = false;
  submissionReportUserId: string = '';
  submissionReportUsername: string = '';

  isSubmissionDataLoading: boolean = false;

  constructor(private fb: FormBuilder,
    private courseService: CourseService,
    private notifyService: NotificationService,
    private renderer: Renderer2) { }

  ngOnInit() {
    //this.createPeerReviewForm();
  }

  createPeerReviewForm(idx) {
    this.peerReviewForm[idx] = this.fb.group({
      review: ['', [Validators.required, Validators.maxLength(2000)]],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
    //FOR EDIT REVIEW FORM
    for(let idx = 0; idx < changes.peerSubmissionReportData.currentValue.length; idx++) {
      this.createPeerReviewForm(idx);
      this.editPeerReviewIndex[idx] = false;
    }
    this.peerSubmissionReportDetail = changes.peerSubmissionReportData.currentValue;
    //console.log(this.peerSubmissionReportDetail);
    this.manageSubmissionReport();
    this.isSubmissionDataLoading = false;
  }

  manageSubmissionReport() {
    this.submissionPublishStatus = this.submissionData.submissionPublish;
  }

  onSaveReview(peerReviewIndex, viewPeeerSubmissionData) {
    //console.log(this.peerReviewForm[peerReviewIndex]);
    if (!this.validateForm(peerReviewIndex)) {
      return;
    }
    this.viewPeeerSubmissionData = viewPeeerSubmissionData;
    const review = this.peerReviewForm[peerReviewIndex].value.review;
    this.saveReview(review, 'peer_review', peerReviewIndex);
  }

  onEditReview(editReviewType, peerSubmissionData, peerReviewIndex, viewPeeerSubmissionData) {
    this.resetEditReviewForms();
    this.editReviewType = editReviewType;
    this.editPeerReviewIndex[peerReviewIndex] = true;
    this.viewPeeerSubmissionData = viewPeeerSubmissionData;
    if (this.editReviewType == 'peer_review') {
      this.peerReviewReport = '';
      if (peerSubmissionData.peer_review.content) {
        this.peerReviewReport = peerSubmissionData.peer_review.content;
      }
      this.peerReviewForm[peerReviewIndex].get('review').setValue(this.peerReviewReport);
      setTimeout(() => { this.renderer.selectRootElement('#peerReviewInputBox_'+peerReviewIndex).focus(); }, 100);
    }
  }

  resetEditReviewForms() {
    for(let idx = 0; idx < this.editPeerReviewIndex.length; idx++) {
      this.peerReviewForm[idx].reset();
      this.editPeerReviewIndex[idx] = false;
    }
  }

  saveReview(review, reviewType, peerReviewIndex) {

    const submissionInfo = this.viewPeeerSubmissionData;
    const submissionReportUserId = this.viewPeeerSubmissionData.user_id;
    const submissionReportName = this.showCourseWork.submission_report;
    let submissionReportData = this.viewPeeerSubmissionData.submissions[submissionReportName];

    if (submissionReportData.files.length == 0) {
      this.notifyService.showNotification('info', 'User has not submitted any file for this submission');
      return;
    }

    if (this.submissionPublishStatus == true && this.role == 'student') {
      this.notifyService.showNotification('info', 'Submission is published. No permission to edit it now.');
      return;
    }

    if (reviewType == 'peer_review') {
      submissionReportData.peer_review = { content: review }
    }

    let grade = '0';
    let feedback = '';
    let peerReview = '';
    let selfReview = '';

    let teamName = '';
    if (this.courseworks.is_group) {
      teamName = submissionInfo.name;
    }

    if (reviewType == 'peer_review') {
      peerReview = submissionReportData.peer_review.content ? submissionReportData.peer_review.content : '';
    }

    let body = {
      user_id: submissionReportUserId,
      team_name: teamName,
      course_work_id: this.courseworks._id,
      schedule_id: this.courseworks.schedule_id,
      course_id: this.courseId,
      grade: grade,
      feedback: feedback,
      submission_name: submissionReportName,
      submission_index: '' + submissionReportData.index,
      peer_review: peerReview,
      self_review: selfReview
    }

    this.processLoader = true;
    this.courseService.add_remark_grade_course_work(body).subscribe(view_remark => {
      this.isSubmissionDataLoading = true;
      this.sendPeerReviewSavedStatus.emit({ status: true });
      this.peerReviewForm[peerReviewIndex].reset();
      this.processLoader = false;
      this.notifyService.showNotification('success', "Review saved successfully.");
    },
      (err) => {
        this.processLoader = false;
        this.notifyService.showNotification('error', err.message);
      });
  }

  validateForm(peerReviewIndex) {
    this.peerReviewForm[peerReviewIndex].get('review').setValue(this.peerReviewForm[peerReviewIndex].get('review').value.trim());
    if (this.peerReviewForm[peerReviewIndex].controls['review'].invalid) {
      if (this.peerReviewForm[peerReviewIndex].get('review').hasError('required')) {
        this.notifyService.showNotification("error", "Review can not be empty.");
        return false;
      }
      if (this.peerReviewForm[peerReviewIndex].get('review').hasError('maxlength')) {
        this.notifyService.showNotification("error", "Review may not be greater than 2000 characters.");
        return false;
      }
      return false;
    }

    return true;
  }

  onDiscardReview(peerReviewIndex) {
    this.editReviewType = '';
    this.editPeerReviewIndex[peerReviewIndex] = false;
    this.peerReviewForm[peerReviewIndex].reset();
  }

  openUploadedWork(url) {
    window.open(url, '_blank');
  }

  //DOWNLOAD ZIP FILE |START
  getZipFile(viewPeeerSubmissionData) {

    //this.uploadedWorkFiles = viewPeeerSubmissionData.files;
    const submissionReportUserId = viewPeeerSubmissionData.user_id;
    const submissionReportUsername = viewPeeerSubmissionData.name;

    let team_name = '';
    let username = submissionReportUsername;
    if (this.courseworks.is_group) {
      if (this.courseworks.team_info.length) {
        team_name = submissionReportUsername;
        username = team_name;
      }
    }
    this.zipDownloading = true;
    this.courseService.get_zip_submission(this.courseworks._id, this.courseworks.schedule_id, submissionReportUserId, team_name).subscribe(get_zip => {
      this.zipDownloading = false;
      let d = new Date();
      let fullDate: any = this.appendZero(d.getDate()) + '-' + this.appendZero((d.getMonth() + 1)) + '-' + d.getFullYear() + '_' + this.appendZero(d.getHours()) + this.appendZero(d.getMinutes()) + this.appendZero(d.getSeconds());
      let zip_file: any = new Blob([get_zip.body], { type: 'application/zip' });
      let fileName = username + '_' + fullDate + '.zip';
      fileSaver.saveAs(zip_file, fileName);
      this.notifyService.showNotification('success', 'Your Zip file is downloading')
    },
      (err) => {
        this.zipDownloading = false;
        if (err.message == undefined) {
          this.notifyService.showNotification('error', 'No submission is present');
          return;
        }
      });
  }

  appendZero(val) {
    if (val < 10) {
      return '0' + val;
    }
    return val;
  }
  //DOWNLOAD ZIP FILE |END

}
