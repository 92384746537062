import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
//import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
// import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
// import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
// import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { AuthGuard } from './auth/auth.guard';
import { MyQuestionsComponent } from './questions/my-questions/my-questions.component';
import { MyAnswersComponent } from './questions/my-answers/my-answers.component';
import { RecommendedQuestionsComponent } from './questions/recommended-questions/recommended-questions.component';
import { MyContentComponent } from './my-content/my-content.component';
import { QuestionDetailsComponent } from './questions/question-details/question-details.component';
import { ReportComponent } from './report/report.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
//import { ManageUserComponent } from './manage-user/manage-user.component';
//import { ManageRoleComponent } from './manage-role/manage-role.component';
//import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { QuestionBankComponent } from './questionBank/question-bank/question-bank.component';
import { AddQuestionComponent } from './questionBank/add-question/add-question.component';
import { MarkingComponent } from './marking/marking.component';
import { CreateAssessmentComponent } from './assessments/create-assessment/create-assessment.component';
import { RetreiveAssessmentComponent } from './assessments/retreive-assessment/retreive-assessment.component';
import { ViewAssessmentComponent } from './assessments/view-assessment/view-assessment.component';
import { MobileUpgradeComponent } from './mobile-upgrade/mobile-upgrade.component';
import { EcUserProfileComponent } from './profile/ec-user-profile/ec-user-profile.component';
import { AddOrganisationComponent } from './add-organisation/add-organisation.component';
import { SearchOrganisationComponent } from './add-organisation/search-organisation/search-organisation.component';
import { MyPrComponent } from './profile/my-pr/my-pr.component';
import { ReportlistComponent } from './reportlist/reportlist/reportlist.component';
import { AskQuestionComponent } from './questions/ask-question/ask-question.component';
import { GiveYourAnswerComponent } from './questions/give-your-answer/give-your-answer.component';
//import { CategoryListComponent } from './category-list/category-list.component';
import { AllQuestionsComponent } from './questions/all-questions/all-questions.component';
import { SearchComponent } from './search/search/search.component';
import { ImportQuestionComponent } from './questionBank/import-question/import-question.component';
import { QuestionDetailComponent } from './questionBank/question-detail/question-detail.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { TermsComponent } from './terms/terms.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ZoomIntegationComponent } from './zoom-integation/zoom-integation.component';


const routes: Routes = [
  { path: '', component: HomeComponent },

  // lazyloading not required as url linked with home component
  { path: 'home', component: HomeComponent, pathMatch: 'full' },


  // lazyloading not required as url linked with home component
  { path: 'draft-courses', component: HomeComponent, canActivate: [AuthGuard] },

  // lazyloading not required as url linked with home component
  { path: 'published-courses', component: HomeComponent, canActivate: [AuthGuard] },

  // { path: 'bulk-upload', component: BulkUploadComponent, canActivate: [AuthGuard] },
  { path: 'bulk-upload',
    loadChildren: () => import("./bulk-upload/bulk-upload.module").then(m => m.BulkUploadModule)
  },
  // { path: 'login', component: LoginComponent },
  { path: 'addUser', component: RegistrationComponent, canActivate: [AuthGuard] },
  // { path: 'manage-user', component: ManageUserComponent, canActivate: [AuthGuard] },
  // { path: 'manage-role', component: ManageRoleComponent, canActivate: [AuthGuard] },
  // { path: 'manage-categories', component: ManageCategoriesComponent, canActivate: [AuthGuard] },
  // { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'reset-password', component: ResetPasswordComponent },
  // { path: 'search', component: SearchComponent },
  // { path: 'public-profile', component: PublicProfileComponent, canActivate: [AuthGuard] },
  { path: 'public-profile',
    loadChildren: () => import("./public-profile/public-profile.module").then(m => m.PublicProfileModule), canActivate: [AuthGuard] 
  },
  { path: 'search',
    loadChildren: () => import("./search/search.module").then(m => m.SearchModule)
  },

  // lazy loading not required as this is old url not working properly currently
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  // { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: 'my-account', loadChildren: () => import("./my-account/my-account.module").then(m => m.MyAccountModule)},
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  
  // lazyloading not required as same component is configured with more than one route
  { path: 'My-Questions', component: MyQuestionsComponent, canActivate: [AuthGuard] },

  { path: 'my-answers', loadChildren: () => import("./questions/modules/my-answer/my-answer.module").then(m => m.MyAnswerModule)},
  { path: 'recommended-questions',loadChildren: () => import("./questions/modules/recommended/recommended.module").then(m => m.RecommendedModule)},
  // { path: 'all-questions', component: AllQuestionsComponent, canActivate: [AuthGuard] },
  { path: 'all-questions', loadChildren: () => import("./questions/all-qus.module").then(m => m.AllQusModule)},

  //lazyloading not required as same component is configured with more than one route
  { path: 'question-details', component: QuestionDetailsComponent, canActivate: [AuthGuard] },

  //lazyloading not required as same component is configured with more than one route
  { path: 'answer-details', component: QuestionDetailsComponent, canActivate: [AuthGuard] },

  { path: 'my-content', component: MyContentComponent, canActivate: [AuthGuard] },
  { path: 'Student-Questions', component: MyQuestionsComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'report-list', component: ReportlistComponent, canActivate: [AuthGuard] },
  // { path: 'category-list', component: CategoryListComponent },
  { path: 'category-list',
    loadChildren: () => import("./category-list/category-list.module").then(m => m.CategoryListModule)
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'question-bank', component: QuestionBankComponent, canActivate: [AuthGuard] },
  { path: 'import-question', component: ImportQuestionComponent, canActivate: [AuthGuard] },
  { path: 'question-detail', component: QuestionDetailComponent, canActivate: [AuthGuard] },

  { path: 'add-question', component: AddQuestionComponent, canActivate: [AuthGuard] },
  { path: 'edit-question', component: AddQuestionComponent, canActivate: [AuthGuard] },
  { path: 'ask-question', component: AskQuestionComponent, canActivate: [AuthGuard] },
  { path: 'edit-ask-question', component: AskQuestionComponent, canActivate: [AuthGuard] },
  { path: 'give-your-answer', component: GiveYourAnswerComponent, canActivate: [AuthGuard] },
  { path: 'edit-your-answer', component: GiveYourAnswerComponent, canActivate: [AuthGuard] },
  { path: 'create-assessment', component: CreateAssessmentComponent, canActivate: [AuthGuard] },
  { path: 'marking', component: MarkingComponent, canActivate: [AuthGuard] },
  { path: 'retrieve-assessment', component: RetreiveAssessmentComponent, canActivate: [AuthGuard] },
  { path: 'edit-assessment', component: CreateAssessmentComponent, canActivate: [AuthGuard] },
  { path: 'marking', component: MarkingComponent, canActivate: [AuthGuard] },
  { path: 'mobile-upgrade', component: MobileUpgradeComponent },
  { path: 'user-profile', component: EcUserProfileComponent, canActivate: [AuthGuard] },
  { path: 'zoom-integration', component: ZoomIntegationComponent, canActivate: [AuthGuard] },
  // FOR LAZY LOADING MODULE |START
  { path: 'login',
    loadChildren: () => import("./authenticate/authenticate.module").then(m => m.AuthenticateModule)
  },
  { path: 'forgot-password',
    loadChildren: () => import("./forgot-password/forgot-password.module").then(m => m.ForgotPasswordModule)
  },
  { path: 'reset-password',
    loadChildren: () => import("./reset-password/reset-password.module").then(m => m.ResetPasswordModule)
  },
  { path: 'manage-user',
    loadChildren: () => import("./manage-users/manage-users.module").then(m => m.ManageUsersModule)
  },
  { path: 'manage-role',
    loadChildren: () => import("./manage-roles/manage-roles.module").then(m => m.ManageRolesModule)
  },
  { path: 'manage-categories',
    loadChildren: () => import("./manage-categories/manage-categories.module").then(m => m.ManageCategoriesModule)
  },
  // FOR LAZY LOADING MODULE |END
  {
    path: 'resource-bank',
    loadChildren: () => import('./resources/resource-bank-module').then(m =>
      m.ResourceBankModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'question-bank',
    loadChildren: () => import('./questionBank/question-bank-module').then(m =>
      m.QuestionBankModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'passion-projects',
    loadChildren: () => import('./passion-projects/passion-projects-module').then(m =>
      m.PassionProjectsModule),
    canActivate: [AuthGuard],
  },
  { path: 'calander', component: ScheduleCalendarComponent},
  { path: 'mobile-upgrade', component: MobileUpgradeComponent},
  { path: 'user-profile', component: EcUserProfileComponent, canActivate: [AuthGuard]},
  {	
    path: 'resource-bank',	
    loadChildren: () => import('./resources/resource-bank-module').then(m =>	
    m.ResourceBankModule),	
    canActivate: [AuthGuard],	
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses/course.module').then(m =>
      m.CourseModule),
    canActivate: [AuthGuard],
  },
  { path: 'view-assessment/:id', component: ViewAssessmentComponent, canActivate: [AuthGuard] },
  {
    path: 'courses',
    loadChildren: () => import('./courses/course.module').then(m =>
      m.CourseModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'schedules',
    loadChildren: () => import('./calendar-schedule/calendar-schedule.module').then(m =>
      m.CalendarScheduleModule),
    canActivate: [AuthGuard],
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'terms', component: TermsComponent, canActivate: [AuthGuard] },
  { path: 'search_organisation', component: SearchOrganisationComponent, canActivate: [AuthGuard] },
  { path: 'add_organisation', component: AddOrganisationComponent, canActivate: [AuthGuard] },
  { path: 'guftagu-old', component: MyPrComponent, canActivate: [AuthGuard] },
  { path: 'mentorship', loadChildren: () => import('./mentorship/mentorship.module').then(m => m.MentorshipModule) },

  {	
    path: 'guftagu',	
    loadChildren: () => import('./guftagu/guftagu.module').then(m =>m.GuftaguModule),	canActivate: [AuthGuard],	
  }, 
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }	