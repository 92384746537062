import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ShowCourseView, CourseInfo } from '../model/course.model';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { parentInter } from '../model/courseCategory';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  apiEndpoint: any;
  constructor(private httpClient: HttpClient, private ns: NotificationService) {
    this.apiEndpoint = environment.APIEndpoint;
  }

  addCourse(value): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/courses', value);
  }

  // POST API FOR ADD/EDIT COURSE

  courseStructure(value): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/course/edit/course_structure', value);
  }

  // PATCH API FOR ADD/EDIT COURSE

  courseStructurePatch(value,courseId): Observable<any> {
    value['course_id'] = courseId
    return this.httpClient.patch<any>(this.apiEndpoint + '/course/edit/course_structure', value);
  }

  // GET API FOR INTENDED LEARNERS
  getIntendedLearners(courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId)
    return this.httpClient.get<any>(this.apiEndpoint + '/course/edit/intended_learners', { params });
  }

  // PATCH FOR INTENDED LEARNERS
  
  courseIntendedLearners(value,courseId): Observable<any> {
    value['course_id'] = courseId
    return this.httpClient.patch<any>(this.apiEndpoint + '/course/edit/intended_learners', value);
  }

   // PATCH FOR COURSE RESOURCE LEARNERS
  
   courseResource(value,courseId): Observable<any> {
    value['course_id'] = courseId
    return this.httpClient.patch<any>(this.apiEndpoint + '/course/edit/course_resources', value);
  }

  // GET FOR COURSE RESOURCE
  getCourseResources(courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId)
    return this.httpClient.get<any>(this.apiEndpoint + '/course/edit/course_resources', { params });
  }

  getStructure(courseId) {
    let params = new HttpParams();
    params = params.append('course_id', courseId)
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/edit/courses_structure", { params }).pipe(catchError(this.handleError))
  }

  patchCourse(value, courseId): Observable<any> {
    var reqHeader = new HttpHeaders({
      'course-id': courseId
    });
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses', value, { headers: reqHeader });
  }


  saveEditCourse(value, courseId): Observable<any> {
    var reqHeader = new HttpHeaders({
      'course-id': courseId,
    });
    return this.httpClient.post<any>(this.apiEndpoint + '/edit_course_details', value, { headers: reqHeader });
  }

  deactivateCourse(courseId): Observable<any> {
    let body = {
      course_id: courseId
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses/deactivate', body);
  }

  cloneCourse(courseId): Observable<any> {
    const currentDate = new Date(); // Get current date and time

    // Add 1 hr
    currentDate.setHours(currentDate.getHours() + 1);

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based (0 = January)
    const day = currentDate.getDate();
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const start_date = {year,month,day}
    const start_time = hours+":"+minutes
    let body = {
      course_id: courseId,
      date_time: {start_date, start_time}
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/clone_course', body);
  }

  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  categoryVisCourses(value, page, status, component_name?: string): Observable<any> {
    let params = new HttpParams();
    if (value) {
      params = params.append('course_category_id', value);
    }
    if (page) {
      params = params.append('page', page);
    }
    if (status) {
      params = params.append('subscription_status', status);
    }

    if (component_name) {
      params = params.append('page_name', component_name);
    }

    return this.httpClient.get<any>(this.apiEndpoint + '/courses', { params });
  }

  getCourseList(search = ''): Observable<any> {
    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.httpClient.get<any>(this.apiEndpoint + '/courses', { params });
  }
  
  save_announcement(value): Observable<any> {
    // console.log(value)
    let body = {
      course_id: value.course_id,
      announcement_html: value.announcement_html,
      title:value.title,
      resources:value.resources
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/announcement', body).pipe(catchError(this.handleError))
  }
  
  // graphAssessment(assessment_Id, course_Id): Observable<any> {
  //   let body = {
  //     assessment_id: assessment_Id,
  //     course_id: course_Id
  // }
  // let headers = new HttpHeaders().set('jsonData', JSON.stringify(body)); // create header object
  // let params = new HttpParams();

  //   return this.httpClient.get<any>(this.apiEndpoint + '/assessment/graph',{ headers: headers, params: params } ).pipe(catchError(this.handleError))
  // }
  graphAssessment(assessment_Id, course_Id): Observable<any> {
  let params = new HttpParams();
  params = params.append('assessment_id', assessment_Id);
  params = params.append('course_id', course_Id);
    return this.httpClient.get<any>(this.apiEndpoint + '/assessment/graph',{params } ).pipe(catchError(this.handleError))
  }

  categoryAPI(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/course_category').pipe(
      catchError(this.handleError)
    );;
  }

  categorynewAPI(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/course_class').pipe(
      catchError(this.handleError)
    );;
  }


  fetchQusType(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_questype').pipe(
      catchError(this.handleError)
    );;
  }

  qusComplexity(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_complexities').pipe(
      catchError(this.handleError)
    );;
  }

  editCourse(courseId, course): Observable<ShowCourseView> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    return this.httpClient.get<ShowCourseView>(this.apiEndpoint + '/courses', { params }).pipe(
      catchError(this.handleError)
    );
  }

  viewCourse(courseId): Observable<ShowCourseView> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    return this.httpClient.get<ShowCourseView>(this.apiEndpoint + '/courses/view', { params }).pipe(
      catchError(this.handleError)
    );
  }

  // optimize code of topic api
  get_topicSession(courseId, topic_id) {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    params = params.append('topic_id', topic_id);
    return this.httpClient.get<any>(this.apiEndpoint + '/courses_topics', { params }).pipe(
      catchError(this.handleError)
    );

  }

  getTopicList(course_id){
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/topics/list', { params }).pipe(
      catchError(this.handleError)
    );
  }

  getLiveSession(course_id){
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/live-sessions', { params }).pipe(
      catchError(this.handleError)
    );
  }

 
  getTags(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_tags').pipe(
      catchError(this.handleError)
    );;
  }

  video_question(askQuestionForm, course_session_id, courseId, selectedPic, selectedVideo, selectedDoc, category_id, currentTime, answer_id): Observable<any> {
    let infoForm = {
      'question': askQuestionForm.yourQuestion,
      'language': "en",
      'coins': askQuestionForm.coins,
      'tags': askQuestionForm.tags,
      'category_id': category_id,
      'video_path': selectedVideo,
      'question_image': selectedPic,
      'doc_path': selectedDoc,
      'answer_id': answer_id,
      'video_time': currentTime,
      'course_id': courseId,
      'course_session_id': course_session_id
    }

    return this.httpClient.post<any>(this.apiEndpoint + "/ask_questions", infoForm).pipe(catchError(this.handleError))
  }

  // edit ask question
  editAskQuestion(askQuestionForm, questionId, selectedPic, selectedVideo, selectedDoc, category_id): Observable<any> {
    let infoEditForm = {
      'question_id': questionId,
      'question': askQuestionForm.yourQuestion,
      'language': "en",
      'coins': askQuestionForm.coins,
      'tags': askQuestionForm.tags,
      'category_id': category_id,
      'video_path': selectedVideo,
      'question_image': selectedPic,
      'doc_path': selectedDoc
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/edit_questions', infoEditForm).pipe(catchError(this.handleError))
  }



  // video_question(askQuestionForm, course_session_id, courseId, selectedPic, selectedVideo, category_id, currentTime, answer_id): Observable<any> {
  //   const askQuestion = new FormData();

  //   askQuestion.append('question', askQuestionForm.yourQuestion);
  //   askQuestion.append('language', "en");
  //   askQuestion.append('coins', askQuestionForm.coins);
  //   askQuestion.append('tags', askQuestionForm.tags);
  //   askQuestion.append('category_id', category_id);
  //   askQuestion.append('video_path', selectedVideo);
  //   askQuestion.append('question_image', selectedPic);
  //   askQuestion.append('answer_id', answer_id);
  //   askQuestion.append('video_time', currentTime);
  //   askQuestion.append('course_id', courseId);
  //   askQuestion.append('course_session_id', course_session_id);

  //   return this.httpClient.post<any>(this.apiEndpoint + "/questions", askQuestion).pipe(catchError(this.handleError))
  // }

  subscribeCourse(isSubscribe, courseId) {
    let body = {
      "course_id": courseId,
      "subscribe": isSubscribe
    }
    return this.httpClient.post<any>(this.apiEndpoint + "/courses/subscription", body).pipe(catchError(this.handleError))

  }
  getCommentsInfo(course_session_id, course_id) {
    let params = new HttpParams();
    params = params.append('course_session_id', course_session_id);
    params = params.append('course_id', course_id)
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/sessions", { params }).pipe(catchError(this.handleError))

  }

  session_video_comments(courseId, value, course_session_id) {
    let body = {
      course_session_id: course_session_id,
      course_id: courseId,
      comment: value,
    }
    return this.httpClient.post<any>(this.apiEndpoint + "/courses/sessions/comments", body).pipe(catchError(this.handleError))

  }

  makeCourseUniversal(courseId,is_true) { 
    let body = {
      course_id: courseId,
      toggle: is_true
    }
    return this.httpClient.patch<any>(this.apiEndpoint + "/make_course_universal", body).pipe(catchError(this.handleError))

  }

  makeCourseUniversalforTeach(courseId) {
    let body = {
      course_id: courseId,
      toggle: true
    }
    return this.httpClient.patch<any>(this.apiEndpoint + "/make_course_universal", body).pipe(catchError(this.handleError))

  }

  saveVideoTime(course_id, course_session_id, videoTime) {
    let body = {
      course_id: course_id,
      course_session_id: course_session_id,
      video_time: Number(videoTime)
    }
    return this.httpClient.post<any>(this.apiEndpoint + "/courses/sessions/resume-time", body).pipe(catchError(this.handleError))
  }

  uploadResourse(resource_content) {

    const uploadResourse = new FormData();
    uploadResourse.append('resource_content', resource_content);

    return this.httpClient.post<any>(this.apiEndpoint + "/upload_course_resource", uploadResourse,
      {
        reportProgress: true,
        observe: 'events'
      }).pipe(catchError(this.handleError))

  }

  courseAssessmentList(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_assessment_list').pipe(
      catchError(this.handleError)
    );;
  }

  submit_course_work_submission(submissionData) {
    return this.httpClient.post<any>(this.apiEndpoint + "/course-work/submissions", submissionData).pipe(catchError(this.handleError))
  }

  viewSubmission(team_name, course_work_id, schedule_id, course_id, peer_review, self_review, view) {
    let params = new HttpParams();
    params = params.append('team_name', team_name);
    params = params.append('course_work_id', course_work_id);
    params = params.append('schedule_id', schedule_id);
    params = params.append('course_id', course_id);
    params = params.append('peer_review', peer_review);
    params = params.append('self_review', self_review);
    // params = params.append('grade_view', view);
    if (view == 'expand_view') {
      params = params.append('expand_view', 'true');
    } else if (view == 'grade_view') {
      params = params.append('grade_view', 'true');
    }

    return this.httpClient.get<any>(this.apiEndpoint + "/course-work/submissions", { params }).pipe(catchError(this.handleError))
  }

  viewQuestions(course_submission_id, course_id) {
    let params = new HttpParams();
    params = params.append('course_session_id', course_submission_id);
    params = params.append('course_id', course_id);

    return this.httpClient.get<any>(this.apiEndpoint + "/user_questions", { params }).pipe(catchError(this.handleError))

  }

  addCourseWork(value, course_work_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_work_id', course_work_id);
    return this.httpClient.post<any>(this.apiEndpoint + '/course_work', value, { params });
  }
  fetchAllCourseWork(page = '', search = '', type = ''): Observable<any> {
    let params = new HttpParams();
    if(page) {
      params = params.append('page', page);
    }
    if(search) {
      params = params.append('search', search);
    }
    if(type) {
      params = params.append('type', type);
    }
    return this.httpClient.get<any>(this.apiEndpoint + '/course_work/search', { params });
  }

  viewCourseWork(course_work_id, schedule_id?: any, course_id?: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_work_id', course_work_id);
    if (schedule_id) {
      params = params.append('schedule_id', schedule_id);
    } if (course_id) {
      params = params.append('course_id', course_id);
    }
    return this.httpClient.get<any>(this.apiEndpoint + '/course_work', { params })
  }

  editCourseWork(course_work_id, value): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_work_id', course_work_id);
    return this.httpClient.patch<any>(this.apiEndpoint + '/course_work', value, { params })
  }

  getSubmissionAssessment(atype, aid,is_timed, courseId ,topicId, sessionId): Observable<any> {
    let params = new HttpParams();
    params = params.append(atype, aid);
    params = params.append('is_timed', is_timed);
    params = params.append('course_id', courseId);
    params = params.append('topic_id', topicId);
    params = params.append('session_id', sessionId);
    return this.httpClient.get<any>(this.apiEndpoint + '/assessment', { params })
  }

  submitAssessment(data): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + "/submit-assessment", data).pipe(
      catchError(this.handleError))
  }

  // this the resume time for assessment
  resumeCountDown(data): Observable<any> {
    // console.log(data)
    return this.httpClient.patch<any>(this.apiEndpoint + "/assessment/time", data).pipe(
      catchError(this.handleError))
  }

  getSubscribeStudent(courseId, role): Observable<any> {
    let params = new HttpParams();
    params = params.append('roles', '[' + "'" + role + "'" + ']');
    params = params.append('course_id', courseId);

    return this.httpClient.get<any>(this.apiEndpoint + '/courses/subscribers', { params: params }).pipe(catchError(this.handleError))
  }

  getAssisstantSubscribe(courseId, role): Observable<any> {
    let params = new HttpParams();
    params = params.append('roles', '[' + "'" + role + "'" + ']');
    params = params.append('course_id', courseId);

    return this.httpClient.get<any>(this.apiEndpoint + '/assisstant/subscribers', { params: params }).pipe(catchError(this.handleError))
  }

  

  feedbackAssessment(assessmentId, courseId, scheduleId, user_id, topic_id, course_session_id, assessment_result_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('assessment_id', assessmentId);
    params = params.append('course_id', courseId);
    params = params.append('topic_id', topic_id);
    params = params.append('course_session_id', course_session_id);
    if (assessment_result_id) {
      // params = params.append('assessment_result_id', assessment_result_id.assessment_result_id)
      params = params.append('assessment_result_id', assessment_result_id)
    }
    // else {
    // }
    if (scheduleId) {
      params = params.append('schedule_id', scheduleId);
    }
    params = params.append('user_id', user_id);
    return this.httpClient.get<any>(this.apiEndpoint + '/assessment-feedback', { params })
  }

  // scheduleId, user_id, topic_id, course_session_id, assessment_result_id
  userSubmissions(assessmentId, courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('assessment_id', assessmentId);
    params = params.append('course_id', courseId);
    // params = params.append('topic_id', topic_id);
    // params = params.append('course_session_id', course_session_id);
    // if (assessment_result_id) {
    //   params = params.append('assessment_result_id', assessment_result_id)
    // }
    // if (scheduleId) {
    //   params = params.append('schedule_id', scheduleId);
    // }
    // params = params.append('user_id', user_id);
    return this.httpClient.get<any>(this.apiEndpoint + '/assessments/submission/users', { params })
  }
  getUserSubmissions(assessmentId, courseId, user_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('assessment_id', assessmentId);
    params = params.append('course_id', courseId);
    params = params.append('user_id', user_id);
    return this.httpClient.get<any>(this.apiEndpoint + '/assessments/user/submissions', { params })
  }
  saveCourseWorkTeams(value, group_type, course_work_id, course_instance_id, course_id, type, reset_team, group_image = '', groupActionName = '', oldTeamName = ''): Observable<any> {
    let team_info = [];
    let studnet_info = [];

    if (type == 'student-led') {
      value.users.forEach(element => {
        if (element == localStorage.getItem('user_id')) {
          studnet_info.push({ _id: localStorage.getItem('user_id'), approval_status: true })
        } else {
          studnet_info.push({ _id: element, approval_status: false })
        }

      });
      // studnet_info.push({ _id: localStorage.getItem('user_id'), approval_status: true })
      team_info.push({ name: value.name, team_img: (value.group_pic ? value.group_pic : ''), users: studnet_info })
    } else if (type == 'manual') {
      value.forEach(element => {
        team_info.push({ name: element.name, team_img: (element.group_pic ? element.group_pic : ''), group_room_id: element.group_room_id, users: element.users })

      });;
    }

    if (group_type == 'student-led') {
      group_type = 'Student-led'
    }

    let body = {
      group_type: group_type,
      course_work_id: course_work_id,
      course_instance_id: course_instance_id,
      course_id: course_id,
      team_info: team_info,
      reset: reset_team,
      old_name: oldTeamName
    }

    if(groupActionName == 'Edit') {
      return this.httpClient.patch<any>(this.apiEndpoint + '/course-work/teams', body).pipe(catchError(this.handleError))
    }
    else {
      return this.httpClient.post<any>(this.apiEndpoint + '/course-work/teams', body).pipe(catchError(this.handleError))
    }
  }


  saveCourseWorkTeams_create(value, group_type, course_work_id, course_instance_id, course_id, type, reset_team,addTeamByStudnet): Observable<any> {
    let team_info = [];
    let studnet_info = [];
    // console.log(addTeamByStudnet);

    if (type == 'student-led') {
      value.forEach(element => {
        if (element == localStorage.getItem('user_id')) {
          studnet_info.push({ _id: localStorage.getItem('user_id'), approval_status: true })
        } else {
          studnet_info.push({ _id: element.value._id, approval_status: false })
        }

      });
      // studnet_info.push({ _id: localStorage.getItem('user_id'), approval_status: true })
      team_info.push({ name: addTeamByStudnet, users: studnet_info })
    } else if (type == 'manual') {
      value.forEach(element => {
        team_info.push({ name: addTeamByStudnet, group_room_id: element.group_room_id, users: element.users })
      });;
    }

    if (group_type == 'student-led') {
      group_type = 'Student-led'
    }

    let body = {
      group_type: group_type,
      course_work_id: course_work_id,
      course_instance_id: course_instance_id,
      course_id: course_id,
      team_info: team_info,
      reset: reset_team
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/course-work/teams', body).pipe(catchError(this.handleError))
  }

  view_assessment_teacher(assessmentId, courseId, scheduleId, currentPage) {
    let params = new HttpParams();
    params = params.append('assessment_id', assessmentId);
    params = params.append('course_id', courseId);
    params = params.append('page', currentPage);
    // params = params.append('submitted_time', submitted_time)
    // params = params.append('assessment_result_id', assessment_result_id);
    if (scheduleId) {
      params = params.append('schedule_id', scheduleId);
    }
    return this.httpClient.get<any>(this.apiEndpoint + '/assessments/submissions/users', { params })

  }
  remark_assessment(reamrk_form, schedule_assessment_id, topic_id, course_session_id, assessment_id, course_id, user_id, question_id,assessment_result_id) {
    let remark;
    let grade;
    if (!course_session_id) {
      course_session_id = '';
    }
    if (!reamrk_form.controls.remark) {
      remark = '';
    } else {
      remark = reamrk_form.controls.remark.value;
    }

    if (!reamrk_form.controls.grade) {
      grade = '';
    } else {
      grade = reamrk_form.controls.grade.value;
    }
    let body = {
      schedule_assessment_id: schedule_assessment_id,
      topic_id: topic_id,
      course_session_id: course_session_id,
      assessment_id: assessment_id,
      course_id: course_id,
      remark: remark,
      user_id: user_id,
      question_id: question_id,
      grade: grade,
      assessment_result_id:assessment_result_id
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/assessments/submission/remarks', body)
  }
  session_detail(course_id, course_session_id,topic_id) {
    let params = new HttpParams();
    params = params.append('course_session_id', course_session_id);
    params = params.append('course_id', course_id);
    params = params.append('topic_id',topic_id)
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/sessions", { params }).pipe(catchError(this.handleError))

  }


  unapproved_ai_Assessment(course_session_id:string) {
    let params = new HttpParams();
    params = params.append('course_session_id', course_session_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/session/assessment", { params }).pipe(catchError(this.handleError))

  }


  get_ai_Assessment_detail(assessment_id:string) {
    let params = new HttpParams();
    params = params.append('assessment_id', assessment_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/get_assessment_view", { params }).pipe(catchError(this.handleError))

  }

  

  getTranscription(course_id, course_session_id,topic_id) {
    let params = new HttpParams();
    params = params.append('course_session_id', course_session_id);
    params = params.append('course_id', course_id);
    params = params.append('topic_id',topic_id)
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/sessions/transcript", { params }).pipe(catchError(this.handleError))

  }



  add_remark_grade_course_work(body) {
    return this.httpClient.post<any>(this.apiEndpoint + "/course-work/submission/remarks", body).pipe(catchError(this.handleError));
  }

  accept_invite(course_instance_id, course_work_id, team_name, response, user_id, redirect, course_id) {
    let params = new HttpParams();
    params = params.append('course_instance_id', course_instance_id);
    params = params.append('course_work_id', course_work_id);
    params = params.append('team_name', team_name);
    params = params.append('response', response);
    params = params.append('user_id', user_id);
    params = params.append('redirect', redirect);
    params = params.append('course_id', course_id);

    return this.httpClient.get<any>(this.apiEndpoint + '/course-work/teams/invite', { params }).pipe(catchError(this.handleError));
  }
  go_live_with_assessment(course_session_id, course_id, topic_id, toggle_status) {
    let body = {
      'course_session_id': course_session_id,
      'course_id': course_id,
      'topic_id': topic_id,
      'is_live': toggle_status,
    }
    return this.httpClient.patch<any>(this.apiEndpoint + "/courses/sessions", body).pipe(catchError(this.handleError));
  }

  statusTarcking(course_id, topic_id,course_session_id) {
    let body = {
      'course_id': course_id,
      'topic_id': topic_id,
      'session_id': course_session_id
    }
    return this.httpClient.patch<any>(this.apiEndpoint + "/courses/session/tracking", body).pipe(catchError(this.handleError));
  }

  make_live_assessment(course_session_id, course_id, topic_id, assessment_id, active) {
    let body = {
      'course_session_id': course_session_id,
      'course_id': course_id,
      'topic_id': topic_id,
      'assessment_id': assessment_id,
      'active': active,
    }
    return this.httpClient.patch<any>(this.apiEndpoint + "/courses/sessions/assessment", body).pipe(catchError(this.handleError));
  }
  submissionAssessmentQusDetail(courseId, sessionId, schedule_id, topic_id, assessment_id) {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    params = params.append('course_session_id', sessionId);
    params = params.append('schedule_id', schedule_id);
    params = params.append('topic_id', topic_id);
    params = params.append('assessment_id', assessment_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/assessments/submission/question-detail", { params }).pipe(catchError(this.handleError))
  }


  //re-design work
  add_note(session_id, type, course_id, topic_id, value): Observable<any> {
    let title = value.title;
    if (type == 'summary') {
      title = '';
    }
    let body = {
      course_session_id: session_id,
      title: title,
      content: value.content,
      type: type,
      course_id: course_id,
      topic_id: topic_id,
      timestamp: value.timestamp,
      video_path: value.video_path,
      image_path: value.image_path,
      resource_path:value.resource_path,
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/session/notes', body);
  }

  delete_resouce(course_id, schedule_id, course_work_id, file_id, report_name, report_index, team_name): Observable<any> {

    let params = new HttpParams();

    params = params.append('course_id', course_id);
    params = params.append('schedule_id', schedule_id);
    params = params.append('course_work_id', course_work_id);
    params = params.append('file_id', file_id);
    params = params.append('report_name', report_name);
    params = params.append('report_index', report_index);
    params = params.append('team_name', team_name);


    return this.httpClient.delete<any>(this.apiEndpoint + '/course-work/submissions/files', { params }).pipe(catchError(this.handleError))

  }

  delete_course(course_work_id?: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_work_id', course_work_id);

    return this.httpClient.delete<any>(this.apiEndpoint + '/course_work', { params: params }).pipe(catchError(this.handleError))
  }


  add_notes(session_id, type, value): Observable<any> {
    let title = value.title;
    if (type == 'summary') {
      title = '';
    }
    let body = {
      course_session_id: session_id,
      title: title,
      content: value.content,
      type: type,
      timestamp: value.timestamp,
     video_path: value.video_path,
      image_path: value.image_path,
      resources:value.resources,
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/session/notes', body);
  }


  edit_notes(session_id, topic_id, course_id, _id, type, value): Observable<any> {
    let title = value.title;
    if (type == 'summary') {
      title = '';
    }
    let body = {
      course_session_id: session_id,
      title: title,
      content: value.content,
      type: type,
      course_id: course_id,
      topic_id: topic_id,
      _id: _id,
      timestamp: value.timestamp,
      video_path: value.video_path,
      image_path: value.image_path,
      resource_path:value.resource_path,
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses/session/notes', body);
  }

  delete_notes(session_id, type, _id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_session_id', session_id);
    params = params.append('type', type);
    params = params.append('_id', _id);

    return this.httpClient.delete<any>(this.apiEndpoint + '/courses/session/notes', { params });
  }
  add_organization(value): Observable<any> {
    let body = {
      name: value.organization_name,
      org_type_id: value.org_type
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/organisations', body);
  }


  bug_list(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/bug_list');
  }

  patchCoursebuglist(_Id): Observable<any> {
    let body = {
      '_id': _Id
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/update_bug_status', body);
  }

  get_organization(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/organisations');
  }
  patch_organization(body): Observable<any> {
    return this.httpClient.patch<any>(this.apiEndpoint + '/organisations', body);
  }

  publish_submission(courseId, schedule_id, course_work_id, topic_id:string = ''): Observable<any> {
    let body:any = {
      'course_id': courseId,
      'course_work_id': course_work_id,
      'schedule_id': schedule_id
    }
    if(topic_id){
      body.topic_id = topic_id;
    }
    return this.httpClient.post<any>(this.apiEndpoint + "/course-work/submission/publish", body).pipe(catchError(this.handleError))
  }
  add_organisation(value): Observable<any> {
    let body = {
      name: value.organisation_name
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/organisations', body);
  }

  monitoringReport(body): Observable<any> {

    return this.httpClient.post<any>(this.apiEndpoint + '/courses/report', body).pipe(
      catchError(this.handleError)
    );
  }

  exportReport(body): Observable<any> {

    return this.httpClient.post<any>(this.apiEndpoint + '/courses/report/export', body, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  get_courseSessions(courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/topics', { params }).pipe(
      catchError(this.handleError)
    );
  }

  courseSessionNotes(courseId, sessionId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    params = params.append('session_id', sessionId);
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/session/notes', { params }).pipe(
      catchError(this.handleError)
    );
  }

  save_assiatnt(data, course_id): Observable<any> {
    let assignee_data = [];
    data.forEach(element => {
      let v = [];
      element.assignees.forEach(ele => {
        v.push(ele._id);
      });
      assignee_data.push({
        teach_assis: element._id, assignees: v
      });
    });
    let body = {
      "course_id": course_id,
      "assignee_data": assignee_data

    }
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/teaching-assistant/assignee', body).pipe(catchError(this.handleError))
  }

  get_report(courseId) {
    let params = new HttpParams();
    params = params.append('course_id', courseId);

    return this.httpClient.get<any>(this.apiEndpoint + '/courses/teaching-assistant/report', { params: params }).pipe(catchError(this.handleError))

  }

  get_assign_teams(course_id, course_work_id?: any, schedule_id?: any) {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    if (course_work_id) {
      params = params.append('course_work_id', course_work_id);
    } if (schedule_id) {
      params = params.append('schedule_id', schedule_id);
    }

    return this.httpClient.get<any>(this.apiEndpoint + '/courses/teaching-assistant/assignee', { params: params }).pipe(catchError(this.handleError))
  }

  save_CW_assiatnt(data, course_id, course_work_id?: any, schedule_id?: any): Observable<any> {
    let assignee_data = [];

    data.forEach(element => {
      let v = [];
      element.assignees.forEach(ele => {
        v.push(ele.name);
      });
      assignee_data.push({
        teach_assis: element._id, assignees: v
      });
    });

    let body = {
      "course_id": course_id,
      'course_work_id': course_work_id,
      'schedule_id': schedule_id,
      "assignee_data": assignee_data,
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/teaching-assistant/assignee', body).pipe(catchError(this.handleError))
  }

  matchAttendance(type, course_id, session_id, file_id?, file_path?) {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    params = params.append('session_id', session_id);
    if (type == 'nonlive_attendance') {
      params = params.append('file_id', file_id);
      params = params.append('file_path', file_path);
    }

    return this.httpClient.get<any>(this.apiEndpoint + '/session/attendance/match', { params: params }).pipe(catchError(this.handleError))
  }

  markSessionAttendance(body): Observable<any> {

    return this.httpClient.post<any>(this.apiEndpoint + '/session/attendance', body).pipe(
      catchError(this.handleError)
    );
  }

  viewSessionAttendance(course_id, session_id) {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    params = params.append('session_id', session_id);

    return this.httpClient.get<any>(this.apiEndpoint + '/session/attendance', { params: params }).pipe(catchError(this.handleError))
  }

  // LIVE SESSION AUTO ATTENDENCE
  LiveSessionAttendance(course_id, session_id,topic_id) {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    params = params.append('live_session_id', session_id);
    params = params.append('topic_id', topic_id);


    return this.httpClient.get<any>(this.apiEndpoint + '/courses/live_session/attendance', { params: params }).pipe(catchError(this.handleError))
  }
  
  updateLiveAttendence(body){
    console.log(body)
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses/live_session/attendance' , body).pipe(catchError(this.handleError))
  }

  // THIS END OF LIVE SESSION AUTO ATTENDENCE

  edit_announcement(value, index): Observable<any> {

    let body = {
      course_id: value.course_id,
      announcement_html: value.announcement_html,
      title:value.title,
      resources:value.resources,
      announcement_id: index
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses/announcement', body).pipe(catchError(this.handleError))
  }

  delete_announcement(course_id, announcement_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    params = params.append('announcement_id', announcement_id);

    return this.httpClient.delete<any>(this.apiEndpoint + '/courses/announcement', { params: params }).pipe(catchError(this.handleError))
  }

  get_zoom_recording(course_id) {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    return this.httpClient.get<any>(this.apiEndpoint + '/course/recordings', { params: params }).pipe(catchError(this.handleError))

  }

  save_zoom_recording(value, course_id) {
    let body = {
      course_id: course_id,
      topics: value.topics
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/course/recordings', body).pipe(catchError(this.handleError));
  }

  get_zip_submission(course_work_id, schedule_id, user_id, team_name): Observable<any> {
    let body = {
      'course_work_id': course_work_id,
      'schedule_id': schedule_id,
      'user_id': user_id,
      'team_name': team_name
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/course-work/submission/zip', body, { observe: 'response', responseType: 'blob' as 'json' }).pipe(catchError(this.handleError));
  }

  deactivated_course(page): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', page);
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/deactivated', { params });
  }


  discoverCourses() {
    return this.httpClient.get<any>(this.apiEndpoint + '/discover_our_courses').pipe(
      catchError(this.handleError)
    );
  }

  addCourseInfo(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/courses').pipe(
      catchError(this.handleError)
    );
  }
  addCourseCate(page, courseStatus): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('publish', courseStatus);
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/catalouge', { params }).pipe(
      catchError(this.handleError)
    );
  }



  homepage(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/homepage').pipe(
      catchError(this.handleError)
    );;
  }

  topRatedCourse(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/top_rated_courses').pipe(
      catchError(this.handleError)
    );
  }

  activate_course(course_id: string) {
    let body = {
      'course_id': course_id
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses/activate', body);
  }
  // teacher can edit the question
  teacher_edit_question(question_id, question_text) {
    let body = {
      'question_id': question_id,
      'text': question_text,
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/edit_questions', body);
  }

  report_issue(startTime, endTime, status, suggestion, s3_key) {
    let body = {
      "s3_key": s3_key,
      "suggestion": suggestion,
      "status": status,
      "starttime": startTime,
      "endtime": endTime
    }
    return this.httpClient.patch<any>(this.apiEndpoint + '/transcript', body);
  }

  getReview(courseid, val: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseid);
    // params = params.append('raiting_sort',val:string)

    if (val == 'latest') {
      params = params.append('rating_sort', val);
    } else if (val == 'A2Z') {
      params = params.append('rating_sort', val);
    }
    return this.httpClient.get<any>(this.apiEndpoint + '/get_reviews', { params }).pipe(
      catchError(this.handleError)
    );
  }

  sortedCourseCategory(): Observable<parentInter[]> {
    return this.httpClient.get<parentInter[]>(this.apiEndpoint + '/sorted_course_category').pipe(
      catchError(this.handleError)
    );
  }

  globalSearch(search, searchType): Observable<any> {
    let params = new HttpParams();
    params = params.append('search_text', search)
    params = params.append('content_type', searchType)
    return this.httpClient.get<any>(this.apiEndpoint + "/global_search", { params }).pipe(catchError(this.handleError))
  }

  // NEW INTERGRATION FOR FILTER IN GLOBAL SEARCH
  
  globalSearchWithFilter(search, searchType,val,filterValue): Observable<any> {
    let params = new HttpParams();
    params = params.append('search_text', search)
    params = params.append('content_type', searchType)
    params = params.append('sort_by', val)
    params = params.append('category', filterValue)
    return this.httpClient.get<any>(this.apiEndpoint + "/global_search", { params }).pipe(catchError(this.handleError))
  }

  session_video_comments_likeDislike(comment_id, value, course_session_id,is_true) {
    let body = {
      course_session_id: course_session_id,
      comment_id: comment_id,
      status: value,
      is_true:is_true
    }
    return this.httpClient.post<any>(this.apiEndpoint + "/courses/sessions/comments/like_dislike", body).pipe(catchError(this.handleError))

  }

  getAnnouncementList(course_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/announcement/list", { params }).pipe(catchError(this.handleError))
  }


  //re-design api 

  getAnnouncementTopic(course_id,announcement_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    params = params.append('announcement_id', announcement_id);

    return this.httpClient.get<any>(this.apiEndpoint + "/courses/announcement", { params }).pipe(catchError(this.handleError))
  }


  getInstructors(course_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/instructors", { params }).pipe(catchError(this.handleError))
  }




  assignstudentData(postData:any){
    return this.httpClient.post<any>(this.apiEndpoint + "/courses/teaching-assistant/assign_subscriber", postData).pipe(catchError(this.handleError))
  }


  getCourseResource(course_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/course_resources", { params }).pipe(catchError(this.handleError))
  }
  getCoursework(course_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/course_work", { params }).pipe(catchError(this.handleError))
  }
  getAssessments(course_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', course_id);
    return this.httpClient.get<any>(this.apiEndpoint + "/courses/assessments", { params }).pipe(catchError(this.handleError))
  }

  getSingleCoursework(courseId, courseworkId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    params = params.append('course_work_id', courseworkId);
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/single_coursework', { params }).pipe(
      catchError(this.handleError)
    );
  }

  // getStudentsList(courseId, role): Observable<any> {
  //   let params = new HttpParams();
  //   params = params.append('course_id', courseId);
  //   params = params.append('roles', role);
  //   return this.httpClient.get<any>(this.apiEndpoint + '/assisstant/subscribers', { params }).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  getCourseworkSubscribers(courseId, role, scheduleId): Observable<any> {
    let params = new HttpParams();
    params = params.append('roles', '[' + "'" + role + "'" + ']');
    params = params.append('course_id', courseId);
    params = params.append('course_work_instance_id', scheduleId);

    return this.httpClient.get<any>(this.apiEndpoint + '/course-work/user-data', { params: params }).pipe(catchError(this.handleError))
  }

  //FOR HOMEPAGE API SEPERATION |START
  recentlyViewSessions(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/homepage/recently_view_sessions').pipe(
      catchError(this.handleError)
    );;
  }

  recommendedCourses(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/homepage/recommended_courses').pipe(
      catchError(this.handleError)
    );;
  }

  subscribedCourses(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/homepage/subscribed_courses').pipe(
      catchError(this.handleError)
    );;
  }

  topCategories(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/homepage/top_categories').pipe(
      catchError(this.handleError)
    );;
  }

  topCourses(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/homepage/top_courses').pipe(
      catchError(this.handleError)
    );;
  }
  //FOR HOMEPAGE API SEPERATION |END

  //FOR ACCEPT REJECT INVITE |START
  acceptRejectGroupInvite(courseInstanceId, courseworkId, courseId, teamName, userId, response): Observable<any> {
    let params = new HttpParams();
    const redirect = 'true';
    params = params.append('course_instance_id', courseInstanceId);
    params = params.append('course_work_id', courseworkId);
    params = params.append('course_id', courseId);
    params = params.append('team_name', teamName);
    params = params.append('user_id', userId);
    params = params.append('response', response);    
    params = params.append('redirect', redirect);

    return this.httpClient.get<any>(this.apiEndpoint + '/course-work/teams/invite', { params }).pipe(
      catchError(this.handleError)
    );
  }
  //FOR ACCEPT REJECT INVITE |END


  // FOR PUBLIC PROFILE
  publicProfile(user_id): Observable<any> {
    let params = new HttpParams();
    if(user_id){
      params = params.append('user_id', user_id);
    }
    return this.httpClient.get<any>(this.apiEndpoint + '/public_profile', { params: params }).pipe(catchError(this.handleError))
  }

  // FOR VIEW RESOURCE
  viewResource(resourceId,instanceId){
    let params = new HttpParams();
    params= params.append('resource_id',resourceId);
    params= params.append('instance_id',instanceId);
    
    return this.httpClient.get<any>(this.apiEndpoint + '/resource-bank-view',{params:params}).pipe(catchError(this.handleError))
  }
  
  // FOR VIEW QUESTION FOR QUESTION BANK
  viewQuestion(QuestionId){
    let params = new HttpParams();
    params= params.append('question_id',QuestionId);
    
    return this.httpClient.get<any>(this.apiEndpoint + '/import_question/view',{params:params}).pipe(catchError(this.handleError))
  }

  //FOR UPDATE COURSE DETAILS
  updateCourseDetails(postData): Observable<any> {
    return this.httpClient.patch<any>(this.apiEndpoint + '/courses/edit/course_details', postData);
  }

  //FOR GET COURSE DETAILS
  getCourseDetails(courseId, topicId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    params = params.append('topic_id', topicId);

    return this.httpClient.get<any>(this.apiEndpoint + '/courses/edit/course_details', {params: params});
  }

  //FOR DELETE COURSE DETAILS
  deleteCourseDetails(courseId, topicId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    params = params.append('topic_id', topicId);

    return this.httpClient.delete<any>(this.apiEndpoint + '/courses/edit/course_details', {params: params});
  }

  //FOR DELETE COURSE DETAILS topics Live Session
   deleteLiveSession(courseId:any, topicId:any, liveSession:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let body = {
      course_id:courseId,
      topic_id:topicId,
      live_session_id:liveSession
      }
    return this.httpClient.request('DELETE', this.apiEndpoint + "/course/edit/live_session", { headers, body, responseType: 'json', })
  }


  //FOR GET COURSE ASSESSMENTS
  getCourseAssessments(courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    return this.httpClient.get<any>(this.apiEndpoint + "/course/edit/course_assessments", { params });
  }

  //FOR UPDATE COURSE ASSESSMENTS
  updateCourseAssessments(postData, courseId): Observable<any> {
    postData['course_id'] = courseId;

    return this.httpClient.patch<any>(this.apiEndpoint + '/course/edit/course_assessments', postData);
  }

  //FOR GET COURSE COURSEWORKS
  getCourseCourseworks(courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId);
    return this.httpClient.get<any>(this.apiEndpoint + "/course/edit/course_work", { params });
  }

  //FOR UPDATE COURSE ASSESSMENTS
  updateCourseCourseworks(postData, courseId): Observable<any> {
    postData['course_id'] = courseId;

    return this.httpClient.patch<any>(this.apiEndpoint + '/course/edit/course_work', postData);
  }

  //FOR PUBLISH COURSE
  publishCourse(postData): Observable<any> {

    return this.httpClient.patch<any>(this.apiEndpoint + '/course/publish', postData);
  }

  meetingStarted(live_session,course_id){
    let postData = {
      "course_id": course_id,
      "meeting_id": live_session.meeting_id,
      "initiated": true
      }
    return this.httpClient.patch<any>(this.apiEndpoint + "/courses/live-sessions/meeting_initiated", postData);
     
  }

  /**
   * To get generated report status from the server.
   * @param {string} courseId - To get user report selections data
   * @return {observable} - To send response to the subscriber
   */
  getReportDownloadStatus(courseId): Observable<any> {
    let params = new HttpParams();
    params = params.append('course_id', courseId)
    return this.httpClient.get<any>(this.apiEndpoint + '/courses/report/download', {params});
  }

  /**
   * To make generate report request to the server.
   * @param {object} postData - To get user report selections data
   * @return {observable} - To send response to the subscriber
   */
  generateCourseReport(postData): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/courses/report/request', postData);
  }
}

