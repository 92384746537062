<div class="new_UIstyle">
    <div class="pageBodyWrap passionProjectDetailMain">
        <!-- Banner Section Start -->
        <section class="banner banner-inner single-passion-banner"
            [style.background-image]="'url(' + ('assets/images/banner/mosaic-bg01.png') + ')'">
            <div class="container">
                <div class="banner-content">
                    <h2 class="white-text avenirBlack">{{passionProject?.project_title | capitalization }}</h2>
                    <div class="tags-badges">
                        <span class="badge badge-green-lt" *ngFor="let item of passionProject?.project_tags"
                            [ngStyle]="{'background-color': item.bgColor ? item.bgColor : 'white', 'color': true ? '#FFFFFF': '#FFFFFF'}">{{
                            item.tag }}</span>
                    </div>
                </div>
                <button *ngIf="role != 'student' && passionProject?.edit_rights == true" class="btn btn-primary edit-btn" (click)="redirectToEdit('/passion-projects/add')">
                    <i class="fas fa-pencil-alt" aria-hidden="true"></i> Edit</button>
                <button class="btn btn-primary" (click)="onGoBack()">
                    <i class="fas fa-arrow-left"></i> Back
                </button>
            </div>
        </section>
        <!-- Banner Section End -->

        <section class="section single-passion-content">
            <div class="container" [ngClass]="{submitting : loader}">

                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                    <span class="sr-only">Loading...</span>
                </div>

                <div class="single-passion-row overView-project border-bottom mb-5 pb-4">
                    <h4 class="avenirBlack">Overview of Project</h4>
                    <div class="single-passion-content-inn noScroll">
                        <div class="row gy-4 gy-md-0">
                            <div class="col-md-6 overView-project-colcontent">
                                <p *ngIf="passionProject?.project_overview?.description">{{
                                    passionProject.project_overview.description }}</p>

                            <div class="desPlaceholderBox" *ngIf="!passionProject?.project_overview?.description"> 
                                <p>No Description Available</p>
                            </div>
                            </div>
                            <div class="col-md-6  overView-project-colFig">
                                <figure class="img-responsive">
                                    <img *ngIf="passionProject?.project_overview?.path_s3"
                                        [src]="passionProject?.project_overview?.path_s3" alt="sst-01.png"class=" overView-project-img">
                                    <img *ngIf="!passionProject?.project_overview?.path_s3 && passionProject?.project_image_s3"
                                        [src]="passionProject?.project_image_s3" alt="sst-01.png"class=" overView-project-img">
                                    <img *ngIf="(!passionProject?.project_overview?.path_s3 && !passionProject?.project_image_s3) && !loader" [src]="defaultThumbnailPic" alt="sst-01.png" class=" overView-project-img" />
                                </figure>
                            </div>
                        </div>
                    </div>

                    
                    <!-- <div class="alert alert-info text-center" *ngIf="!passionProject?.project_overview?.description &&  !passionProject?.project_overview?.path_s3"> 
                        <p>No Data Available</p>
                    </div> -->
                </div>
                <!-- <hr /> -->
                <div class="single-passion-row singlePassoinMentor border-bottom mb-5 pb-4">
                    <h4 class="avenirBlack mb-2">Mentors</h4>

                    <div class="single-passion-content-inn">
                        <div class="Instructor-lists">
                            <div class="row gy-5 gy-lg-5">
                                <div class="col-md-6 mt-4"
                                    *ngFor="let projectMentor of passionProject?.project_mentors; let mentor_index=index">
                                    <div class="Instructor-profile">
                                        <figure class="Instructor-pro-fig">
                                            <img *ngIf="projectMentor.profile_pic" [src]="projectMentor.profile_pic"
                                                alt="dp-4.png" />
                                            <default-name-icon *ngIf="!projectMentor.profile_pic"
                                                [name]="projectMentor.first_name"
                                                [figureClass]="''"></default-name-icon>
                                        </figure>
                                        <div class="Instructor-pro-content">
                                            <h6 class="avenirBlack"><a href="javascript:void(0);" (click)="gotoUserProfilePage(projectMentor.id, projectMentor)">{{projectMentor.first_name+' '+ projectMentor.last_name | capitalization}}</a></h6>
                                            <p class="Instructor-pro-sub blue-text">Instructor</p>
                                            <p class="Instructor-pro-des">Big Data, Cloud and AI Solution</p>
                                            <button *ngIf="!is_mentor_present() && mentor_index == 0 && projectPublishStatus ===  true" class="btn btn-xs btn-primary mt-2" (click)="open_chat_mentor(projectMentor, 'single')">
                                                <span *ngIf="is_chat_room()"><i class="far fa-comment"></i> Chat with mentors</span>
                                                <span *ngIf="!is_chat_room()"><i class="far fa-comment"></i> Initialze Chat</span>
                                            </button>
                                            <div *ngIf="mentor_index == 0 && projectPublishStatus === true">
                                                <div class="d-inline-block dropdown" ngbDropdown #myDrop="ngbDropdown"
                                                    *ngIf="is_mentor_present()">
                                                    <button class="btn btn-xs btn-primary mt-2" id="dropdownManual"
                                                        ngbDropdownAnchor (focus)="myDrop.open()">
                                                        <i class="far fa-comment"></i>
                                                        Chat</button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownManual"
                                                        class="dropdown-style-2"
                                                        *ngIf="passion_project_mentor_list.length > 0">
                                                        <button ngbDropdownItem type="button"
                                                            *ngFor="let user_chat of passion_project_mentor_list"
                                                            (click)="get_user_chat(user_chat)">{{user_chat.name}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-info text-center" *ngIf="passionProject?.project_mentors?.length == 0"> 
                                <p>No Data Available</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <hr /> -->
                <div class="single-passion-row singlePassoinTeam border-bottom  mb-5 pb-4">
                    <div class="title">
                        <h4 class="avenirBlack  d-inline-block mb-0">Teams</h4>

                        <!-- <button class="btn btn-primary chat-btn btn-icon-text float-right"
                            *ngIf="UserRole.matchRole('student')" (click)="openSearchTeamModal(searchTeam)">
                            + Create Team
                        </button> -->
                        <!-- MODAL CREATE TEAMS |START-->
                        <app-team-create *ngIf="projectPublishStatus === true" [viewItemId]="passionProjectId" btnRef="'Create'" (teamStatus)="onGetTeamStatus()"></app-team-create>
                        <!-- MODAL CREATE TEAMS |END-->
                    </div>


                    <div class="row gy-4 gy-md-3 mb-5" *ngIf="processLoader">
                        <div class="col-md-12 text-center">
                            <div class="spinner-border text-warning formLoader" role="status" *ngIf="processLoader">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="projectTeams?.length > 0" class="single-passion-content-inn">
                        <div class="teamTabs">
                            <div class="team-list-box grey-Box">
                                <div class="row gy-4 gy-md-3">
                                    <div class="col-lg-4 col-md-6 team-list-elemBox"
                                        *ngFor="let team of projectTeams; let i = index" [class.mt-4]="i > 2">
                                        <div class="Instructor-profile">
                                            <figure  class="Instructor-pro-fig" style="background-color: #fff; overflow: hidden;">
                                                <img *ngIf="team.logo" [src]="team.logo" [alt]="team.name" />  
                                                <img *ngIf="!team.logo" src="../../../assets/images/team-avtars.png" alt="team-avtars.png" class="placeholderAvtar"/>  
                                            </figure>
                                            <!-- <default-name-icon *ngIf="!team.logo" [name]="team.name"
                                                [figureClass]="''"></default-name-icon> -->
                                            <div class="Instructor-pro-content">                                                
                                                    <h6 class="avenirBlack"><a (click)="viewTeam(team)">{{ team.name | capitalization }}</a></h6>
                                                <div class="mt-1">
                                                    <a href="javascript:void(0);" class="link-bbtn btn-primary-linnk mb-2 d-inline-block" (click)="viewTeam(team)">View Project Report</a>
                                                    <button class="btn btn-xs btn-primary ms-auto d-table"  *ngIf="is_team_object(team) && projectPublishStatus === true"
                                                        (click)="open_chat(team.group_chat.room_id, team.group_chat, 'team')">
                                                        <i class="far fa-comment"></i> Chat
                                                    </button>
                                                </div>
                                                <!-- <button class="btn btn-xs btn-primary ms-auto d-table"><i class="fas fa-pencil-alt"></i> Edit Team
                                                </button> -->
                                                <app-team-create *ngIf="passionProject?.edit_rights == true && projectPublishStatus === true" [viewItemId]="passionProjectId" [btnRef]="'Edit'" [teamData]="team" (teamStatus)="onGetTeamStatus()"></app-team-create>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-info text-center" *ngIf="projectTeams?.length == 0"> 
                        <p>No Data Available</p>
                   </div>
                </div>
                <!-- <hr /> -->
                <div class="single-passion-row singlePassoinGoal border-bottom mb-5 pb-4">
                    <!-- <h5 class="avenirBlack primary-text">Project Goals</h5> -->
                    <h4 class="avenirBlack">Project Goals</h4>

                    <div class="single-passion-content-inn">
                        <ul class="list listGoal" *ngIf="passionProject?.project_goals?.length > 0">
                            <ng-container *ngFor="let item of passionProject?.project_goals">
                                <li *ngIf="item">{{item}}</li>
                            </ng-container>
                        </ul>

                        <div class="alert alert-info text-center" *ngIf="passionProject?.project_goals?.length == 0"> 
                            <p>No Data Available</p>
                        </div>
                    </div>
                </div>
                <!-- <hr /> -->
                <div class="single-passion-row singlePassoinOtherDetal">
                    <h4 class="avenirBlack">Project Resources</h4>

                    <!-- <h5 class="avenirBlack primary-text">Project Resources</h5> -->
                    <div class="single-passion-content-inn">
                        <div class="learning-course-items-wrap learning-course-resources-wrap">
                            <div *ngFor="let projectResource of passionProject?.project_resources"
                                class="learning-course-item">
                                <div class="learning-course-link">
                                    <div class="learning-course-elem-left">
                                        <p class="learning-course-link-title">
                                            <a href="javascript:void(0);" (click)="openProjRes(projectResource)">
                                            <span class="title-icon" *ngIf="projectResource.file_type === 'file'"><i class="fas fa-file"></i></span>
                                            <span class="title-icon" *ngIf="projectResource.file_type === 'image'"><i class="fas fa-image"></i></span>
                                            <span class="title-icon" *ngIf="projectResource.file_type === 'video'"><i class="fas fa-video"></i></span>
                                            <span class="title-icon" *ngIf="projectResource.file_type === 'text'"><i class="fas fa-file"></i></span>
                                                <strong>{{projectResource?.title | capitalization}}</strong>
                                            </a>
                                        </p>
                                    </div>
                                    <div class="learning-course-elem-right">
                                        <ul class="list-icon-wrap">
                                            <li>
                                                <a class="learning-course-download"
                                                    (click)="openProjRes(projectResource)"><i
                                                        class="fas fa-download"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-info text-center" *ngIf="passionProject?.project_resources?.length == 0"> 
                                <p>No Data Available</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <hr /> -->
                <!-- <div class="single-passion-row singlePassoinGoal d-none">
                    <h4 class="avenirBlack">Other Details of the project</h4>
                    <div class="single-passion-content-inn">
                        <div class="row gy-3 gy-lg-0 mb-3 mb-lg-5" *ngFor="let item of passionProject?.project_details">
                            <div class="col-lg-6">
                                <h5 class="avenirBlack">{{item.title}}</h5>
                            </div>
                            <div class="col-lg-6">
                                <p>{{item.details}}</p>
                                <figure class="img-responsive">
                                    <img *ngIf="item?.path_s3" [src]="item?.path_s3" alt="sst-01.png"class="overView-project-img m-auto">
                                    <img *ngIf="!item?.path_s3 && !loader" [src]="defaultThumbnailPic" alt="sst-01.png" class=" overView-project-img m-auto" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
    </div>

    <!--  TEAM CHAT BOX |START -->
    <div class="chatboxModal mosaic_chat_wrap chatboxModal-fixed" #scrollMe id="chatboxModal" *ngIf="is_chat_true">
        <!-- Chat Header -->
        <div class="chatboxModal-header bg-yellow">
            <div class="chatboxModal-header-detail">
                <div class="Instructor-profile">
                    <figure class="Instructor-pro-fig chat-user-icon" *ngIf="avatar_url"><img class="image_class" [src]="avatar_url" alt="get_username(chat_user_name)" /></figure>
                    <default-name-icon *ngIf="!avatar_url" [name]="get_username(chat_user_name)" [figureClass]="'chat-user-icon'">
                    </default-name-icon>
                </div>
                <div class="chatboxModal-header-proDetal">
                    <p class="contDetail"><strong class="d-block">{{get_username(chat_user_name)}}</strong>
                        <!-- <small>Active Now</small> -->
                    </p>
                </div>
            </div>

            <div class="chatboxModal-header-action">
                <!-- <a type="button" class="chat-cta-btn chat-cta-expand"><i class="fas fa-expand-alt"></i></a> -->
                <div ngbDropdown class="optDDBox">
                    <i ngbDropdownToggle class="fas fa-ellipsis-v"></i>
                    <div ngbDropdownMenu class="passGuftaGuMenu">
                        <button ngbDropdownItem (click)="exit_group(group_details)">Exit Group</button>
                        <!-- <button ngbDropdownItem class="delete_group"
                                    (click)="delete_group(group_details)">Delete Group</button> -->
                    </div>
                </div>
                <a type="button" class="chat-cta-btn chat-cta-close" (click)="close_chat(group_details)"><i
                        class="fas fa-times"></i></a>
            </div>
        </div>
        <!-- Chat Body -->
        <div class="chatboxModal-body" #scrollMe [scrollTop]="get_height(scrollMe.scrollHeight)">
            <div *ngFor="let msg of get_message()">
                <div class="chatboxModal-msg-elem msg-left" *ngIf="msg.user_id == userId">
                    <div class="Instructor-profile">
                        <figure class="Instructor-pro-fig chat-user-icon" *ngIf="msg.profile_pic">
                            <img [src]="msg.profile_pic" [alt]="msg.sender_name">
                        </figure>
                        <default-name-icon *ngIf="!msg.profile_pic" [name]="msg.sender_name" [figureClass]="'chat-user-icon'">
                        </default-name-icon>
                    </div>
                    <div class="chatboxModal-msg-cont">
                        <small>{{msg.username}}</small>
                        <p class="msg-block" *ngIf="msg.message_type == 'text'">{{msg.message}}</p>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'image'">
                            <figure class="chatMsgHarFig" *ngFor='let img of msg.urls'>
                                <img [src]="img.url" height="150" width="100px" class="chatMsgHarImg"
                                    (click)="openImgModal(view_image, img)">
                            </figure>
                        </div>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'file'">
                            <button type="button" *ngFor="let img of msg.urls; let i=index"
                                class="btn btn-lg btn-outline-primary mr-2 mb-1" [title]="img.file_name"
                                (click)="openInNewWindow(img)">
                                <i class="far fa-file-alt"></i>
                            </button>

                        </div>
                        <small>{{get_time(msg.created_at)}}</small>
                        <small *ngIf="msg.is_read"><i class='fas fa-check-double'></i></small>
                        <small *ngIf="!msg.is_read"><i class='fas fa-check'></i></small>
                    </div>
                </div>

                <div class="chatboxModal-msg-elem msg-right" *ngIf="msg.user_id != userId">
                    <div class="Instructor-profile">
                        <figure class="Instructor-pro-fig chat-user-icon" *ngIf="msg.profile_pic">
                            <img [src]="msg.profile_pic" [alt]="msg.sender_name">
                        </figure>
                        <default-name-icon *ngIf="!msg.profile_pic" [name]="msg.sender_name" [figureClass]="'chat-user-icon'">
                        </default-name-icon>
                    </div>
                    <div class="chatboxModal-msg-cont">
                        <small>{{msg.username}}</small>
                        <p class="msg-block" *ngIf="msg.message_type == 'text'">{{msg.message}}</p>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'image'">
                            <figure class="chatMsgHarFig" *ngFor='let img of msg.urls'>
                                <img [src]="img.url" height="150" width="100px" class="chatMsgHarImg"
                                    (click)="openImgModal(view_image, img)">
                            </figure>
                        </div>
                        <div class="chatMsgHarFigBlk" *ngIf="msg.message_type == 'file'">
                            <button type="button" *ngFor="let img of msg.urls; let i=index"
                                class="btn btn-lg btn-outline-primary mr-2 mb-1" [title]="img.file_name"
                                (click)="openInNewWindow(img)">
                                <i class="far fa-file-alt"></i>
                            </button>

                        </div>
                        <small>{{get_time(msg.created_at)}}</small>
                        <small *ngIf="msg.is_read"><i class='fas fa-check-double'></i></small>
                        <small *ngIf="!msg.is_read"><i class='fas fa-check'></i></small>
                    </div>
                </div>

                <span class="d-block text-center msg-date-divide">{{is_day(msg.created_at)}}</span>


            </div>
            <span class="d-block text-center msg-date-divide" *ngIf="get_length_message() > 0">Today</span>
            <div id="message">

            </div>
        </div>
        <!-- Chat Footer -->
        <div class="chatboxModal-footer">
            <form action="" #messageform="ngForm">
                <div class="msg-submit-field d-flex align-items-center">
                    <input type="text" name="message_view" class="form-control" (keydown.enter)="send_message()"
                        [(ngModel)]="message_view" placeholder="Type a Message" />
                    <div class="btn-group btn-group-right">
                        <a type="button" (click)="send_message()" class="more-ctcBtn msgsubmit-btn"><i
                                class="fas fa-paper-plane"></i></a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!--  TEAM CHAT BOX |END -->

    <ng-template #view_image let-modal>
        <div class="modal-header border-0 p-0">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modalprevImgBody">
            <div>
                <div class="modalprevAntionBar">
                    <button class="modalprevImgBtn download-img" (click)="download_file()"><i
                            class="fas fa-cloud-download-alt"></i></button>
                </div>
                <div class="modalprevFig">
                    <img [src]="image_url.url" alt="cover-image-sample.jpg" />
                </div>
            </div>
            
            <!-- <img  [src]="img.url" height="150" width="100px" class="chatMsgHarImg" /> -->
        </div>
    </ng-template>

</div>
