<div class="new_UIstyle">
    <div class="pageBodyWrap">

        <!-- Banner Section Start for Teacher -->
        <section class="banner banner-home banner-home-teacher"
            style="background-image: url('assets/images/banner/banner-teacher.png');">
            <div class="container">
                <div class="banner-content text-center">
                    <h1 class="white-text">Come teach with us</h1>
                    
                    <ul class="banner-h-list d-flex align-items-center mb-3 mb-lg-5">
                        <li>Plan Your Course</li>
                        <li>Record Your Course</li>
                        <li>Publish Your Course</li>
                    </ul>
                    <div>
                        <button class="btn btn-primary" routerLink="/courses/add">+ Create New Course</button>
                    </div>
                </div>
            </div>
        </section>

        <!-- pickup teacher Section Start -->
        <div class="relative" [style.minHeight]="loader == true ? '40vh': ''">
            
            <section class="section teacher-course-wrap">
                <div class="container">

                    <div class="title teacher-course-title">
                        <h3 class="mb-lg-0">Edu-collab Archived Courses</h3>
                    </div>

                    <div class="relative" [ngClass]="{submitting : loader}">
                        <div class="text-center pt-5 pb-5" *ngIf="loader">
                            <div class="spinner-border text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="teacher-course-list">
                        <div class="mb-3" *ngFor="let course of deactivated_course_list | paginate: { itemsPerPage: 10, currentPage: pageP, totalItems:totalLengthU }; let i=index">
                            <div class="teacher-course-items teacher-course-items-vCenter mb-0">
                                <div class="teacher-course-items-left">
                                    <figure class="teacher-course-fig"><img src="{{course.banner_img}}"
                                            alt="course-item-1.png" /></figure>
                                    <div class="teacher-course-items-detail">
                                        <h6 class="avenirBlack mb-1">
                                            <a href="javascript:void(0);"
                                                (click)="sendCourseDetails(course.course_id, course.subject)"><span
                                                    class="d-inline-block">{{ course.subject }}</span></a>
                                        </h6>
                                        <p [innerHtml]="course.learning_objectives"></p>
                                        <div class="rating-elem">
                                            <ngb-rating max="5" [(rate)]="course.rating"></ngb-rating>
                            
                                        </div>
                                        <div class="teacher-course-items-status"><span
                                                class="badge badge-light badge-green-dark">{{course.course_category}}{{course.active}}</span></div>
                                    </div>
                                </div>
                                <div class="teacher-course-items-right">
                                    <div class="btn-groups  justify-content-end">

                                        <a class="deactivate_course mr-5" type="button" ngbTooltip="View Course" container="body" (click)="sendCourseDetails(course.course_id, course.subject)">
                                            <i class="fas fa-eye"></i>
                                        </a>
                                       
                                        
                                                     <form action="">
                                                     <div class="form-check form-switch form-switch-right">
                                               
                                                        <input name="tt" [(ngModel)]="course.active" class="form-check-input" container="body"  ngbTooltip="Activate" (change)="activeCourseModel(deativeCourse)" *ngIf="role =='super_admin'"
                                                         type="checkbox">
                                            </div>
                                                     </form>
                                        <!-- new ui deative course -->
                                        <ng-template #deativeCourse let-c="close" let-d="dismiss">
                                            <div class="new_UIstyle">
                                                <div class="modal-header justify-content-between align-items-center">
                                                    <h5 class="avenirBook modal-title text-danger mb-0">Reactivate Course
                                                    </h5>
                                                    <button type="button" class="close ml-0" aria-label="Close"
                                                        (click)="closeCoursePopup()">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Are you sure you want to reactivate this course again<strong
                                                            class="text-danger">?</strong></p>
                                                </div>

                                                <div class="modal-footer">
                                                    <div class="text-left">
                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="closeCoursePopup()">NO</button>
                                                    </div>
                                                    <div class="text-right">
                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="activate_course(course.course_id)">YES</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        
                                    </div>

                                    


                                </div>
                               
                            </div>
                        </div>
                        <div class="alert alert-info text-center mb-2" *ngIf="deactivated_course_list.length == 0 && !loader">
                            <p>No Result Found.</p>
                        </div>
                        <pagination-controls *ngIf="deactivated_course_list.length > 0 && !loader" class="text-center my-pagination"
                            previousLabel="<" nextLabel=">" maxSize="30"
                            (pageChange)="currentPageNo($event)"></pagination-controls>

                    </div>
                </div>
            </section>
        </div>

    </div>
</div>