import { Injectable } from '@angular/core';
import { Observable, Subscriber, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { io } from 'socket.io-client';
import { NotificationService } from '../notification.service';
import { ShareService } from '../share.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  sockets;
  Socket_endpoint;
  APIEndpoint;
  room_id = '';
  receive_message_view = [];
  receiveStatus = [];
  receive_request_:any;
  user_id:string = '';
  video_status:any=[]
  timerInterval:any;
  userId:any;

  constructor(private https: HttpClient, private ns: NotificationService, private shareService:ShareService) {
    this.user_id = localStorage.getItem('user_id')
    this.Socket_endpoint = environment.socket_EndPoint;
    this.APIEndpoint = environment.APIEndpoint;
    this.sockets = io(this.Socket_endpoint);
    this.userId = localStorage.getItem('user_id')
    this.receive_permission();
    this.receive_message();
    this.receiveLiveSession();
    this.get_user_status();
    this.get_refresh_status();
    this.receiveLiveSessionForadmin();
  }

  send_request(user_id, name) {
    let sender_id = localStorage.getItem('user_id')
    let sockets = io(this.Socket_endpoint + "/notifs");
    sockets.emit('send_permission', {
      sender: sender_id,
      receiver: user_id
    });     
  }

  Accept_decline(sent_to, sent_by, answer): Observable<any> {
    let body = {
      'sent_by': sent_by,
      'sent_to': sent_to,
      'answer': answer
    }
    return this.https.post<any>(this.Socket_endpoint + '/chat/request', body);
  }

  view_room(room_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('room_id', room_id);
    return this.https.get<any>(this.Socket_endpoint + '/chat/room', { params });
  }

  join_room(username, room_id) {
    this.sockets.emit('join_room', {
      username: username,
      room: room_id
    });
  }


  send_message(username, room_id, message, files?: String[]) {
    let room = room_id
    this.room_id = room_id;
    if (files && files.length > 0) {
      message = null;
    } else {
      files = null
    }
    localStorage.setItem('room_id', room_id);
    this.sockets.emit('send_message', {
      "username": username,
      "room": room,
      "message": message,
      'resource_ids': files,
      'type': 'web'
    });   
    
  }

  receive_message() {
    this.user_id = localStorage.getItem('user_id')
    this.sockets.on('receive_message', (data) => {        
      if(data && data.receivers.length > 0){
        let isHasUser = data.receivers.filter((userId)=>{
           if(userId == this.user_id || data.user_id == this.user_id){
             return true;
           }
         })
         
         if(isHasUser.length > 0){
           this.receive_message_view.push(data);        
         }
      }
    });
  }


  // emit online status
  refresh_status(refresh_member_ids) {
    this.sockets.emit('refresh_chat', { "receivers": refresh_member_ids});                
  }


  get_refresh_status(): Observable<any>{
    return new Observable<any>((observer)=>{
     this.sockets.on('refresh_chat_status', (data)=>{
       observer.next(data);
     })
    }) 
 }


 get_notification_data(): Observable<any>{
  return new Observable<any>((observer)=>{
   this.sockets.on('global_notifications', (data)=>{
     observer.next(data);
   })
  }) 
}

 


  // emit online status
  set_user_status(chat_status, user_id) {
    this.sockets.emit('set_user_status', { "chat_status": chat_status, "user_id": user_id});            
  }

  // listen for online status updates
  get_user_status(): Observable<any>{
     return new Observable<any>((observer)=>{
      this.sockets.on('current_user_status', (data)=>{
        observer.next(data);
      })
     }) 
  }

  receive_permission(): Observable<any>{
    let sockets = io(this.Socket_endpoint + "/notifs");
    return new Observable<any>((observer)=>{
     sockets.on('receive_permission', (data)=>{
       observer.next(data);
     })
    }) 
 }

  close_socket() {
    this.sockets.disconnect();
  }

  create_group(user_info, group_name, group_icon): Observable<any> {
    let body = {
      'members': user_info,
      'name': group_name,
      'group_description': '',
      'sub_heading': '',
      'avatar_url': group_icon
    }
    return this.https.post<any>(this.Socket_endpoint + '/chat/create-group', body);
  }


  get_group_info(room_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('room_id', room_id)
    return this.https.get<any>(this.Socket_endpoint + '/chat/group-info', { params });
  }

  get_all_group(): Observable<any> {
    return this.https.get<any>(this.Socket_endpoint + '/chat/all-groups');
  }

  get_all_group_new(scrollNumber, groupLength, room_id:string): Observable<any> {
    let params = new HttpParams();
    params = params.append('app_type', 'webapp');
    params = params.append('page', scrollNumber);
    params = params.append('total_groups', groupLength);
    params = params.append('room_id', room_id);
    return this.https.get<any>(this.Socket_endpoint + '/chat/all-groups', { params });
  }

  update_group(more_member, group_name, description, group_icon, room_id, delete_member): Observable<any> {
    let params = new HttpParams();
    let body;
    let userId = [];
    if (delete_member) {
      params = params.append('room_id', room_id)
    }

    body = {
      'more_users': more_member,
      "group_name": group_name,
      "group_description": description,
      "group_photo": group_icon,
      "room_id": room_id,
      "remove_users": [...delete_member]
    }



    return this.https.patch<any>(this.Socket_endpoint + '/chat/update-group-info', body, { params })
  }

  exit_group(room_id): Observable<any> {
    let body = {
      "room_id": room_id
    }
    return this.https.patch<any>(this.Socket_endpoint + '/chat/exit-group', body)
  }

  delete_group(room_id): Observable<any> {
    let params = new HttpParams();
    params = params.append("room_id", room_id)
    return this.https.delete<any>(this.Socket_endpoint + '/chat/delete-group', { params })
  }

  connection() {
    if (this.sockets.disconnected) {
      this.Socket_endpoint = environment.socket_EndPoint;
      this.sockets = io(this.Socket_endpoint);
      this.receive_message();
      this.get_user_status();
    }
    let user_id = localStorage.getItem('user_id')
    this.sockets.emit('connection', {
      'user_id': user_id
    });
  }

  // LIVE SESSION SOCKET CONNECTION

  liveSessionConnection(course_id, topic_id, singleCoursetab = 1) {
    if(singleCoursetab == 1){
      if ( course_id  && topic_id) {
           this.sockets.emit('live_session_status', {
             "course_id": course_id,
             "topic_id": topic_id,
             "user_id":this.userId
           });        
      }
    } else if(singleCoursetab == 5){
      if (course_id) {
        this.sockets.emit('live_session_status', {
          "course_id": course_id,
          "user_id":this.userId
        });
      } 
  }
    
    
  }

  receiveLiveSession() {
    this.sockets.on('current_live_session_status', (data) => {
      if(data && data.user_id == this.userId){
        this.receiveStatus = [];
        this.receiveStatus = data.course_live_sessions;    
        // console.log(data);
      } else{
        // console.log('Extra Soket else.');        
      }
      
      // if(data.course_id == localStorage.getItem('course_id')){
      // this.receiveStatus = [];
      // }
    });
  }

  // video_duration:number
  // admindata:any;
  // receiveLiveSessionForadmin() {
  //   // console.log("zoom outside")
  //   this.sockets.on('zoom_live_session_status_update', (data) => {
  //     if(data){
  //       if(data.course_id == localStorage.getItem('course_id')){
  //         this.video_status = [];
  //       this.admindata = data;
  //       console.log(data);
        
  //         this.video_status = data;
  //         this.video_duration = data.duration

  //       return  this.admindata;
  //       }
  //     }
  //   });

  // }

  receiveLiveSessionForadmin(): Observable<any>{
    return new Observable<any>((observer)=>{
     this.sockets.on('zoom_live_session_status_update', (data)=>{
       observer.next(data);
     })
    }) 
 }


  get_notification_list = [];
  get_notification() {
    // let room_id = this.room_id
    let notification = [];
    let nofity = this.ns;
    this.sockets.on('send_notification', function (data) {
      notification.push(data)
      let is_Mute:boolean = data.is_mute;      
      if(!is_Mute){        
        nofity.showNotification('success', data.sender_name + ' sent you a message.');
      }
      // if (notification.length % 10 == 0) {
      //   this.ns.showNotification('success', 'You have got some message from groups and chats');
      // }
    })
    this.get_notification_list = notification;
  }

  leave_room(room_id, user_id) {
    this.sockets.emit('leave_room', {
      'room': room_id,
      'username': user_id
    });
  }

  acknowledgement(room_id, user_id, message_id) {
    this.sockets.emit('message_acknowledgement', {
      'room': room_id,
      'username': user_id,
      'message_id': message_id
    });
  }

  disconnect() {
    this.sockets.disconnect();
  }

  initialize_chat(project_name, admin_list, project_Des, project_id) {
    let body = {
      "group_name": project_name,
      "admins": admin_list,
      "group_description": project_Des,
      "subheading": "",
      "passion_project_id": project_id
    }
    return this.https.post<any>(this.APIEndpoint + '/init_mentor_chat', body)
  }

  get_passion_project_proposal(passion_project_id) {
    let params = new HttpParams();
    params = params.append('passion_project_id', passion_project_id);
    return this.https.get<any>(this.APIEndpoint + '/passion_project_proposal', { params })
  }

  count_update = [];
  getCountAmount:any;
  get_count() {
    let count_update = [];
    let $this = this;
    $this.sockets.on('send_chat_users', function (data) {
      let room_id = localStorage.getItem('room_id');     
      
      
      this.getCountAmount = data;
      
      // if (room_id != data.room_id) {
        count_update = [];
        count_update.push(data);        
        console.log(count_update);
      // }     
      $this.count_update = count_update;
      
      $this.shareService.setCountNumber(data.total_unread_messages);   
         
    });
  }

}
