import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { AssessmentShareService } from 'src/app/services/assessment-share.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';

@Component({
  selector: 'app-unapproved-assessment',
  templateUrl: './unapproved-assessment.component.html',
  styleUrls: ['./unapproved-assessment.component.scss']
})
export class UnapprovedAssessmentComponent implements OnInit {

  @Input() unapproved_assessment_data:any;
  @Input() closeAssementEvent: Observable<void>;
  @Input() course_active:any = false
  @Output()  delete_Assessment_emit = new EventEmitter<string>();
  @Output()  aiAssessmentDetailEmit = new EventEmitter<string>();
  questionModel = null;

  session_id:string = '';
  topic_id:string = '';
  isTA:string = ''
  activeIdNav:any = 1;
  courseId:string = '';
  courseName:string = '';
  course_category_id:string = '';
  course_category:string = '';
  sessionkey:string = '';

  private eventsSubscription: Subscription;


  constructor(private _assessmentShareService: AssessmentShareService,
    private router: Router,
    private modalService: NgbModal,
    private _questionBankService: QuestionBankService,
    private _notificationService: NotificationService,
    private activatedRoute: ActivatedRoute) { }

    
  ngOnInit() {
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.topic_id = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.isTA = this.activatedRoute.snapshot.queryParamMap.get('isTA');
    this.activeIdNav = this.activatedRoute.snapshot.queryParamMap.get('tab_no');
    this.courseName = localStorage.getItem('courseName');
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');
    this.sessionkey = this.activatedRoute.snapshot.queryParamMap.get('sessionkey');

    this.eventsSubscription = this.closeAssementEvent.subscribe(() => this.closeAssPopup());
  }


  gotoViewAssessment(data) {
    this._assessmentShareService.setAssessment(data.title);
    localStorage.setItem('assessment_name', data.title);
    // this.router.navigate(['/view-assessment', data._id]);

    this.router.navigate(['/view-assessment',data._id], {
      queryParams: {
        course_id: this.courseId,
        course:this.courseName,
        session_id: this.session_id,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId: this.topic_id,
        sessionkey: this.sessionkey,
        isTA: this.isTA,
        tab_no:this.activeIdNav,
        // ngbNavItem:1
      }
    })
  }


  openModelDeleteAss(deleteAss, _id) {
    this.questionModel = this.modalService.open(deleteAss, { size: "sm", centered: true });
  }

  closeAssPopup() {
    this.questionModel.close();
  }

  delete_Assessment_event(_id:string){
   this.delete_Assessment_emit.emit(_id) ;
  }

  editassessment(assessment) {
    this.aiAssessmentDetailEmit.emit(assessment._id);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
