<!-- New Code -->
<div class="new_UIstyle">
    <div class="pageBodyWrap">

        <!-- banner section -->
        <div class="banner banner-gradient banner-C-assessment"
            style="background-image:url('../../../assets/images/banner/banner-question.png');">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">Question</h2>
                        <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
                        <!-- <div class="tags-badges">
                     <span class="badge badge-light badge-primary">Draft</span>
                 </div> -->
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary" (click)="backToQsnBank()"><i class="fa fa-arrow-left"></i>
                            Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- banner section -->

        <section class="section addQuestionWrap">
            <div class="container" [ngClass]="{submitting : loader}">
                <div class="text-center pt-2 pb-2">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="questionDetailWrap ass_ques_btn_wrap">
                    <div class="course-assessment-qsn  mb-3 " *ngIf="resDetails?.questype.type != 'Text' && resDetails?.questype.type != 'Speech' && resDetails?.questype.type != 'Fill in the blanks'" >
                        <!-- <span class="badge badge-green-lt mb-2">Marks: 60</span> -->
                        <h5 class="avenirBlack" [ngClass]="{'mb-1':resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                        || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url || resDetails?.questype.type === 'Multi Answer MCQ'}" >
                           <div [innerHTML]="resDetails?.question" ></div>
                           <span class="number-icon">1 </span>
                        </h5>
                        <p *ngIf="resDetails?.questype.type === 'Multi Answer MCQ'" class="mb-1"><small><i>Choose one or more correct answers. Select all that apply.</i></small></p>

                        <button type="button" class="mb-2 audio_video_iconButtton" 
                                (click)="openAudioVideoPopup()" 
                               *ngIf="resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                               || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url" >Click this link to play the audio/video to answer the question.</button>

                        <div class="row gy-2" *ngIf="resDetails?.questype.type === 'Multi Answer MCQ'" >                   
                                    <div class="col-md-6 custom-control custom-checkbox mb-2"
                                        *ngFor="let option of resDetails?.meta_data;let ii = index">
                                        <input type="checkbox" [value]="option.option"
                                            class="form-check-input custom-control-input me-2"  readonly>
                                        <label class="form-check-label custom-control-label" >
                                            <div class="d-inline-block vTop"
                                                [innerHTML]="option.option"></div>
                                        </label>
                                    </div>
                        </div>

                        <div class="row gy-2" *ngIf="resDetails?.questype.type !== 'Multi Answer MCQ'">                   
                            <div class="col-md-6 custom-control custom-radio mb-2"
                                *ngFor="let option of resDetails?.meta_data;let ii = index">
                                <input type="radio" [value]="option.option"
                                    class="form-check-input custom-control-input me-2"  readonly>
                                <label class="form-check-label custom-control-label" >
                                    <div class="d-inline-block vTop"
                                        [innerHTML]="option.option"></div>
                                </label>
                            </div>
                        </div>
                        <div class="dark-green-text mb-5" *ngIf="resDetails?.correct_answer.length > 0">
                            <p><strong>Correct Answer:</strong></p> 
                            <div class="primary-text" [innerHTML]='resDetails?.correct_answer'></div>
                        </div>
                    </div>

                </div>
              
                <div class="questionDetailWrap ass_ques_btn_wrap">
                    <div class="course-assessment-qsn  mb-3" *ngIf="resDetails?.questype.type == 'Text' && resDetails?.questype.type != 'Fill in the blanks'" >
                       
                        <h5 class="avenirBlack" [ngClass]="{'mb-1':resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                        || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url}">
                           <div [innerHTML]="resDetails?.question"></div>
                           <span class="number-icon">1 </span>
                        </h5>

                        <button type="button" class="mb-2 audio_video_iconButtton" 
                        (click)="openAudioVideoPopup()" 
                       *ngIf="resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                       || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url" >Click this link to play the audio/video to answer the question.</button>


                       <div class="" *ngIf="resDetails?.correct_answer.length > 0">
                           <label class="avenirBlack dark-green-text">Correct Answer:</label> 
                           <div class="form-control form-control-textarea" [innerHTML]="resDetails?.correct_answer"></div> 
                       </div>

                    </div>

                </div>

                <div class="questionDetailWrap ass_ques_btn_wrap">
                    <div class="course-assessment-qsn  mb-3" *ngIf="resDetails?.questype.type == 'Speech'" >
                        <h5 class="avenirBlack" [ngClass]="{'mb-1':resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                        || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url}">
                           <div [innerHTML]="resDetails?.question"></div>
                           <span class="number-icon">1 </span>
                        </h5>

                        <button type="button" class="mb-2 audio_video_iconButtton" 
                        (click)="openAudioVideoPopup()" 
                       *ngIf="resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                       || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url" >Click this link to play the audio/video to answer the question.</button>
                       
                        <p class="dark-green-text mb-5" *ngIf="resDetails?.correct_answer.length > 0"><strong>Correct Answer: <span
                            class="primary-text" [innerHTML]="resDetails?.correct_answer"></span></strong></p>
                    </div>

                </div>


                <div class="questionDetailWrap ass_ques_btn_wrap">
                    <div class="course-assessment-qsn  mb-3" *ngIf="resDetails?.questype.type == 'Fill in the blanks'" >
                       
                        <h5 class="avenirBlack" [ngClass]="{'mb-1':resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                        || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url}">
                           <div [innerHTML]="resDetails?.question"></div>
                           <span class="number-icon">1 </span>
                        </h5>

                        <button type="button" class="mb-2 audio_video_iconButtton" 
                        (click)="openAudioVideoPopup()" 
                       *ngIf="resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url 
                       || resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url" >Click this link to play the audio/video to answer the question.</button>

                       <div class="" *ngIf="resDetails?.correct_answer.length > 0">
                           <label class="avenirBlack dark-green-text">Correct Answer:</label> 
                           <div class="form-control form-control-textarea" [innerHTML]="resDetails?.correct_answer"></div> 
                       </div>

                    </div>

                </div>
            </div>
        </section>

    </div>
</div>



<!-- audio/video question modal -->
<app-audio-video-model *ngIf="openVideoAudioPopup" [openVideoAudioPopup]="openVideoAudioPopup" 
(openVideoAudioPopupClick)="closeAudioVideoPopup()"
[videoCondion]="resDetails?.questionrecordedvideokey && resDetails?.questionrecordedvideokey?.url" 
[audioCondion]="resDetails?.questionrecordedaudiokey && resDetails?.questionrecordedaudiokey?.url"
[videoUrl]="resDetails?.questionrecordedvideokey?.url"
[audioUrl]="resDetails?.questionrecordedaudiokey?.url"></app-audio-video-model>