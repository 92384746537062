import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SocketService } from '../services/web/socket.service';
import { HeaderService } from '../services/header.service';
import { tr } from 'date-fns/locale';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifyLoader:boolean = false;
  user_id:string = '';
  notificationList:any = [];
  currentPage:number = 1;
  itemPerPage:number;
  totalPages:number;
  default_svg_project:string;
  default_svg_course:string;
  default_svg_profile:string;
  default_svg_guftagu:string;
  default_svg_calender:string;
  
  defaultAvtar:string;
  defaultAvtarGroup:string;
  isNotifiyShow:any = false;
  send_to:string = null;
  get role() {
    return localStorage.getItem('role');
  }
  constructor(private notificationService:NotificationService,
    private router:Router,
    private headerService:HeaderService,
    private socketService:SocketService) { 

    this.default_svg_project = environment.svgBasePath + environment.default_Pic_project;
    this.default_svg_course = environment.svgBasePath + environment.default_Pic_course;
    this.default_svg_profile = environment.svgBasePath + environment.default_Pic_profile;
    this.default_svg_guftagu = environment.svgBasePath + environment.default_Pic_guftagu;
    this.default_svg_calender = environment.svgBasePath + environment.default_Pic_calender;
    
    this.defaultAvtar = environment.imageBasePath + environment.userDefaultProfilePic;
    this.defaultAvtarGroup = environment.imageBasePath + environment.userDefaultGroupPic;
  }

  ngOnInit() {
    this.user_id = localStorage.getItem('user_id');
    let isNotifiyResult = localStorage.getItem('notificationStatus');
    this.isNotifiyShow  = isNotifiyResult === 'true' ? true:false;
    this.getNotificationData();
    this.get_notify_status();
    this.headerService.notifyCountNumAs.subscribe((response:number)=>{
      this.notifyCount = response;
    })
  }
  currentPageNo(pageNo) {
    this.currentPage = pageNo;
    
    if (pageNo) {
      this.getNotificationData();
    }
  }


  getNotificationData(){
    this.notificationList = [];
    this.notifyLoader = true;
    this.notificationService.getNotifications(this.currentPage).subscribe((response)=>{
      let responseData = response;
      let filter_by_user = responseData.notifications;

      filter_by_user.forEach((notification:any)=>{
        notification.receivers.filter((receiver:any)=>{
          if(receiver == this.user_id){
            this.notificationList.push(notification);
          }
        })
      })
      // this.notificationList = responseData.notifications;
      this.itemPerPage =  this.notificationList.length;
      this.totalPages =  this.itemPerPage * responseData.max_pages;
      this.notifyLoader = false
    },(error)=>{
      console.log(error);
    })
  }


  isAccept:boolean = false
  isdeclined:boolean = false
  receive_request(pc, answer) {
    // console.log(pc)
    let send_by = localStorage.getItem('user_id');
    this.send_to = pc.content.sender_id;

    let view_room: any[];
    this.socketService.Accept_decline(send_by, this.send_to, answer).subscribe(res => {
      console.log(res)
      view_room = res.message;
      if (answer == 'accept') {
        this.isAccept = true;
        this.isdeclined = false;
        this.notificationService.showNotification('success', "Thank you for accepting the request. Let's chat!");
      } else if (answer == 'decline') {
        this.isAccept = false;
        this.isdeclined = true;
        this.notificationService.showNotification('success', 'No worries, Thank you!');
      }
    },
      (err) => {

      })
  }

  deleteNotification(notificationVal){
    if(confirm('Are you sure want to delete notification.'))
    this.notificationService.deleteNotify(notificationVal._id).subscribe((response)=>{
      this.notificationService.showNotification('success', response);   
      this.getNotificationData();   
      this.getNotificationApiData()
    }, (err)=>{
      this.notificationService.showNotification('error', err);      
    })
  } 

  showHideNotification(){
    this.isNotifiyShow = !this.isNotifiyShow;
    localStorage.setItem('notificationStatus', String(this.isNotifiyShow));    
    this.notificationService.show_Hide_notification(this.isNotifiyShow).subscribe((response)=>{  
      this.notificationService.showNotification('success', response.message);   
      // this.getNotificationData();   
    }, (err)=>{
      this.notificationService.showNotification('error', err.message);      
    })
  } 

  get_notify_status(){
    this.socketService.get_notification_data().subscribe(async (response)=>{
      response.receivers.filter((receiver:any)=>{
          if(receiver == this.user_id){
            this.notificationList.unshift({...response});
          }
        })
    })
  }


  redirectTo(NotifiyData){
    this.readNotifyMethod(NotifiyData._id);   
    if(NotifiyData.type == 'messages'){
      this.headerService.SetNotifyEvent(true);      
      if(NotifiyData.content.group_chat == false){
        this.router.navigate(['/guftagu'], { queryParams:{ chatRoomId:NotifiyData.content.room_id}})
      } else if(NotifiyData.content.group_chat == true){
        this.router.navigate(['/guftagu'], { queryParams:{ group_room_id:NotifiyData.content.room_id}})
      }
    } else if(NotifiyData.type == 'chat_request'){
      this.headerService.SetNotifyEvent(true);      
      this.router.navigate(['/guftagu'], { queryParams:{ rq_sender_id:NotifiyData.content.sender_id}})
    } else if(NotifiyData.type == 'chat_invite'){
      this.router.navigate(['/guftagu']);
    } else if(NotifiyData.type == 'calendar_reminders_or_tasks'){
      this.router.navigate(['/schedules'])
    } else if(NotifiyData.type == 'TA_assignment'){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id , tab_no:4}})
    } else if(NotifiyData.type == 'AI_assessment'){
      localStorage.setItem('ngbNavItem', '5');
      localStorage.setItem('active_tab_assessment', 'unApprovedAssessment')
      this.router.navigate(['/courses/video-with-notes'], { queryParams:{ 
        course_id: NotifiyData.content.course_id,
        session_id: NotifiyData.content.session_id,
        topicId: NotifiyData.content.topic_id,
        sessionkey: 'session',
        isTA: false,
        ngbNavItem: 5,
        notification:true,
        tab_no:4}})
        

    }  else if(NotifiyData.type == 'mosaic_team'){
      this.router.navigate(['/passion-projects/view'], { queryParams:{ id:NotifiyData.content.project_id}})
    } else if(NotifiyData.type == 'marked_coursework'){
      let activeIdNav:any;
      if(NotifiyData.content.topic_id !== ''){
        activeIdNav = 1
      } else{
        activeIdNav = 7
      }
      this.router.navigate(['/courses/course-work'], { queryParams: { courseWork: NotifiyData.content.coursework_id, course_id: NotifiyData.content.course_id,course:NotifiyData.content.course_name, topic_id: NotifiyData.content.topic_id,tab_no:activeIdNav, notification:true } });
    } else if(NotifiyData.type == 'marked_assessment'){
      this.router.navigate(['/courses/feedback-assessement'] , { 
        queryParams: {
          course_id:NotifiyData.content.course_id, 
          course:NotifiyData.content.course_name,
          assessment_id:NotifiyData.content.assessment_id, 
          assessment_name:NotifiyData.content.assessment_name, 
          assessment_result_id:NotifiyData.content.assessment_result_id,
          user__id:this.user_id, 
          topicId: NotifiyData.content.topic_id, 
          session_id: NotifiyData.content.session_id,
          sessionkey:'session' ,
          isTA:false,
          notification:true 
        } 
      })
    } else if(NotifiyData.type == 'changes_by_admin'){
      this.router.navigate(['/public-profile'])
    } else if(NotifiyData.type == 'topic'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, topic_id:NotifiyData.content.topic_id , tab_no:1}})
     } else if(NotifiyData.type == 'question'){     
      this.router.navigate(['/question-details'], { queryParams:{ questionId:NotifiyData.content.question_id, info:'allQus'}})
     } else if(NotifiyData.type == 'announcement'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:2}})
     } else if(NotifiyData.type == 'TA_students'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:7}})
     }else if(NotifiyData.type == 'course_coursework' || NotifiyData.type == 'coursework'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:7}})
     } else if(NotifiyData.type == 'coursework_invite'){  
      
      if(NotifiyData.content.topic_id){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:1}})
      } else{
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:7}})
      }
     } else if(NotifiyData.type == 'course_assessment'){     
      this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id, tab_no:6}})
     } else if(NotifiyData.type == 'assessment'){   
      if(NotifiyData.level == 'course'){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id,  topic_id:NotifiyData.content.topic_id, tab_no:6 }})
      }  else if(NotifiyData.level !== 'course'){
        this.router.navigate(['/courses/single-course'], { queryParams:{ course_id:NotifiyData.content.course_id,  topic_id:NotifiyData.content.topic_id, tab_no:1 }})
      }
     } else if(NotifiyData.type == 'mantee_session_visibility') {
        this.router.navigate(['/public-profile']);
     } else if(NotifiyData.type == 'mentor_session_booking') {
      this.router.navigate(['/schedules'], {queryParams:{sbi:NotifiyData.content.booking_id}});
      } if(NotifiyData.type == 'mentor_session_reminder') {
        this.router.navigate(['/schedules'], {queryParams:{sbi:NotifiyData.content.mentor_session_id}});
    }

  }

  readNotifyMethod(notify_id){
    this.notificationService.readNotify(notify_id).subscribe((response:any)=>{
      this.getNotificationApiData();
    },error=>{
      console.log(error.message);
    })
  }

  notifyCount:number = 0;
  getNotificationApiData(){
    this.notificationService.getNotificationsDropDown().subscribe(response =>{
      let notificationsCount = response.count;
      this.headerService.SetNotifyCount(notificationsCount);

    })
  }


}
