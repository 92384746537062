import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { CourseService } from '../../services/course.service';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-course-work-test-review',
  templateUrl: './course-work-test-review.component.html',
  styleUrls: ['./course-work-test-review.component.scss']
})
export class CourseWorkTestReviewComponent implements OnInit {

  @Input() reportIndex:any;
  @Input() showCourseWork: any;
  @Input() courseworks: any;
  @Input() courseId: any;
  @Input() teachingAssistant: any;
  @Input() submissionData: any;
  @Input() course_active:any;
  @Output() sendReviewSavedStatus = new EventEmitter();

  processLoader: boolean = false;
  role: string = '';
  loggedInUserId: string = '';

  viewSubmissionData: any[] = [];
  reviewForm!: FormGroup;
  showReport: boolean = false;
  showReviewForm: boolean = false;
  submissionReportDetail: any = {};
  selfReviewReport: any = '';
  peerReviewReport: any = '';
  remarkReport: any = '';
  gradeReport: any = '';
  showSelfReviewEditBtn: boolean = false;
  submissionPublishStatus: boolean = false;
  editReviewType: string = '';

  //PEER REVIEW
  peerSubmissionReportData: any[] = [];
  isPeerAssigned: boolean = false;
  findedIndex: number = 0;
  findedPeerData: any[] = [];

  isSubmissionDataLoading: boolean = false;

  constructor(private fb: FormBuilder, private notifyService: NotificationService, private courseService: CourseService, private renderer: Renderer2) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
  	this.createReviewForm();
  }

  createReviewForm() {
    this.reviewForm = this.fb.group({
      review: ['', [Validators.required, Validators.maxLength(2000)]],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes.submissionData.firstChange) {
      this.viewSubmissionData = changes.submissionData.currentValue.viewSubmissionData;
      if(this.viewSubmissionData.length > 0) {
        this.manageSubmissionReportData();
        //console.log(this.submissionReportDetail);
        //console.log(this.peerSubmissionReportData);
        if(Object.keys(this.submissionReportDetail).length > 0) {
          this.manageSubmissionReport();
        }
      }
      this.isSubmissionDataLoading = false;
    }
  }

  manageSubmissionReportData() {
    this.submissionPublishStatus = this.submissionData.submissionPublish;

    if(this.courseworks.is_group == false) {
      if(this.courseworks.self_review == true && this.courseworks.peer_review == true) {
        this.findedIndex = this.viewSubmissionData.findIndex(item => {
          return item.self_review_status == true;
        });
        if(this.findedIndex == -1) {
          //COURSE WORK EXPIRED
          return;
        }
        this.submissionReportDetail = this.viewSubmissionData[this.findedIndex].submissions[this.showCourseWork.submission_report];
        this.findedPeerData = this.viewSubmissionData.filter(item => {
          return item.peer_review_status == true;
        });        
        if(this.findedPeerData.length > 0) {          
          this.peerSubmissionReportData = this.findedPeerData;          
          this.isPeerAssigned = true;
        }
      }
      else if(this.courseworks.self_review == true) {
        this.findedIndex = this.viewSubmissionData.findIndex(item => {
          return item.self_review_status == true;
        });
        if(this.findedIndex == -1) {
          //COURSE WORK EXPIRED
          return;
        }
        this.submissionReportDetail = this.viewSubmissionData[this.findedIndex].submissions[this.showCourseWork.submission_report];
      }
      else if(this.courseworks.peer_review == true) {
        this.findedPeerData = this.viewSubmissionData.filter(item => {
          return item.peer_review_status == true;
        });
        if(this.findedPeerData.length > 0) {          
          this.peerSubmissionReportData = this.findedPeerData;
          this.isPeerAssigned = true;
        }
      }
      else {
        this.submissionReportDetail = this.viewSubmissionData[this.findedIndex].submissions[this.showCourseWork.submission_report];
      }
    }
    else {
      this.submissionReportDetail = this.viewSubmissionData[this.findedIndex].submissions[this.showCourseWork.submission_report];
    }
  }

  //SHOW HID REPORT DISAPLY AND REVIEW FORM
  manageSubmissionReport() {
    this.onDiscardReview();
    
    if(this.courseworks.is_group == false) {
      if(this.submissionReportDetail.self_review.content) {
        this.selfReviewReport = this.submissionReportDetail.self_review.content;
        this.checkReviewEditAllowed('self_review');
      }
      
      if(this.submissionReportDetail.peer_review.content) {
        this.peerReviewReport = this.submissionReportDetail.peer_review.content;
      }
    }

    if(this.submissionReportDetail.remark.content) {
      this.remarkReport = this.submissionReportDetail.remark.content;
    }

    if(this.submissionReportDetail.grade.content) {
      this.gradeReport = this.submissionReportDetail.grade.content;
    }
    
    if(this.submissionPublishStatus == true) {
      this.showReport = true;
    }
    else {
      if(this.courseworks.is_group == true || (this.courseworks.self_review == false && this.courseworks.peer_review == false)) {
        this.showReport = false;
      }
      else {
        if(this.selfReviewReport || this.peerReviewReport) {
          this.showReport = true;
        }
        else {
          this.showReport = false;
        }
      }
    }

    if((this.courseworks.is_group == false) && (this.submissionPublishStatus == false) && (this.submissionReportDetail.files.length > 0)) {
      if(this.courseworks.self_review == true && !this.selfReviewReport) {
        this.showReviewForm = true;
      }
    }
  }

  checkReviewEditAllowed(reviewType) {
    this.showSelfReviewEditBtn = false;
    
    if(reviewType == 'self_review') {
      if(this.submissionPublishStatus == false && this.role == 'student') {
        this.showSelfReviewEditBtn = true;
      }
    }
  }

  onSaveReview() {
  	//console.log(this.reviewForm);
  	if(!this.validateForm()) {
  		return;
  	}
    const review = this.reviewForm.value.review;
    this.saveReview(review, 'self_review');
  }

  onEditReview(editReviewType) {
    this.editReviewType = editReviewType;
    if(this.editReviewType == 'self_review') {
      this.showReviewForm = true;      
      this.reviewForm.get('review').setValue(this.selfReviewReport);
      setTimeout(() => {this.renderer.selectRootElement('#selfReviewEditInputBox').focus();}, 100);
    }
  }

  saveReview(review, reviewType) {
    const submissionIndex = this.findedIndex;
    const submissionInfo = this.viewSubmissionData[submissionIndex];
    const submissionReportUserId = this.viewSubmissionData[submissionIndex].user_id;
    const submissionReportName = this.showCourseWork.submission_report;
    let submissionReportData = this.viewSubmissionData[submissionIndex].submissions[submissionReportName];

    if (submissionReportData.files.length == 0) {
      this.notifyService.showNotification('info', 'User has not submitted any file for this submission.');
      return;
    }

    if (this.submissionPublishStatus == true && this.role == 'student') {
      this.notifyService.showNotification('info', 'Your Submission is published. No permission to edit it now.');
      return;
    }

    if (reviewType == 'self_review') {
      submissionReportData.self_review = { content: review }
    }

    let grade = '0';
    let feedback = '';
    let peerReview = '';
    let selfReview = '';

    let teamName = '';
    if (this.courseworks.is_group) {
      teamName = submissionInfo.name;
    }

    if (reviewType == 'self_review') {
      selfReview = submissionReportData.self_review.content ? submissionReportData.self_review.content : '';
    }

    let body = {
      user_id: submissionReportUserId,
      team_name: teamName,
      course_work_id: this.courseworks._id,
      schedule_id: this.courseworks.schedule_id,
      course_id: this.courseId,
      grade: grade,
      feedback: feedback,
      submission_name: submissionReportName,
      submission_index: '' + submissionReportData.index,
      peer_review: peerReview,
      self_review: selfReview
    }

    this.processLoader = true;
    this.courseService.add_remark_grade_course_work(body).subscribe(view_remark => {
      this.isSubmissionDataLoading = true;
      this.processLoader = false;
      this.onDiscardReview();
      this.sendReviewSavedStatus.emit({status: true});
      this.notifyService.showNotification('success', "Review saved successfully.");
    },
    (err) => {
      this.processLoader = false;
      this.notifyService.showNotification('error', err.message);
    });
  }

  validateForm() {
    this.reviewForm.get('review').setValue(this.reviewForm.get('review').value.trim());
  	if(this.reviewForm.controls['review'].invalid) {
  	  if(this.reviewForm.get('review').hasError('required')) {
  	    this.notifyService.showNotification("error", "Review can not be empty.");
  	    return false;
  	  }
  	  if(this.reviewForm.get('review').hasError('maxlength')) {
  	    this.notifyService.showNotification("error", "Review may not be greater than 2000 characters.");
  	    return false;
  	  }
  	  return false;
  	}

    return true;
  }

  getPeerReviewSavedStatus(data) {
    this.sendReviewSavedStatus.emit({status: true});
  }

  //FOR RESET REVIEW BOX
  onDiscardReview() {
    this.reviewForm.reset();
    this.editReviewType = '';
    this.showReviewForm = false;
    this.reviewForm.get('review').setValue('');
  }

  //FOR BUILD FIX
  findedPeerIndex: any = '';

}
