<div class="new_UIstyle">
  <div class="pageBodyWrap">
    <!-- Banner Section Start -->
    <section class="banner banner-passion-project"
      style="background-image: url('assets/images/banner/mosaic-bg01.png');">
      <div class="container">
        <div class="banner-content text-center">
          <h1 class="white-text">The Mosaic</h1>
          <p class="white-text lead">Collection of all the projects done.</p>
          <form class="assessment-banner-form">
            <div class="input-with-icon input-with-icon-right">
              <input class="form-control form-control-lg" type="text" name="search" autocomplete="off" appSearchField
                (sendSearchData)="onStartSearch($event)" placeholder="Search for Project..." />
              <button class="input-with-icon-btn">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </form>
          <button *ngIf="hasPermission('add_project')" class="btn btn-primary mt-2 mt-md-4"
            (click)="redirect('/passion-projects/add', '')">+ Create New Project</button>
        </div>
      </div>
    </section>
    <!-- Banner Section End -->

    <!-- Discover Course Section Start -->
    <app-discover-projects *ngIf="role == 'student' && !isSearchProject"></app-discover-projects>
    <!-- Discover Course Section End -->

    <!-- Our Projects Section Start -->
    <section class="section our-passion-projects">
      <div class="container">

        <div class="search-result-bag mb-3 mb-md-4 mb-xl-5" *ngIf="isSearchProject && !loader">
          <h6 class="avenirBlack"><span class="primary-text">{{totalDatalength}}</span> results for “{{searchText}}”
          </h6>
        </div>

        <!-- Title Block Start -->
        <div class="title passion-title text-center" *ngIf="!isSearchProject">
          <h3>Our Project List</h3>
        </div>
        <!-- Title Block End -->
        <div [ngClass]="{submitting : loader}">
          <!-- Filter Block Start -->
          <div class="filter-elem-block mb-4" *ngIf="!isSearchProject">
            <div class="filter-elem-item filter-elem-left">
              <div class="passion-calegory-wrap" *ngIf="!isSearchProject">
                <div class="filterBarlist" *ngIf="role === 'super_admin'">
                  <button type="button"  [ngClass]="{'active':listType === 'published'}" (click)="onViewAllProject_admin('published')">Published Projects</button>
                  <button type="button" [ngClass]="{'active':listType === 'unpublished'}" (click)="onViewAllProject_admin('unpublished')">Archived Projects</button>
                </div>


                <div class="filterBarlist" *ngIf="role === 'teacher' || role === 'student' || role === 'mentor'">
                  <!-- <button type="button" [ngClass]="{'active':activateTab === 'all'}" (click)="onViewAllProject('all')">All
                    Project</button> -->
                  <button type="button" [ngClass]="{'active':activateTab === 'my_organisation' &&  !archivedTab}" (click)="onViewAllProject('my_organisation')">My Organisation projects</button>
                  <button type="button" [ngClass]="{'active':activateTab === 'other'}" (click)="onViewAllProject('other')">Other Organisation projects</button>
                  <button type="button" [ngClass]="{'active':activateTab === 'my_organisation' && archivedTab === true}" (click)="onViewAllProject('archived')">Archived Projects</button>
                </div>

              </div>
            </div>
            <div class="filter-elem-item filter-elem-right">
              <form>
                <div class="sort-elenm">
                  <label><small>Sort by:</small></label>
                  <select class="form-control form-select form-control-caret" (change)="onSortData($event)">
                    <option *ngFor="let item of sortingList" value="{{item.value}}">{{item.name}}</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          <!-- Filter Block End -->

          <!-- Passion Course Block Start -->
          <div class="passion-passions-wrap">
            <!-- loader -->
            <div class="loadingData">
              <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <!-- loader -->
            <div class="row gy-4">
              <div class="col-lg-4 col-md-6 col-sm-6 mb-4" *ngFor="let project of filterProjects; let i=index">

                <div class="course-item-block shineEffect passion-course-item-block">
                  <figure class="course-item-fig"
                    style="background-image: url('assets/images/course/placeholder.png');">
                    <img *ngIf="project.project_overview_image" [src]="project.project_overview_image" [alt]="project.project_title" />
                    <img *ngIf="!project.project_overview_image && project.project_image" [src]="project.project_image" [alt]="project.project_title" />
                    <img *ngIf="!project.project_overview_image && !project.project_image" [src]="defaultThumbnailPic" [alt]="project.project_title" />
                  </figure>

                  <div class="course-item-content">
                    <p class="course-title"> <a (click)="changeSharedProject(project._id, project.publish)"
                        title="{{ project.project_title | titlecase }}"><strong>{{ project.project_title |
                          titlecase}}</strong></a></p>
                    <p class=""><small>By {{project.created_by.name + ' ' + project.created_by.last_name}}</small></p>

                    <div class="btn-groups">
                      <button  (click)="changeSharedProject(project._id, project.publish)"
                        class="icon-btn btn-primary" ngbTooltip="View Project" container="body" type="button">
                        <i class="far fa-eye"></i>
                      </button>

                      <button class="icon-btn btn-primary" ngbTooltip="Edit" container="body" type="button" (click)="goToEdit(project._id)" *ngIf="role != 'student' && project.edit_rights == true && project.publish === true"><i class="fas fa-pencil-alt"></i>
                      </button>

                      <!-- <button class="icon-btn btn-primary" ngbTooltip="Edit" container="body" type="button" (click)="goToEdit(project._id)" *ngIf="role == 'teacher' && project.edit_rights">
                        <i class="fas fa-pencil-alt"></i>
                      </button> -->

                      <!-- <button class="icon-btn btn-primary" ngbTooltip="Delete" container="body" type="button"
                        (click)="openModelDeletePro(delPro)" *ngIf="(userRole.userRole === 'super_admin')"><i
                          class="fas fa-trash"></i>
                      </button> -->

                   
                      <div class="row" *ngIf="(userRole.userRole === 'super_admin') && project.publish === true">
                        <div class="col-md-12 mt-2 radio_but">
                                <form>
                                    <div  class="form-check form-switch form-switch-right">
                                        <label  class="form-check-label " for="flexSwitchCheckChecked">
                                        </label>
                                        <input  [checked]="project.publish" class="form-check-input" (change)="openModelDeletePro(delPro)"
                                         id="flexSwitchCheckChecked" type="checkbox" [disabled]="project.publish === false" container="body" ngbTooltip="deactivate">
                                    </div>
                                </form>
                                </div>

                      </div>          

                      <ng-template #delPro let-c="close" let-d="dismiss">
                        <div class="new_UIstyle">
                            <div class="modal-header justify-content-between align-items-center">
                                <h5 class="avenirBook modal-title text-danger mb-0">Delete Project</h5>
                                <button type="button" class="close ml-0" aria-label="Close" (click)="closeProPopup()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to deactivate Project <strong class="text-danger">?</strong></p>
                            </div>
                    
                            <div class="modal-footer">
                                <div class="text-left">
                                    <button type="submit" class="btn btn-primary btn-sm" (click)="closeProPopup()">NO</button>
                                </div>
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary btn-sm" (click)="deleteProject(project._id)">YES</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="alert alert-info text-center" *ngIf="!loader && !filterProjects?.length">
              <p>No Project Found</p>
            </div>

            <!-- PAGING START -->
            <div *ngIf="!isSearchProject && filterProjects?.length > 0" class="container text-center pagena justify-content-center">
              <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalDatalength" [(page)]="page"
                [maxSize]="maxPageNoDisplay" aria-label="Default pagination" class="pagena pagination-wrap"
                (pageChange)="onPageChanged($event)" [boundaryLinks]="false">
                <ng-template ngbPaginationPrevious><</ng-template>
                <ng-template ngbPaginationNext>></ng-template>
              </ngb-pagination>
            </div>
            <!-- PAGING END -->

          </div>
          <!-- Passion Course Block End -->
        </div>
      </div>
    </section>
    <!-- Our Projects Section End -->
  </div>

</div>
