<div class="assessment-content-list"  *ngIf="unapproved_assessment_data.length > 0">
                        
    <!-- *ngFor="let assessment of assessments;let i=index;" -->
    <div class="questionBankItem assessment-content-item bg-grey relative">
        <p class="nagtiveStatus">
            <span class="badge  badge-primary avenirRoman" *ngIf="unapproved_assessment_data[0].negative_marking_status === true">Negative Marking</span> 
              <span class="badge  badge-green-lt avenirRoman" *ngIf="unapproved_assessment_data[0]?.ai_generated === true">AI-generated</span>
          </p>
        <div class="questionItemLeft pt-md-3">
            <h6 class="avenirBook mb-3"><a href="javascript:void(0);"  (click)="gotoViewAssessment(unapproved_assessment_data[0])">{{unapproved_assessment_data[0].title |
                    capitalization}}</a></h6>

            <div class="assessment-content-options-list">
                <ul class="assessment-content-options-ul options-ul-style-label ul-list-100">
                    <li>
                        <span class="options-ul-label w-auto mr-2">Status:</span> 
                        <div class="options-ul-content">
                            <strong class="primary-text">{{unapproved_assessment_data[0].approved ? 'Published':'Unpublished'}}</strong>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <div class="questionItemRight icons-group">
            <a *ngIf="course_active" class="questionItemLink ItemEditLink" (click)="editassessment(unapproved_assessment_data[0])"><i
                    class="fas fa-pencil-alt"></i></a>
                    <!-- (click)="deleteAssMent(assessment._id)" -->
            <a *ngIf="course_active" class="questionItemLink ItemDeleteLink" (click)="openModelDeleteAss(delAss, unapproved_assessment_data[0]._id)"><i class="fas fa-trash-alt"></i></a>

            <ng-template #delAss let-c="close" let-d="dismiss">
                <div class="new_UIstyle">
                    <div class="modal-header justify-content-between align-items-center">
                        <h5 class="avenirBook modal-title text-danger mb-0">Delete Assessment</h5>
                        <button type="button" class="close ml-0" aria-label="Close" (click)="closeAssPopup()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to delete Assessment <strong class="text-danger">?</strong></p>
                    </div>
            
                    <div class="modal-footer">
                        <div class="text-left">
                            <button type="submit" class="btn btn-primary btn-sm" (click)="closeAssPopup()">NO</button>
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-primary btn-sm" (click)="delete_Assessment_event(unapproved_assessment_data[0]._id)">YES</button>
                        </div>
                    </div>
                </div>
            </ng-template>

        </div>
        <a  class="questionItemLink ItemAnchorLink" (click)="gotoViewAssessment(unapproved_assessment_data[0])"><i
                class="fa fa-angle-right"></i></a>
    </div>
</div>