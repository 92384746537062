
<div class="new_UIstyle">
    <!-- Banner Section start -->
<div class="banner banner-gradient banner-course-assement">
    <div class="container">
         <div class="banner-content">
             <div class="banner-content-content">
                 <h2 class="white-text avenirBlack mb-1">{{reportName}}</h2>
             </div>

             <div class="banner-content-action btn-groups">
                 <button class="btn btn-primary" routerLink="/add_organisation"><i class="fas fa-plus-circle"></i></button>
             </div>
         </div>
    </div>
 </div>
<!-- Banner Section End -->


<div class="pageBody qBank section org_serach_wrap">
    <div class="container">
        <div [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>

            <div class="clearfix filter mb-4 form">
                <div class="row align-items-center">
                    <div class="col-12 py-2 py-lg-0 col-lg-12">
                        <div class="search-hero">
                            <i class="fas fa-search"></i>
                            <input class="form-control form-control-style-2 form-control-lg" type="text" (input)="searchCourseWork($event)" id="search"
                                placeholder="Search Organisation..." autofocus />
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive maxHe8">
                <table class="table alt">
                    <thead>
                        <tr>
                            <th class=" text-left">S.No.</th>
                            <th class="text-left">Organisation</th>
                            <th class="text-left">Organisation Type</th>
                            <th class="w100p text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let organization of organizations; let i = index;">
                            <td class="text-left">{{i+1}}</td>
                            <td class="text-left">{{organization.name}}</td>
                            <td class="text-left">{{organization.organisation_type.name}}</td>
                            <td class="text-center">
                                <button class="btn edit btn-link btn-transparent noHover" ngbTooltip="Edit" type="button"><i
                                        class="fas fa-pencil-alt" (click)="editOrganisation(organization)"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>