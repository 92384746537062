<button class="btn btn-primary chat-btn btn-icon-text float-right"
    *ngIf="['student', 'teacher', 'mentor'].includes(role) && btnRef != 'Edit'" (click)="openGroupCreateModel(createGroup, 'Create')">
    + Create Team
</button>
<button *ngIf="btnRef == 'Edit'" class="btn btn-xs btn-primary ms-auto d-table mt-1" (click)="openGroupCreateModel(createGroup, 'Edit', teamData)"><i class="fas fa-pencil-alt"></i> Edit Team
</button>

<!-- MODAL CREATE TEAMS |START-->
<ng-template #createGroup let-modal>
    <div class="new_UIstyle" [ngClass]="{submitting : groupProcessing}">
        <div class="create_mod">
            <div class="modal-header">
                <h5 class="avenirBlack blue-lt-text d-flex d-lg-inline-block mb-0">
                    {{groupActionName}} Group</h5>
                <button type="button" class="close close_pop" aria-label="Close" (click)="closeGroupCreateModel()">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body modalGroupBody crea_mod">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="groupProcessing">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="guftagu-addUserGroup-body-wrap pt-0">
                    <div class="row g-0 gy-3 gy-md-0">
                        <div class="col-lg-7 mt-3  mt-lg-0 order-2 order-lg-1 pt-3">
                            <div class="guftagu-addUser-search mb-4">
                                <form class="input-with-icon input-with-icon-left search-form">
                                    <input type="search" name="search" placeholder="Search user"
                                        class="form-control form-control-style-2 form-control-lg create-group-control" autocomplete="off" appSearchField
                                        (sendSearchData)="onStartSearch($event)"/>
                                    <button class="input-with-icon-btn search-btn"><i
                                            class="fas fa-search"></i></button>
                                </form>
                            </div>

                            <div class="guftagu-addUserList mt-2">
                                <div class="guftagu-item-list guftagu-item-list-addChatUser status-dot-none"
                                    *ngFor="let subscriberStudent of subscribeStudentList">
                                    <div class="guftagu-item-link">
                                        <div class="row g-0 gy-3 gy-md-0 align-items-center">
                                            <div class="col-12 col-md-8 guftagu-item-link-left">
                                                <default-name-icon *ngIf="!subscriberStudent.profile_pic" [name]="subscriberStudent.name" [figureClass]="''"></default-name-icon>
                                                <figure *ngIf="subscriberStudent.profile_pic" class="guftagu-item-fig">
                                                    <img [src]="subscriberStudent.profile_pic" alt="dp-2.jpg" /></figure>
                                                <div class="ml-2">
                                                    <p class="guftagu-item-desc-title avenirBlack mb-0">
                                                        {{subscriberStudent.name}}</p>
                                                    <p class="guftagu-item-desc-title mb-0">
                                                        {{subscriberStudent.username}}</p>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-4 guftagu-item-link-right text-end">
                                                <button class="btn btn-sm btn-blue"
                                                    (click)="addMemberToGroup(subscriberStudent)" name="grouplist"
                                                    [disabled]="subscriberStudent?.isAdded || subscriberStudent.team_status == true">{{subscriberStudent?.isAdded
                                                    ? 'Added to group' : 'Add to group'}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-info text-center p-2"
                                    *ngIf="!groupProcessing && subscribeStudentList.length == 0 && searchText.length > 0">
                                    <p>User Not Found.</p>
                                </div>
                                <div class="alert alert-info text-center p-2"
                                    *ngIf="!groupProcessing && subscribeStudentList.length == 0 && searchText.length == 0">
                                    <p>Search users to add into group</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 ps-0 ps-lg-3 order-1 order-lg-2">
                            <form [formGroup]="addTeamByStudnet">
                                <div class="guftaguCreateGroup">
                                    <div class="guftaguUploadProfile">
                                        <label class="UploadHoverable" for="fileInput">
                                            <img *ngIf="!groupProcessing" class="UploadHoverableImg"
                                                [src]="uploadedImg ? uploadedImg : userDefaultPic" alt="dp-2.jpg">
                                            <div class="UploadHoverableBackground"></div>
                                            <span class="camra-icon"><i class="fas fa-camera"></i></span>
                                        </label>
                                        <input id="fileInput" type='file' (change)="onSelectFile($event)"
                                            (click)="onFileInputClick($event)" accept="image/*">
                                    </div>
                                    <div class="mt-2 ceateGroupName">
                                        <p class="mb-0">Group Name</p>
                                        <input type='text' maxlength="50"
                                            class="form-control form-control-style-2 create-group-control"
                                            placeholder="Enter group name" formControlName="name">
                                    </div>

                                    <div class="mt-2 memberListWrap">
                                        <p class="mb-0">Members Added</p>
                                        <hr class="mt-0" />
                                        <div class="mt-2 addGroupMenmerList">
                                            <div class="guftagu-item-list guftagu-item-list-addChatUser status-dot-none"
                                                *ngFor="let member of addedMembers;">
                                                <div class="guftagu-item-link">
                                                    <div class="row g-0 align-items-center">
                                                        <div class="col-9 guftagu-item-link-left">
                                                            <default-name-icon *ngIf="!member.profile_pic" [name]="member.name" [figureClass]="''"></default-name-icon>
                                                            <figure *ngIf="member.profile_pic" class="guftagu-item-fig">
                                                                <img src="{{member.profile_pic}}" alt="dp-2.jpg" />
                                                            </figure>
                                                            <div class="guftagu-item-desc">
                                                                <p class="guftagu-item-desc-title avenirBlack mb-0">
                                                                    {{member.name}} {{['teacher', 'mentor'].includes(member.role)? "("+role+")":''}}
                                                                </p>
                                                                <p class="guftagu-item-desc-title mb-0">
                                                                    {{member.username}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-3 guftagu-item-link-right text-right">
                                                            <button *ngIf="groupActionName == 'Create' && (member._id != loggedInUserId)"
                                                                class="btn-transparent primary-text"
                                                                (click)="removeMemberFromGroup(member)"><i
                                                                    class="fa fa-times"></i>
                                                            </button>
                                                            <button *ngIf="groupActionName == 'Edit' && (member._id != leadMember._id)"
                                                                class="btn-transparent primary-text"
                                                                (click)="removeMemberFromGroup(member)"><i
                                                                    class="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>

                                <div class="col-12 order-3">
                                    <div class="btn-groups justify-content-end">
                                        <button type="button" class="btn btn-sm btn-primary-border" aria-label="Close"
                                            (click)="discardChanges()">Discard
                                        </button>
                                        <button type="button" (click)="saveTeams()"
                                            class="btn btn-sm btn-primary">
                                            {{groupActionName == 'Edit' ? 'Update' : 'Create'}} Group
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- MODAL CREATE TEAMS |END-->