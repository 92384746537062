import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { finalize, retry } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-zoom-integation',
  templateUrl: './zoom-integation.component.html',
  styleUrls: ['./zoom-integation.component.scss']
})
export class ZoomIntegationComponent implements OnInit {
  @ViewChild('apps', {static:false}) accordionApps: NgbAccordion | undefined;

  public dateAddedApp = new Date();
  zoom_integration: FormGroup;
  zoom_array: FormArray;
  loader = false;
  get_zoom_account: any[];
  zoom_account: any[];
  zoom_form_submit = false;
  activeId: string ='panel-0';
  isDafaultIndex:any = 0;
  isDefaultVal:boolean = false;
  isCheckboxClicked:boolean = false;
  constructor(private user_service: UserService, private fb: FormBuilder, private ns: NotificationService) { }

  ngOnInit() {
    this.ValidateForm();
    this.get_credentials();
  }
  ValidateForm(): void {
    this.zoom_integration = this.fb.group({
      zoom_array: this.fb.array([this.addZoomForm()])
    })
  }

  addZoomForm(): FormGroup {
    this.zoom_form_submit = false;
    return this.fb.group({
      api_key: ['', Validators.required],
      client_id: ['', Validators.required],
      secret_key: ['', Validators.required],
      app_email: ['', Validators.required],
      co_authors: [false, Validators.required],
      public_to_org: [false, Validators.required],
      collaborators: [false, Validators.required],
      type: [''],
      is_default: [false]
    })
  }

  add_zoom_form() {
    if (this.accordionApps) {
      let activePanelId = this.accordionApps.activeIds;
      activePanelId = 'panel-' + this.zoom_integration.controls.zoom_array['controls'].length
      this.activeId = activePanelId;
    }
    this.zoom_form_submit = false;
    let zoom_form = this.zoom_integration.get('zoom_array') as FormArray;
    zoom_form.push(this.addZoomForm());

    if(this.zoom_integration.controls.zoom_array['controls'].length === 1){
      this.zoom_integration.controls.zoom_array['controls'][0].controls.is_default.setValue(true);
    }
  }


  
  add_credentials(index) {
    this.zoom_form_submit = true;
    if (this.zoom_integration.controls.zoom_array['controls'][index].invalid) {
      this.ns.showNotification('info', 'Please enter correct zoom details');
      return;
    }
    this.loader = true;
    this.user_service.zoom_integration('zoom_s2s', this.zoom_integration.controls.zoom_array['controls'][index].value)
    .pipe(finalize(() => {
      this.loader = false;
      this.zoom_form_submit = true;
    })).subscribe(zoom_api => {
      this.ns.showNotification('success', zoom_api.message);
      this.get_credentials();
    },
      (err) => {
        this.ns.showNotification('error', err.message);
        this.loader = false;
      });
  }

  get_credentials() {
    this.loader = true;
    this.user_service.get_zoom_account().subscribe(zoom_account => {      
      this.get_zoom_account = zoom_account.response
      this.zoom_account = zoom_account.response.apps.zoom_s2s
      this.edit_zoom(this.zoom_account);
      this.loader = false
    },
      (err) => {
        this.ns.showNotification('error', err.message);
        this.loader = false;
      });
  }

  edit_zoom_credentials(index) {
    if (this.zoom_integration.controls.zoom_array['controls'][index].invalid) {
      this.ns.showNotification('info', 'Please enter correct zoom details');
      return;
    }
    this.loader = true;
    this.user_service.edit_zoom_credentials('zoom_s2s', this.zoom_integration.controls.zoom_array['controls'][index].value).pipe(finalize(() => this.loader = false)).subscribe(zoom_account => {
      this.ns.showNotification('success', zoom_account.message);
      this.get_credentials();
    },
      (err) => {
        this.ns.showNotification('error', err.message);
        this.loader = false;
      });
  }


  isDefaultChecked(event, index){
    this.isDafaultIndex = index;
    this.isDefaultVal = event.target.checked;
    this.isCheckboxClicked = true;
  }

  submit_zoom(index) {
    this.zoom_integration.controls.zoom_array['controls'].forEach((control, index) => {
      control.controls.is_default.setValue(false);
      if (control.invalid) {
        this.ns.showNotification('info', `Zoom details account ${index + 1} is invalid`);
        return;
      }
    });

    if(!this.isCheckboxClicked && this.isDafaultIndex === 0){
      this.isDefaultVal = true;
    }
    this.zoom_integration.controls.zoom_array['controls'][this.isDafaultIndex].controls.is_default.setValue(this.isDefaultVal);
  
    if(this.zoom_integration.invalid){
      return;
    }

    let value = this.zoom_integration.controls.zoom_array['controls'][index].value;

    if (value.type == '') {
      this.add_credentials(index);
    } else {
      this.edit_zoom_credentials(index);
    }
  }

   // Get the checkboxes FormArray
   get checkboxControls() {
    return (this.zoom_integration.get('zoom_array') as FormArray).controls;
  }

  edit_zoom(zoom_account) {
    
    if(zoom_account.length === 0){
      this.zoom_integration.controls.zoom_array['controls'][0].controls.is_default.setValue(true);
    }
    for (let zoom = 0; zoom < zoom_account.length; zoom++) {
      // this.zoom_integration.controls.zoom_array['controls'][zoom].controls.api_key.setValue(zoom_account[zoom].api_key);
      // this.zoom_integration.controls.zoom_array['controls'][zoom].controls.secret_key.setValue(zoom_account[zoom].secret_key);
      if(this.zoom_integration){
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.api_key.setValue(zoom_account[zoom].s2s_account_id);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.client_id.setValue(zoom_account[zoom].s2s_client_id);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.secret_key.setValue(zoom_account[zoom].s2s_client_secret_key);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.app_email.setValue(zoom_account[zoom].email);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.is_default.setValue(zoom_account[zoom].is_default);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.co_authors.setValue(zoom_account[zoom].permissions.co_authors);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.public_to_org.setValue(zoom_account[zoom].permissions.public_to_org);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.collaborators.setValue(zoom_account[zoom].permissions.collaborators);
        this.zoom_integration.controls.zoom_array['controls'][zoom].controls.type.setValue('edited');
        // this.add_zoom_form();
        if (zoom + 1 == zoom_account.length) {
          this.removeZoomForm('zoom_array', zoom + 1);
        }
      }
    }
  }

  removeZoomForm(controlName: string, index: number, type?: string) {
    let control;
    if (type == 'delete') {
      if (confirm('Are you sure to wants to delete')) {
        let zoom_form = this.zoom_integration.controls.zoom_array['controls'][index].controls;
        if (zoom_form.api_key.value != '') {
          this.loader = true;
          this.user_service.delete_zoom_account(this.zoom_integration.controls.zoom_array['controls'][index].value).pipe(finalize(() => this.loader = false)).subscribe(zoom_delete => {
            control = <FormArray>this.zoom_integration.controls[controlName];
            control.removeAt(index);
            this.ns.showNotification('success', zoom_delete.message);
          },
            (err) => {
              this.ns.showNotification('error', err.message);
              return;
            });
        } else{
          control = <FormArray>this.zoom_integration.controls[controlName];
          control.removeAt(index);
        }

      } else {
        return;
      }
    }
   
  }
}
