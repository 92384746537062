<div class="learning-course-item" *ngFor="let assessmentinfo of assessment_data; let assessment_index = index">

  <div class="learning-course-link bg-grey relative">
    <p class="nagtiveStatus">
      <span class="badge  badge-primary avenirRoman" *ngIf="assessmentinfo.negative_marking_status === true">Negative Marking</span> 
        <span class="badge  badge-green-lt avenirRoman" *ngIf="assessmentinfo?.ai_generated === true">AI-generated</span>
    </p>
    <!-- <div class="badgeTag nagtiveStatus"><span class="badge  badge-primary avenirRoman m-0 ">AI-generated</span> -->
    <!-- </div> -->
    <div class="learning-course-elem-left pt-md-3">

      <p class="learning-course-link-title h6 avenirBlack">
        <strong>{{assessmentinfo.title}}</strong>
      </p>
      <div class="mt-3 assessment-time-slot">
        <p *ngIf="assessmentinfo.start_time"><small class="d-inline-block">Start Time:</small>
          <small class="d-inline-block primary-text"><b>{{assessmentinfo.start_time}}</b></small>
        </p>
        <p *ngIf="assessmentinfo.start_time"><small class="d-inline-block">End Time:</small>
          <small class="d-inline-block primary-text"><b>{{assessmentinfo.end_time}}</b></small>
        </p>
      </div>
    </div>

    <div class="learning-course-elem-right" *ngIf="role=='student'  && isTA == 'false'">
      <p><a class="btn btn-primary btn-w-150 btn-sm"
          (click)="openAssessmentForSession('submitAssessment',assessmentinfo,assessmentinfo.time, assessment_index)"
          *ngIf="(session_status == 'active' || session_status == '' || session_status == 'recording_completed') && !is_answer_assessment(assessmentinfo) && sessions?.course_active">Answer</a>
      </p>
      <p><a class="btn btn-primary-border btn-w-150 btn-sm" *ngIf="assessmentinfo.user_submission"
          (click)="viewUserSubmission(assessmentinfo, topicId, session_id )">Feedback</a>
      </p>
      <!-- <p><a class="btn btn-primary-border btn-w-150 btn-sm" *ngIf="assessmentinfo.user_submission"
                                                                        (click)="assessmentFeedbackPopup(assessmentFeedback,assessmentinfo, '', topicId,session_id)">Feedback</a>
                                                                </p> -->
    </div>


    <!-- *ngIf="showCourseData.course_info.publish_right" -->
     <ng-container *ngIf="objSession > 0">
       <div class="learning-course-elem-right" *ngIf="sessions?.publish_right || !sessions?.publish_right && isTA == 'true'">
   
         <p [title]="assessmentinfo.submission_exists ? '': 'No Submission Yet'">
           <a class="btn btn-primary btn-w-150 btn-sm" [title]="assessmentinfo.title"
             [ngClass]="assessmentinfo.submission_exists ? '' : 'disabled'"
             (click)="viewSubmission('view_submission_assessment',assessmentinfo,topicId)">
             <!--         
                                                                           <i class="far fa-file-alt mr-2"></i>  -->
             {{sessions?.course_active?"Mark":"View"}} Assessment
           </a>
         </p>
   
         <p *ngIf="assessmentinfo?.is_graph" [title]="assessmentinfo?.submission_exists ? '': 'No Submission Yet'">
           <a (click)="getGraphAssessment(assessmentinfo)" [ngClass]="assessmentinfo?.submission_exists ? '' : 'disabled'"
             class="btn btn-primary btn-w-150 btn-sm">
             <!-- <i class="fas fa-chart-pie mr-2"></i> -->
             View Graph
           </a></p>
   
       </div>
     </ng-container>
  </div>
</div>
