import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowCourseView } from 'src/app/model/course.model';
import { CourseAssessmentShareService } from 'src/app/services/course-assessment-share.service';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-mark-assessment',
  templateUrl: './mark-assessment.component.html',
  styleUrls: ['./mark-assessment.component.scss']
})
export class MarkAssessmentComponent implements OnInit {
  assessment_view;
  topicId: any;
  loader: boolean = false;
  courseId = null;
  assessmentId: any;
  noSubmissions: string = '';
  assessment_name: string = '';
  submission_length;
  schedule_id: any;
  start_time: any
  courseName = null;
  course_Id = null;
  view_assessment_submission: any[] = [];
  courseAssessmentData: any;
  course_session_id;
  user_id = '';
  userId: string;
  user__Id: any;
  topic_id;
  session_id = '';
  showCourseData: ShowCourseView;
  assessmentD;
  sessionkey: any;
  course_category: any;
  course_category_id: any;
  isTA:any;

  role: string = '';
  draft: '';

  
  currentPage:number = 1;
  isLoadingUser = false;
  totalItemss:number=null;
  maxPage:number = 0;
  activeIdNav:any = 1;
  ngbNavItem:any = 1;
  course_active:any = false

  constructor(private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private _courseAssessmentShareService: CourseAssessmentShareService,
  ) {
    this.userId = localStorage.getItem('user_id');
  }

  ngOnInit() {

    this.role = localStorage.getItem('role');

    this.sessionkey = this._courseAssessmentShareService.getSessionKey();
    this.course_category_id = this._courseAssessmentShareService.gercourse_category_id();
    this.course_category = this._courseAssessmentShareService.getCourse_Category();
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.assessmentId = this.activatedRoute.snapshot.queryParamMap.get('assessment_id');
    this.assessment_name = this.activatedRoute.snapshot.queryParamMap.get('assessment_name');
    this.schedule_id = this.activatedRoute.snapshot.queryParamMap.get('schedule_id');
    this.start_time = this.activatedRoute.snapshot.queryParamMap.get('start_time');
    this.topicId = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.isTA = this.activatedRoute.snapshot.queryParamMap.get('isTA');
    this.activeIdNav = Number(this.activatedRoute.snapshot.queryParamMap.get('tab_no'));
    this.ngbNavItem = this.activatedRoute.snapshot.queryParamMap.get('ngbNavItem');
    if (this.topicId == (undefined || null)) {
      this.topicId = '';
    }
    if (this.session_id == (undefined || null)) {
      this.session_id = '';
    }
    this.viewSubmission();
  }


  viewSubmission() {
    this.view_assessment_submission = [];
    this.isLoadingUser = true;
    let assessment_result_id = this.assessmentId;
    this.courseService.view_assessment_teacher(this.assessmentId, this.courseId, this.schedule_id, this.currentPage).subscribe(view_submission => {
      if(view_submission.hasOwnProperty('response')){
        this.view_assessment_submission = view_submission.response;
      }
      this.course_active = view_submission.course_active
      this.currentPage = view_submission.current_page;
      this.maxPage = view_submission.max_pages;
      let totlaltems = view_submission.max_items;
      this.totalItemss =  totlaltems 
      this.view_assessment_submission.forEach(res => {
        this.user__Id = res._id;
      })
      this.isLoadingUser = false;
      this.submission_length = this.view_assessment_submission.length;
      if (this.view_assessment_submission.length == 0 || this.view_assessment_submission.length == undefined) {
        this.noSubmissions = 'No submission is persent right now';
      }
    })
  }

  // backTOCourseInfo() {
  //   this.route.navigate(['/courses/single-course'], {
  //     queryParams: {
  //       course_id: this.courseId,
  //       course: this.courseName
  //     }
  //   })
  // }

  backTOCourseInfo() {
    let url = '/courses/single-course'
    let body;
    if (this.session_id) {
      body = {
        course_id: this.courseId,
        course: this.courseName,
        session_id: this.session_id,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        sessionkey: this.sessionkey,
        isTA:this.isTA,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem

      }
      url = '/courses/video-with-notes'
    } else {
      body = {
        course_id: this.courseId,
        course: this.courseName,
        isTA:this.isTA,
        tab_no:this.activeIdNav
      }
    }
    this.route.navigate([url], { queryParams: body })
  }

  getAss;
  markButton(ass, studentName) {
    localStorage.setItem('studentSubmissionName',studentName)
    let assessment_result_id = ass.assessment_result_id;
    this.route.navigate(['/courses/user-submissions'], {
      queryParams: {
        course_id: this.courseId,
        course: this.courseName,
        assessment_id: this.assessmentId,
        assessment_name: this.assessment_name,
        schedule_id: this.schedule_id,
        start_time: this.start_time,
        user__id: ass.user_id,
        topicId: this.topicId,
        session_id: this.session_id,
        assessment_result_id: assessment_result_id,
        isTA:this.isTA,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem
      }
    })
  }
  // markButton(ass) {
  //   let assessment_result_id = ass.assessment_result_id;
  //   this.route.navigate(['/courses/feedback-assessement'], {
  //     queryParams: {
  //       course_id: this.courseId,
  //       course: this.courseName,
  //       assessment_id: this.assessmentId,
  //       assessment_name: this.assessment_name,
  //       schedule_id: this.schedule_id,
  //       start_time: this.start_time,
  //       user__id: ass._id,
  //       topicId: this.topicId,
  //       session_id: this.session_id,
  //       assessment_result_id: assessment_result_id,
  //       isTA:this.isTA
  //     }
  //   })
  // }

  currentPageNo(pageNo) {
    this.currentPage = pageNo;
    if(pageNo){
      this.viewSubmission()
    }
  }


}
