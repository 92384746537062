<div class="new_UIstyle">
    <!-- Banner Section start -->
<div class="banner banner-gradient banner-course-assement">
    <div class="container">
         <div class="banner-content">
             <div class="banner-content-content">
                 <h2 class="white-text avenirBlack mb-1">{{save_organization}}</h2>
             </div>

             <div class="banner-content-action btn-groups">
                 <button class="btn btn-primary" routerLink="/search_organisation"><i class="fas fa-arrow-circle-left"></i></button>
             </div>
         </div>
    </div>
 </div>
<!-- Banner Section End -->


<div class="pageBodyd">
    <div class="container">
        <div class="modal-body" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="rounded embed-responsive-16by9">
                <form class="mw620 floatedInputForm typo-16" [ngClass]="{submitting : loader}"
                    [formGroup]="add_organization_form" (ngSubmit)="add_organization()">
                        <div class="mb-3">
                            <label>Organization Type</label>
                            <select class="custom-select form-control form-control-lg form-control-style-2"
                                [ngClass]="{ 'is-invalid': submit_org && organization_form.org_type.errors }"
                                formControlName="org_type">
                                <option selected value="" selected>Select Organization type</option>
                                <option value="School" *ngFor="let org of org_type" [value]="org._id">{{org.name}}
                                </option>
                            </select>
                            <div *ngIf="submit_org && organization_form.org_type.errors" class="invalid-feedback">
                                <div *ngIf="submit_org && organization_form.org_type.errors.required">Organization Type is required
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label>Organisation Name</label>
                            <input type="text" class="form-control form-control-lg form-control-style-2" placeholder=""
                                [ngClass]="{ 'is-invalid':  submit_org && organization_form.organization_name.errors }"
                                formControlName="organization_name">
                            <div *ngIf="submit_org && organization_form.organization_name.errors" class="invalid-feedback">
                                <div *ngIf="submit_org && organization_form.organization_name.errors.required">Organization Name is
                                    required</div>
                            </div>
                        </div>

                    <div class="text-right">
                        <button type="button" class="btn btn-primary mr-2" (click)="reset()">Reset</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</div>