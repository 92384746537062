<!-- new ui code -->
<div class="new_UIstyle" appDisableRightClick>
    <div class="banner banner-gradient banner-course-assement">
        <div class="container">
             <div class="banner-content" *ngIf="assessmentData">
                 <div class="banner-content-content">
                    <p class="position-relative pos-top-10 mb-0" *ngIf="negative_marking_status"><span class="badge badge-light badge-primary avenirRoman m-0 mb-1" >Negative Marking</span></p>
                     <h2 class="white-text avenirBlack prevent-select">{{assNme}}</h2>
                 </div>

                 <!-- TIMER FOR COURSE TOPIC SESSION LEVEL ASSESSMENT -->
                 <div class="banner-content-content" *ngIf="(courseAssessmentData.is_timed) && this.courseAssessmentData.total_time != 0 && !remainCounter" >
                    <h2 class="white-text avenirBlack prevent-select">{{counter |formatTime  }}</h2>
                </div> 

                <div class="banner-content-content"  *ngIf="(courseAssessmentData.is_timed) && this.courseAssessmentData.total_time != 0 && remainCounter">
                    <h2 class="white-text avenirBlack prevent-select">{{remainCounter |formatTime  }}</h2>
                </div>

                 <div class="banner-content-action btn-groups">
                     <button class="btn btn-primary btn-w-120" *ngIf="role == 'student'" (click)="go_to_url()">Cancel</button>
                 </div>
             </div>
        </div>
     </div>
     
     <div class="section course-assessment-wraper" [ngClass]="{submitting : loader}">
          <div class="container">
             <!-- <span class="timer" *ngIf="is_timed"><strong>{{assessmentTimer}}</strong></span> -->
     
               <div class="course-assessment-list">
                     <div class="text-center pt-2 pb-2">
                         <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                             <span class="sr-only">Loading...</span>
                         </div>
                     </div>
                    <form name="submitAssessment" [formGroup]="submitAssessmentForm" (ngSubmit)="formSubmit()">
                        <!-- *ngIf="assessmentData?.questions.length >0"  -->
                        
                        <div class="row g-0" *ngIf="assessmentData && assessmentData.questions"  [ngClass]="itemm != assessmentData?.questions.length ? 'd-block':'d-none' || isBlcokTrue == false ? 'd-none': 'd-block'">
                            <div class="col-md-10" >
                                <div class="course-assessment-qsn-blk" *ngFor="let aData of assessmentData?.questions;let i = index;" [ngClass]="i == itemm ? 'd-block':'d-none'">
                                     <div class="course-assessment-qsn  mb-3 ">
                                      <span class="badge badge-green-lt mb-2 prevent-select">Marks: {{aData?.marks}}</span>
                                      <div class="tinyText ass_ques_btn_wrap">
                                        <h5 class="avenirBlack"><span class="number-icon">{{i+1}}</span></h5>
                                        <div class="tinyTextContent"   [ngClass]="{'mb-1':assessmentData?.questions[itemm]?.question_recording_url && assessmentData.questions[itemm].question_recording_url.url 
                                        || assessmentData?.questions[itemm]?.question_video_url && assessmentData.questions[itemm].question_video_url.url || aData.question_type == 'multianswermcq'}" 
                                        [innerHTML]="aData?.question_html" 
                                        title="view image" 
                                        (click)="viewImagepopUP(aData?.question_html, viewImage)"></div>
                                        <p *ngIf="aData.question_type === 'multianswermcq'" class="mb-1"><small><i>Choose one or more correct answers. Select all that apply.</i></small></p>
                                        <button type="button" class="audio_video_iconButtton" 
                                        *ngIf="assessmentData?.questions[itemm]?.question_recording_url && assessmentData.questions[itemm].question_recording_url.url 
                                        || assessmentData?.questions[itemm]?.question_video_url && assessmentData.questions[itemm].question_video_url.url"
                                        (click)="openAudioVideoPopup()" 
                                        >Click this link to play the audio/video to answer the question.</button>
                                     
                                       <div formArrayName="questions" class="questionsAnsw">
                                           <!-- {{i}} -->
                                           <!-- {{submitAssessmentForm.value.questions | json }} -->
                                           <div *ngIf="aData.question_type == 'singleanswermcq'">
                                              <div [formGroupName]="i">
                                                  <div formArrayName="answer">
                                                      <div class="row gy-2" formGroupName="0">
                                                          <div class="col-md-6 custom-control custom-radio mb-2"
                                                              *ngFor="let option of aData?.option;let ii = index">
                                                              <input type="radio" [value]="option.option_value"
                                                                  class="form-check-input custom-control-input me-2" id="colAnsC{{i}}{{ii}}"
                                                                  formControlName="value"  [checked]="option?.checked" />
                                                              <label class="form-check-label custom-control-label prevent-select" for="colAnsC{{i}}{{ii}}">
                        
                                                                  <div class="d-inline-block vTop"
                                                                      [innerHTML]="option?.option_value"></div>
                        
                                                              </label>
                                                          </div>
                                                      </div>
                        
                                                  </div>
                                                  <!-- {{i}}
                                                  {{submitAssessmentForm.value.questions[i] | json }} -->
                                                  
                                                  <div class=" mt-4" >
                                                       <div>
                                                           <div class="btn-groups custom-check-btn mb-4">
                                                           <input type="checkbox" class="custom-check-input" [checked]="submitAssessmentForm.value.questions[i].mark" formControlName="mark"  [id]="'mark'+i" />
                                                           <label [for]="'mark'+i" class="custom-check-label btn " [ngClass]="submitAssessmentForm.value.questions[i].mark == true ? 'btn-primary':'btn-primary-border'"> 
                                                               Mark for Review
                                                           </label>
                                                           </div>
                                                       </div>
                                                   </div>
                                              </div>
                        
                                          </div>
                        
                                          <div *ngIf="aData.question_type == 'multianswermcq'">
                                              <div [formGroupName]="i">
                                                <div class="row gy-2" formArrayName="answer">
                                                      <div [formGroupName]="ii" class="col-md-6 custom-control custom-checkbox mb-2"
                                                          *ngFor="let option of aData?.option;let ii = index">
                                                          <input type="checkbox" [checked]="option?.checked" [value]="option?.checked"
                                                              (change)="selectedCheck($event,i,ii,option?.option_value)"
                                                              class="form-check-input custom-control-input" id="colAnsC{{i}}{{ii}}"
                                                              formControlName="value">
                                                          <label class="form-check-label custom-control-label prevent-select" for="colAnsC{{i}}{{ii}}">
                        
                                                              <div class="d-inline-block vTop"
                                                                  [innerHTML]="option?.option_value"></div>
                        
                                                          </label>
                                                      </div>
                                                  </div>
                                                  <div class=" mt-4" >
                                                   <div>
                                                       <div class="btn-groups custom-check-btn mb-4">
                                                       <input type="checkbox" class="custom-check-input" [checked]="submitAssessmentForm.value.questions[i].mark" formControlName="mark"  [id]="'mark'+i" />
                                                       <label [for]="'mark'+i" class="custom-check-label btn " [ngClass]="submitAssessmentForm.value.questions[i].mark == true ? 'btn-primary':'btn-primary-border'"> 
                                                           Mark for Review
                                                       </label>
                                                       </div>
                                                   </div>
                                               </div>
                                              </div>
                        
                                          </div>
   
                                          <div *ngIf="aData.question_type == 'bool'">
                                              <div [formGroupName]="i">
                                                  <div formArrayName="answer">
                                                      <div class="row gy-2" formGroupName="0">
                                                          <div class="col-md-6 custom-control custom-radio mb-2"
                                                              *ngFor="let option of aData?.option;let ii = index">
                                                              <input type="radio" [value]="option?.option_value" 
                                                                  class="form-check-input custom-control-input" id="colAnsC{{i}}{{ii}}"
                                                                  formControlName="value"   [checked]="option?.checked" />
                                                              <label class="form-check-label custom-control-label prevent-select" for="colAnsC{{i}}{{ii}}">
                        
                                                                  <div class="d-inline-block vTop"
                                                                      [innerHTML]="option?.option_value"></div>
                        
                                                              </label>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  
                                                   <div class=" mt-4" >
                                                       <div>
                                                           <div class="btn-groups custom-check-btn mb-4">
                                                           <input type="checkbox" class="custom-check-input" (change)="eventCheck($event)" [checked]="submitAssessmentForm.value.questions[i].mark" formControlName="mark"  [id]="'mark'+i" />
                                                           <label [for]="'mark'+i" class="custom-check-label btn " [ngClass]="submitAssessmentForm.value.questions[i].mark == true ? 'btn-primary':'btn-primary-border'"> 
                                                               Mark for Review
                                                           </label>
                                                           </div>
                                                       </div>
                                                   </div>
                                              </div>
                                             
                                              
                                          </div>
                        
                                          <div *ngIf="aData.question_type == 'speech'" [formGroupName]="i">
                                            <div formArrayName="answer">
                                                <div [formGroupName]="0">
                                                    <textarea class="form-control mb-5"  *ngIf="aData.ans_by_recording === false" rows="4" cols="10"
                                                    placeholder="Enter Answer" [value]="aData?.answer[0]?.value" formControlName="value"></textarea>
                                                    
                                                    <app-upload-video-audio-file *ngIf="aData.ans_by_recording === true" [isChildField]="true" [parentQusIndex]="i" 
                                                    [parentFieldControlName]="'questions'" [subQusIndex]="i" [fieldControlName]="'answer'" 
                                                    [fieldLabelName]="'File'" (moveButtonDisabled)="onMoveButtonDisabled($event)" [isRequiredField]="(aData.question_type == 'speech') ? true : false"></app-upload-video-audio-file>
                                                </div>
                                            </div>
                                            
                                            <div class=" mt-4" >
                                             <div>
                                                 <div class="btn-groups custom-check-btn mb-4">
                                                 <input type="checkbox" class="custom-check-input" [checked]="submitAssessmentForm.value.questions[i].mark" formControlName="mark"  [id]="'mark'+i" />
                                                 <label [for]="'mark'+i" class="custom-check-label btn " [ngClass]="submitAssessmentForm.value.questions[i].mark == true ? 'btn-primary':'btn-primary-border'"> 
                                                     Mark for Review
                                                 </label>
                                                 </div>
                                             </div>
                                         </div>
                      
                                        </div>

                                          <div *ngIf="aData.question_type == 'text'" [formGroupName]="i">
                                              <div formArrayName="answer">
                                                  <div [formGroupName]="0">
                                                      <textarea class="form-control mb-5"  *ngIf="aData.ans_by_recording === false" rows="4" cols="10"
                                                          placeholder="Enter Answer" [value]="aData?.answer[0]?.value" formControlName="value"></textarea>
                                                  
                                                  
                                                        <app-upload-video-audio-file   *ngIf="aData.ans_by_recording === true" [isChildField]="true" [parentQusIndex]="i" 
                                                        [parentFieldControlName]="'questions'" [subQusIndex]="i" [fieldControlName]="'answer'" 
                                                        [fieldLabelName]="'File'" (moveButtonDisabled)="onMoveButtonDisabled($event)" [isRequiredField]="(aData.question_type == 'text') ? true : false"></app-upload-video-audio-file>
                                                  </div>
                                              </div>
                                              
                                              <div class=" mt-4" >
                                               <div>
                                                   <div class="btn-groups custom-check-btn mb-4">
                                                   <input type="checkbox" class="custom-check-input" [checked]="submitAssessmentForm.value.questions[i].mark" formControlName="mark"  [id]="'mark'+i" />
                                                   <label [for]="'mark'+i" class="custom-check-label btn " [ngClass]="submitAssessmentForm.value.questions[i].mark == true ? 'btn-primary':'btn-primary-border'"> 
                                                       Mark for Review
                                                   </label>
                                                   </div>
                                               </div>
                                           </div>
                        
                                          </div>
   
                                          <div *ngIf="aData.question_type == 'fib'" [formGroupName]="i">
                                              <div formArrayName="answer">
                                                  <div [formGroupName]="0">
                                                      <textarea class="form-control" rows="4" cols="10"
                                                          placeholder="Enter Answer" [value]="aData?.answer[0]?.value" formControlName="value"></textarea>
                        
                                                  </div>
                                              </div>
                                              <div class=" mt-4" >
                                               <div>
                                                   <div class="btn-groups custom-check-btn mb-4">
                                                   <input type="checkbox" class="custom-check-input" [checked]="submitAssessmentForm.value.questions[i].mark" formControlName="mark"  [id]="'mark'+i" />
                                                   <label [for]="'mark'+i" class="custom-check-label btn " [ngClass]="submitAssessmentForm.value.questions[i].mark == true ? 'btn-primary':'btn-primary-border'"> 
                                                       Mark for Review
                                                   </label>
                                                   </div>
                                               </div>
                                           </div>
                                          </div>
                                          
   
                                       </div>
                                       
                                      
                                    </div>
                    
                                    <!-- <p class="dark-green-text mb-5"><strong>Correct Answer: Modifier</strong></p> -->
                                    </div>
                                    
                                </div>                          
                            </div>

                            <div class="col-12 stepsButtons d-flex align-items-center justify-content-between" *ngIf="!goSummary && !isBlcokTrue && !loader" [ngClass]="{disabled:moveButtonDisabledAction}">                           
                                <button type="button" class="btn btn-primary btn-w-120 prevButton"  [hidden]="itemm == 0" (click)="prevItem()">Previous</button>
                                <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="nextItem()">Next</button>
                            </div>
                            <div class="col-12 stepsButtons d-flex align-items-center justify-content-between" *ngIf="goSummary">
                                <button type="button" class="btn btn-primary btn-w-120 nextButton" (click)="goToSummary($event)">Go to result</button>
                            </div>
                        </div>
         
        
        
                    <div class="course-assessment-list" [ngClass]="isBlcokTrue ? 'd-block':'d-none'">
                    <div class="course-assessment-reviewBlk">
                     <div class="table-responsive assessment-reviewTable mb-3">
                         <table class="table">
                            <thead>
                                <tr> 
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th class="w-250px">Review</th>
                                    <th class="w-200px">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let newQ of newArrayItem; let i = index;">
                                    <td><span class="number-icon">{{i + 1}}</span></td>
                                    <td>
                                        <span class="dark-green-text" *ngIf="newQ.answerValue.length > 0"><i class="fas fa-check-double"></i></span>
                                        <span class="primary-text" *ngIf="newQ.answerValue.length == 0"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td class="w-250px"><small>{{newQ.markReview ? 'Marked for Review' : 'Not Marked for Review'}}</small></td>
                                    <td  class="w-250px"><button type="button" class="btn btn-primary-border btn-sm" [id]="i" (click)="goQustn($event, i)">Go to question</button></td>
                                </tr>
                            </tbody>
                         </table>
                       </div>
                       <div class="text-center mt-3">
                        <button class="btn btn-primary  btn-w-120">Submit</button>
                       </div>
                    </div>
                    </div>  
                </form>             
          </div>
     </div>
     </div>

</div>


<!-- audio/video question modal -->
<app-audio-video-model *ngIf="openVideoAudioPopup" [openVideoAudioPopup]="openVideoAudioPopup" 
(openVideoAudioPopupClick)="closeAudioVideoPopup()"
[videoCondion]="assessmentData?.questions[itemm]?.question_video_url && assessmentData.questions[itemm].question_video_url.url" 
[audioCondion]="assessmentData?.questions[itemm]?.question_recording_url && assessmentData.questions[itemm].question_recording_url.url"
[videoUrl]="assessmentData?.questions[itemm].question_video_url.url"
[audioUrl]="assessmentData.questions[itemm].question_recording_url.url"></app-audio-video-model>

<!-- Custom Alert Box -->
<div class="modalCustom" *ngIf="notAnsweredQusPopup">
    <div class="new_UIstyle">

    <div class="mBoxInner">
        <button type="button" class="close ml-0" aria-label="Close" (click)="closeCustomModel()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="mb-4">Alert!</h4>
        <p>{{alertMsg}}</p>

        <div class="text-right pt-2" *ngIf="(answerCounter > 0) && (answerCounter<assessmentData?.questions.length)">
            <button type="button" (click)="submitNo()" class="btn btn-grey ml-3">No</button>
            <button type="button" (click)="submitYes()" class="btn btn-primary ml-3">Yes</button>
        </div>
        <div class="text-right pt-2" *ngIf="assessmentData?.questions.length == answerCounter">
            <button type="button" (click)="submitOk()" class="btn btn-primary ml-3">Ok</button>
        </div>
    </div>
</div>
</div>

<!-- Custom Alert Box For Timed Assessment -->
<div class="modalCustom" *ngIf="timedAssessmentPopup">
    <div class="new_UIstyle">

    <div class="mBoxInner">
        <button type="button" class="close ml-0" aria-label="Close" (click)="closeCustomModel()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="mb-4">Alert!</h4>
        <p>{{alertTimedAssessmentMsg}}</p>

        <div class="text-right pt-2" >
            <button type="button" (click)="onOkTimedAssessment()" class="btn btn-secondary ml-3">Ok</button>
        </div>
    </div>
    </div>
</div>

<ng-template #viewImage let-c="close" let-d="dismiss">
    <div class="new_UIstyle">

    <div class="modal-header justify-content-between align-items-center">
        <h4 class="modal-title">View question Image</h4>
        <button type="button" class="close ml-0" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="rounded embed-responsive-16by9" *ngFor="let src1 of urls">
            <img [src]="src1" alt="view image">
        </div>
    </div>
    </div>
</ng-template>
