<div class="new_UIstyle">
    <!-- Banner Section start -->
<div class="banner banner-gradient banner-course-assement">
    <div class="container">
         <div class="banner-content">
             <div class="banner-content-content">
                 <h2 class="white-text avenirBlack mb-1">{{reportName}}</h2>
             </div>

             <div class="banner-content-action btn-groups">
                 <button class="btn btn-primary" routerLink="/report"><i class="fas fa-plus-circle"></i></button>
             </div>
         </div>
    </div>
 </div>
<!-- Banner Section End -->




<!-- <div class="banner">
    <div class="container text-center">
        <h1 class="mb-3">Report-List</h1>
        <button class="btn btn-primary" routerLink="/report"><i class="fas fa-plus-circle mr-2"></i>
            Add Report
        </button>
    </div>
</div> -->

<div class="pageBody  section qBank reportListWrap">
    <div class="container">
        <div [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>

            <div class="table-responsive maxHe8 bug_listdata">
                <table class="table alt">
                    <thead>
                        <tr>
                            <th class="w60p text-left">S.No.</th>
                            <th class="text-left"> Application Type</th>
                            <th class="text-left">Type</th>
                            <th class="w100p text-center">Description</th>
                            <th class="w100p text-center">File</th>
                            <th class="w100p text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let buglist of bugs_listdata; let i = index;">
                            <td class="text-left">{{i+1}}</td>
                            <td class="text-left">{{buglist.type}}</td>
                            <td class="text-left">{{buglist.report_type}}</td>
                            <td class="text-left">{{buglist.description}}</td>
                            <td class="text-left">
                                <a download href="{{buglist.report}}" target="_blank" class="download_att"> 
                                   Download Attachment
                                 </a>
                               </td>
                        <td>
                            <div class="select1 form">
                                <select name="format" id="format" [(ngModel)]="buglist.status" class="form-control form-control-style-2 form-control-caret" (change)="get_patch(buglist._id)">
                                    <option value="false" disabled>IN PROGRESS</option>
                                    <option value="true">COMPLETED</option>
                                </select>
                            </div>
                        </td>
                           
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>