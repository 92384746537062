import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as fileSaver from 'file-saver';
import { CourseWorkUtilityService } from '../../services/course-work-utility.service';

@Component({
  selector: 'app-course-work-test',
  templateUrl: './course-work-test.component.html',
  styleUrls: ['./course-work-test.component.scss']
})
export class CourseWorkTestComponent implements OnInit {

  @Input() reportIndex:any;
  @Input() showCourseWork: any;
  @Input() courseworks: any;
  @Input() courseId: any;
  @Input() teachingAssistant: any;
  @Input() reviewSavedStatus: any;
  @Input() course_active:any;
  @Output() sendSubmissionData = new EventEmitter();
  
  progressBar: number = 0;
  role: string = '';
  loader: boolean = false;
  zipDownloading: boolean = false;
  viewSubmissionData: any[] = [];
  
  loggedInUserId: string = '';
  isAssistant: boolean = false;
  isUploadWorkAllowed: boolean = false;
  uploadedWorkFiles: any[] = [];
  submissionReportDetail: any = {};
  submissionPublishStatus: boolean = false;
  isFileDeleteAllowed: boolean = false;
  isSubmissionLength: boolean = false;
  submissionsList: any;
  late_submission:boolean = false;
  constructor(
    private courseService: CourseService,
    private notificationService: NotificationService,
    private router: Router,
    private cwUtilityService: CourseWorkUtilityService) { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
    this.checkIsAssistant();
    this.checkUploadWorkAllowed();
    this.viewCourseSubmission(this.showCourseWork['submission_report']);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes.reviewSavedStatus.firstChange) {
     if(this.reviewSavedStatus.status == true) {
       this.viewCourseSubmission(this.showCourseWork['submission_report']);
     }
    }
  }

  upload_resource(event, modelName, schedule_id, course_work_id, submission_report, date, days) {
    let submission_course = this.showCourseWork;
    let courseworkIndex = '';

    //CHECK IF FILE SELECTED
    if (event.target.files.length == 0) {
      return;
    }
  
    //FOR AUTO GRADING COURSEWORK SUBMISSION
    let autograding_id = '';
    let file = event.target.files[0];
    if (submission_course.autograding_id) {
      autograding_id = submission_course.autograding_id;
        if(file.type != '' && ['text/x-python', 'text/x-python-script'].indexOf(file.type) == -1) {
        this.notificationService.showNotification('info', 'Please add Python program for this course work.');
        return;
      } else if (file.type == '') {
        let split_file = file.name.split('.');
        if (split_file[1].toLowerCase() != 'py') {
          this.notificationService.showNotification('info', 'Please add Python program for this course work.');
          return;
        }
      }
    }

    let team_name = '';
    if (this.courseworks.group_type == 'Student-led' || this.courseworks.group_type == 'manual') {
      if (this.courseworks.team_info[0]) {
        team_name = this.courseworks.team_info[0].name;
      } else {
        this.notificationService.showNotification('info', 'Please specify the team first');
        return;
      }
    }

    let resource_data;
    this.notificationService.upload_resource(modelName, file).subscribe((res: HttpEvent<any>) => {
      switch (res.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressBar = Math.round(res.loaded / res.total * 100);
          break;
        case HttpEventType.Response:
          resource_data = res.body.resource_info[0];

          this.notificationService.showNotification('success', res.body.message);
          this.submitSubmission(schedule_id, course_work_id, submission_report,this.reportIndex, resource_data.resource_id, courseworkIndex, team_name, autograding_id);
          setTimeout(() => {
            this.progressBar = 0;
          }, 1000);
      }
    }, (err) => {
      this.notificationService.showNotification('info', 'Please add again not able to save your file');
    });
  }

  submitSubmission(schedule_id, course_work_id, submission_report, reportIndex, fileId, courseworkIndex, team_name, autograding_id?: string) {
    let body = {
      course_id: this.courseId,
      schedule_id: schedule_id,
      course_work_id: course_work_id,
      file_id: fileId,
      "report_name": submission_report,
      "report_index": reportIndex,
      "team_name": team_name
    }

    if (autograding_id) {
      body['autograding_id'] = autograding_id;
    }

    this.loader = true;
    this.courseService.submit_course_work_submission(body).subscribe(getSubmissionResponse => {
      this.loader = false;
      let course_work = {
        'team_info': [],
        '_id': course_work_id,
        'schedule_id': schedule_id,
      }
      this.viewCourseSubmission(courseworkIndex, team_name);
      this.notificationService.showNotification('success', getSubmissionResponse.message);
    }, (err) => {
      this.notificationService.showNotification('error', err.message);
      this.loader = false;
    });
  }

  deleteUploadedWork(courseId,schedule_id, course_work_id, fileId, report_name, reportIndex, team_name) {
    this.loader = true;
    this.courseService.delete_resouce(courseId,schedule_id, course_work_id,fileId, report_name, reportIndex, team_name).pipe(finalize(() => {
    })).subscribe(req => {
      this.loader = false;
      this.notificationService.showNotification("success", "File deleted successfully.");
      this.viewCourseSubmission();
    }, (err) => {
      this.loader = false;
      console.log(err);
      this.notificationService.showNotification("error", "This coursework is Expired");
    });
  }

  isCW_group = false;
  viewCourseSubmission(courseworkIndex?: string, team_n?: string) {
    let course_work: any = null;
    course_work = this.courseworks;
  this.isCW_group = course_work.is_group;
    let team_name = '';
    if(course_work.is_group) {
      if(course_work.team_info.length > 0){
        team_name = course_work.team_info[0].name;
      }
    }

    if(this.isAssistant == true) {
      return;
    }

    if(course_work.is_group == true && !this.isApprovedTeamMember(course_work.team_info)) {
      return;
    }

    if(this.role == 'student' && this.isCourseWorkStarted(course_work.date, course_work.time)) {

      this.loader = true;
      this.courseService.viewSubmission(team_name, course_work._id, course_work.schedule_id, this.courseId, course_work.peer_review, course_work.self_review, 'grade_view').subscribe(viewSubmissions => {
        this.loader = false;
        this.viewSubmissionData = [];
        if(Object.keys(viewSubmissions.response).length > 0) {
          this.viewSubmissionData = viewSubmissions.response;
          this.uploadedWorkFiles = this.viewSubmissionData[0].submissions[this.showCourseWork.submission_report].files;
          this.submissionReportDetail = this.viewSubmissionData[0].submissions[this.showCourseWork.submission_report];
          this.submissionsList = this.viewSubmissionData[0].submissions;
          this.submissionPublishStatus = viewSubmissions.submission_publish;
          this.late_submission = this.viewSubmissionData[0].late_submission;
          this.manageSubmissionReport();
        }
        this.getSubmissionsWithFiles();
        if(this.isCourseWorkExpired()){
          this.isSubmissionLength =  this.countSubmissionsWithFiles();
        }
        //SEND SUBMISSION DATA
        this.sendSubmissionData.emit({viewSubmissionData: this.viewSubmissionData, submissionPublish: viewSubmissions.submission_publish});
      }, (err) => {
        this.loader = false;
        if(err.error.detail) {
          console.log(err.error.detail);
        }
        else {
          console.log(err);
        }
      });
    }
  }

  //SHOW HID FILE DELETE BUTTON
  manageSubmissionReport() {
    this.isFileDeleteAllowed = false;
    let selfReviewReport = '';
    let peerReviewReport = '';
    let remarkReport = '';
    let gradeReport = '';

    if(this.courseworks.is_group == false) {
      if(this.submissionReportDetail.self_review.content) {
        selfReviewReport = this.submissionReportDetail.self_review.content;
      }

      if(this.submissionReportDetail.peer_review.content) {
        peerReviewReport = this.submissionReportDetail.peer_review.content;
      }
    }

    if(this.submissionReportDetail.remark.content) {
      remarkReport = this.submissionReportDetail.remark.content;
    }

    if(this.submissionReportDetail.grade.content) {
      gradeReport = this.submissionReportDetail.grade.content;
    }

    if(this.submissionPublishStatus == false && !this.isCourseWorkExpired()) {
      if(!selfReviewReport && !peerReviewReport && !remarkReport && !gradeReport) {
        this.isFileDeleteAllowed = true;
      }
    }
  }


  hasSubmissionsOnDate(targetDateValue: any, time:any): boolean {
    if(this.uploadedWorkFiles.length === 0){
      return false;
    }
    let submissionCounts =  this.uploadedWorkFiles.filter(submission => {
      let updatedate;
      let courseWorkTime;
      courseWorkTime = time.split(':')
      let day = targetDateValue.day;
      let month = targetDateValue.month;
      let year = targetDateValue.year;
      let date = year + '-' + month + '-' + day;
      updatedate = new Date(Date.parse(date));
      updatedate.setHours(Number(courseWorkTime[0]), Number(courseWorkTime[1]));
      return  new Date(submission.timestamp).getTime() <= updatedate.getTime();
    });
     return submissionCounts.length > 0;
  }


 // Method to check if any submission has files
  hasFiles(submission: any): boolean {
    return submission.files.length > 0;
  }

  // Method to get a list of submissions with files
  getSubmissionsWithFiles(): any {
    let submissionValue = [];
    if(this.viewSubmissionData.length === 0){
      return submissionValue;
    } 
    submissionValue = Object.keys(this.submissionsList).filter(submissionKey => {
    return this.hasFiles(this.submissionsList[submissionKey])
    });
    return submissionValue;
  }

  // Method to get the count of submissions with files
  countSubmissionsWithFiles(): boolean {
    return this.getSubmissionsWithFiles().length > 0;
  }


  isCourseWorkStarted(dateValue, time) {
    return this.cwUtilityService.isCourseWorkStarted(dateValue, time);
  }

  isApprovedTeamMember(team: any) {
    return this.cwUtilityService.isApprovedTeamMember(team);
  }

  isCourseWorkExpired() {
    const cwExpireDate = this.cwUtilityService.getExpireDate(this.courseworks.date, this.showCourseWork.days_of_completion);
    return this.cwUtilityService.isCourseWorkExpired(cwExpireDate, this.courseworks.time);
  }

  //SHOW HIDE UPLOAD WORK BUTTON
  checkUploadWorkAllowed() {
    if((this.isAssistant == false) && (this.role == 'student') && this.isCourseWorkStarted(this.courseworks.date, this.courseworks.time) && ( (this.courseworks.group_type == '') || ((this.courseworks.group_type == 'Student-led' || this.courseworks.group_type == 'manual') && this.isApprovedTeamMember(this.courseworks.team_info)) )) {
      this.isUploadWorkAllowed = true;
    }
    else {
      this.isUploadWorkAllowed = false;
    }
  }

  checkIsAssistant() {
    let userIndex = this.teachingAssistant.findIndex(e => e._id === this.loggedInUserId);
    if(userIndex != -1) {
      this.isAssistant = true;
    }
    else {
      this.isAssistant = false;
    }
  }

  openUploadedWork(url) {
    window.open(url, '_blank');
  }

  //DOWNLOAD ZIP FILE |START
  getZipFile() {

    const submissionReportUserId = this.viewSubmissionData[0].user_id;
    const submissionReportUsername = this.viewSubmissionData[0].name;
    
    let team_name = '';
    let username = submissionReportUsername;
    if (this.courseworks.is_group) {
      if (this.courseworks.team_info.length) {
        team_name = submissionReportUsername;
        username = team_name;
      }
    }
    this.zipDownloading = true;
    this.courseService.get_zip_submission(this.courseworks._id, this.courseworks.schedule_id, submissionReportUserId, team_name).subscribe(get_zip => {
      this.zipDownloading = false;
      let d = new Date();
      let fullDate: any = this.appendZero(d.getDate()) + '-' + this.appendZero((d.getMonth() + 1)) + '-' + d.getFullYear() + '_' + this.appendZero(d.getHours()) + this.appendZero(d.getMinutes()) + this.appendZero(d.getSeconds());
      let zip_file: any = new Blob([get_zip.body], { type: 'application/zip' });
      let fileName = username + '_' + fullDate + '.zip';
      fileSaver.saveAs(zip_file, fileName);
      this.notificationService.showNotification('success', 'Your Zip file is downloading')
    },
    (err) => {
      this.zipDownloading = false;
      if (err.message == undefined) {
        this.notificationService.showNotification('error', 'No submission is present');
        return;
      }
    });
  }

  appendZero(val) {
    if (val < 10) {
      return '0' + val;
    }
    return val;
  }
  //DOWNLOAD ZIP FILE |END
}
