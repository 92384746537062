import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-reportlist',
  templateUrl: './reportlist.component.html',
  styleUrls: ['./reportlist.component.scss']
})
export class ReportlistComponent implements OnInit {

  bugs_list: any[] = [];
  bugs_listdata = [];
  isCourseWorkAvailable: boolean = false;
  loader = false;
  enabled: boolean;
  reportName:string = 'Report List';
  constructor(private course_service:CourseService, private router: Router, 
    private ns:NotificationService) { }

  ngOnInit() {
    this.get_organization();
  }

  get_organization() {
    this.loader = true;
    this.course_service.bug_list().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(organization => {
      this.bugs_list = organization.response
      this.bugs_listdata = this.bugs_list;
      console.log(this.bugs_list)
    },
      (error) => {
        this.ns.showNotification('error', error.message);
      })
  }

  get_patch(Id) {
   
    this.loader = true;
    this.course_service.patchCoursebuglist(Id).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(res => {
      this.ngOnInit();
      this.loader = false;
    },
      (error) => {
        this.ns.showNotification('error', error.message);
      })
  }

}


