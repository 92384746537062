import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Observer, throwError } from 'rxjs';
import { teachers } from '../model/user.model';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

declare var tinymce: any;


@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  apiEndpoint: any;
  chat_app_api: any;

  constructor(private notifierService: NotifierService, private httpClient: HttpClient,private navigate:Router) {
    this.apiEndpoint = environment.APIEndpoint;
    this.chat_app_api = environment.socket_EndPoint;
  }

  showNotification(type: string, message: string) {
    this.notifierService.notify(type, message);
  }
  getOrganisation(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + "/organisations")
  }

  getNotificationsDropDown(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + "/notification/unread")
  }

  getNotifications(parametersVal): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', parametersVal);
    return this.httpClient.get<any>(this.apiEndpoint + "/notification", { params })
  }

  deleteNotify(notify_ID): Observable<any> {
    let body = {
      notification_id:notify_ID
    }
    return this.httpClient.patch(this.apiEndpoint + "/notification/remove",body);
  }

  readNotify(notify_ID): Observable<any> {
    let body = {
      _id:notify_ID
    }
    return this.httpClient.patch(this.apiEndpoint + "/notification/read",body);
  }



  show_Hide_notification(checkVal:boolean): Observable<any> {
    let body = {
      notification_allow:checkVal
    }
    return this.httpClient.patch(this.apiEndpoint + "/notification/allow",body);
  }

  getTeachersList(query, role?: string, organization?: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', query);
    if (role != '') {
      params = params.append('role', role);
    }

    if (organization != '') {
      organization.forEach(element => {
        params = params.append('organisation_name', element);
      });
      params = params.append("module", "course");
    }

    return this.httpClient.get(this.apiEndpoint + "/users", { params: params }).pipe(map((res: teachers[]) => {
      let teachers_list_map = [];
      res.forEach((teacher: teachers) => {
        teachers_list_map.push({ _id: teacher['id'], username: teacher['username'] })
      })
      return teachers_list_map;
    }));
  }

  upload_resource(moduleName, file): Observable<any> {
    let params = new HttpParams();
    const files = new FormData();

    files.append('resource', file);
    params = params.append('module_name', moduleName);
    return this.httpClient.post<any>(this.apiEndpoint + "/upload-resource", files, {
      params,
      reportProgress: true,
      observe: 'events'
    });
  }

  get_date_with_update(dateValue, frequency?: string, end_date?: any, start_time?: any, duration?: any) {
    let newDate = new Date()
    let hours = start_time.split(':')
    let dura = (Number(duration) / 60);
    let rhours = Math.floor(Number(dura));
    let minutes = Number((Number(dura) - Number(rhours)) * 60);
    let rminutes = Math.round(Number(minutes));

    let expireDate;
    let updatedate;
    let liveSessionDate;
    hours[0] = Number(hours[0]) + Number(rhours);
    hours[1] = Number(hours[1]) + Number(rminutes);
    let date = dateValue.year + '-' + dateValue.month + '-' + dateValue.day;
    let end_date_value = end_date.year + '-' + end_date.month + '-' + end_date.day

    updatedate = new Date(Date.parse(date));
    expireDate = new Date(Date.parse(end_date_value));
    updatedate.setHours(Number(hours[0]), Number(hours[1]));
    expireDate.setHours(Number(hours[0]), Number(hours[1]));
    if (newDate.getTime() < expireDate.getTime()) {
      if (frequency == 'weekly') {
        if (updatedate.getTime() < newDate.getTime()) {
          let diffTime = Math.abs(newDate.getTime() - updatedate.getTime());
          const diffDays = Math.ceil((diffTime / (1000 * 60 * 60 * 24)) / 7);
          updatedate.setDate(updatedate.getDate() + (diffDays) * 7);
        }
      } else if (frequency == 'daily') {
        if (updatedate.getTime() < newDate.getTime()) {
          let diffTime = Math.abs(newDate.getTime() - updatedate.getTime());
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          updatedate.setDate(updatedate.getDate() + diffDays);
        }
      }
    } else {
      return liveSessionDate = { day: expireDate.getDate(), month: expireDate.getMonth() + 1, year: expireDate.getFullYear() };
    }
    liveSessionDate = { day: updatedate.getDate(), month: updatedate.getMonth() + 1, year: updatedate.getFullYear() };
    updatedate = '';
    return liveSessionDate;
  }

  checkValidDate(use_date, time) {
    let updatedate;
    let _time;
    if (time) {
      _time = time.split(':')
    }
    let day = use_date.day;
    let month = use_date.month;
    let year = use_date.year;
    let date = year + '-' + month + '-' + day;
    updatedate = new Date(Date.parse(date));
    updatedate.setHours(Number(_time[0]), Number(_time[1]));
    let curretdate = new Date();
    return curretdate.getTime() < updatedate.getTime();
  }
  weblink_validation(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  _user_list(): Observable<any> {
    return this.httpClient.get(this.chat_app_api + "/users-list").pipe(map((res) => {
      let teachers_list_map = res;
      return teachers_list_map;
    }));
  }

  user_list_New(scrollNumber:any, usersLen:any, mentor_room_id:string = '', pageRef:string =''): Observable<any> {
    let params = new HttpParams();
    
    params = params.append('app_type', 'webapp');
    params = params.append('page', scrollNumber);
    params = params.append('total_user', usersLen);
    if(localStorage.getItem('isMentorredirect') === 'true'){
      params = params.append('specific_room', mentor_room_id);
    }
    if (pageRef) {
      params = params.append('page_ref', pageRef)
    }
    return this.httpClient.get(this.chat_app_api + "/users-list", {params: params}).pipe(map((res) => {
      let teachers_list_map = res;
      return teachers_list_map;
    }));
  }

  get_user_request_status(): Observable<any> {
    return this.httpClient.get(this.chat_app_api + "/chat/chat_request")
  }
  get_invites_status(): Observable<any> {
    return this.httpClient.get(this.chat_app_api + "/chat/request/invites")
  }

  // all_group__New(scrollNumber:any): Observable<any> {
  //   let params = new HttpParams();
    
  //   params = params.append('app_type', 'webapp');
  //   params = params.append('page', '3');
  //   return this.httpClient.get(this.chat_app_api + "/chat/all-groups", {params: params}).pipe(map((res) => {
  //     let group_list_map = res;
  //     return group_list_map;
  //   }));
  // }


  find_user(query): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', query);

    return this.httpClient.get(this.chat_app_api + "/search/chat-users", { params: params }).pipe(map((res: teachers[]) => {
      let teachers_list_map = [];
      teachers_list_map = res;
      return teachers_list_map;
    }));
  }


  find_Message(query): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', query);

    return this.httpClient.get(this.chat_app_api + "/search/chat-messages", { params: params });
  }

  search_conversions(query): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', query);
    return this.httpClient.get(this.chat_app_api + "/search/conversation", { params: params });
  }

  userActiveStatus(status): Observable<any> {
    let statusData = {
      chat_status: status
    }
    return this.httpClient.post(this.chat_app_api + "/user_status", statusData);
  }

  getUserActiveStatusApi(): Observable<any> {
    return this.httpClient.get(this.chat_app_api + "/user_status");
  }


  markFavUserApi(favStatus, userId): Observable<any> {
    let favData = {
      is_fav: favStatus,
      user_id:userId
    }
    return this.httpClient.post(this.chat_app_api + "/mark_user_fav", favData);
  }

  markFavStatusApi(userId): Observable<any> {
    let params = new HttpParams();
    params = params.append('user_id', userId);

    return this.httpClient.get(this.chat_app_api + "/mark_user_fav", { params: params });
  }

  

  favUsersList(): Observable<any> {
    return this.httpClient.get(this.chat_app_api + "/fav_users");
  }


  userblockApi(isBlocked,userId): Observable<any> {
    let body = {
      block: isBlocked,
      user_id:userId
    }
    return this.httpClient.patch(this.apiEndpoint + "/chat/user/block",body);
  }

  addMoreMemberApi(moreMember:any,userId:string): Observable<any> {
    let body = {
      more_users: moreMember,
      room_id:userId
    }
    return this.httpClient.patch(this.chat_app_api + "/chat/group/add_member",body);
  }


  userdetetechatApi(room_id): Observable<any> {
    let body = {
         room_id: room_id
    }
    return this.httpClient.patch(this.chat_app_api + "/chat_message",body);
  }


  muteNotifyApi(isMuted:boolean, room_id:string): Observable<any> {
    let body = {
         is_mute: isMuted,
         room_id: room_id
    }
    return this.httpClient.patch(this.apiEndpoint + "/chat/notification/mute",body);
  }

  reportUserApi(report_reason:string, user_id:string): Observable<any> {
    let body = {
      report_reason: report_reason,
      user_id: user_id
    }
    return this.httpClient.patch(this.apiEndpoint + "/chat/user/report",body);
  }

  
  room_Info(roomId): Observable<any> {
    let params = new HttpParams();
    params = params.append('room_id', roomId);
    return this.httpClient.get(this.chat_app_api + "/chat/room-info", { params: params });
  }




  chat_history(): Observable<any> {
    return this.httpClient.get(this.chat_app_api + "/chat-users");
  }

  titleCase(str) {
    if (str == null || str == undefined) {
      return;
    }
    let string = str.split('. ');
    for (var i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join('. ');
  }

  paraString(str) {
    let para = str.split('<p>');
    for (let ii = 0; ii < para.length; ii++) {
      para[ii] = para[ii].charAt(0).toUpperCase() + para[ii].slice(1);
    }
    return para.join('<p>');
  }

  get_status_zoom(status) {
    if (status == "recorded") {
      return true;
    } else if (status == "zoom_recorded") {
      return true;
    }
    return false;
  }

  get_time_zone(time) {
    let time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let d = new Date(time);
    d.toLocaleString('en-US', { timeZone: time_zone });
    let date = d.toDateString();
    let t = d.toTimeString();
    let time_stemp = date + ' ' + t.split('GMT')[0];
    return time_stemp;
  }

  convert_into_ist(time) {
    let time_start = time.split('T');
    let time_ = time_start[0].split(':').join('-');
    let update_time = time_.concat('T' + time_start[1]);
    let ist = new Date(update_time);
    let ist_convert = this.get_time_zone(ist);
    return ist_convert;
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  upload_urls(moduleName, file_names): Observable<any> {
    let params = new HttpParams();
    params = params.append('module_name', moduleName);
    let body = {
      files: file_names
    }
    return this.httpClient.post<any>(this.apiEndpoint + "/upload_url", body, { params });
  }

  upload_to_s3(url, body): Observable<any> {
    return this.httpClient.put<any>(url, body, { observe: 'response' }).pipe(
      map(data => { data }),
      catchError(this.handleError)
    );
  }


  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }


  base64Image: any;
  downloadImage(imageURL, name) {
    let imageUrl = imageURL;
    if (!name) {
      name = 'download.jpg'
    }

    this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
      this.base64Image = "data:image/jpg;base64," + base64data;
      // save image to disk
      var link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", this.base64Image);
      link.setAttribute("download", name);
      link.click();
      // this.showNotification('success', 'Your Image has been downloaded');
    });
  }

  getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");
    // this.showNotification('info', 'Your Image is in progress');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }




  downloadFiles(fileUrl:string):Observable<Blob>{
    return this.httpClient.get(fileUrl, {
      responseType:'blob',
      headers: new HttpHeaders().append('Content-Type','application/json')
    });
  }

  susbcribe_courses = [];
  teach_on_edu = [];
  other_course = [];
  getReletedCourses(categoryVisDetails) {
    this.susbcribe_courses = [];
    this.susbcribe_courses = [];
    this.other_course = []
    categoryVisDetails.forEach(element => {
      if (element['subscribed'] === true) {
        this.susbcribe_courses.push(element)
      }
      if (element['edit_right'] === true) {
        this.teach_on_edu.push(element);
      }
      if (element['subscribed'] == false) {
        this.other_course.push(element)
      }
    });
  }
  
  navigate_to(url) {
    this.navigate.navigate([url]);
  }

  navigate_wirh_id(url, id, courseName:string= null, tab_no:any = 1) {
    // this.navigate.navigate([url], { queryParams: { _id: id } });
    this.navigate.navigate([url], { queryParams: { course_id: id,course:courseName,tab_no:tab_no} }); //  Due to conflict quary param key changed
  }

  getChatAllowedStatus(userId): Observable<any> {
    let params = new HttpParams();
    params = params.append('user_id', userId);

    return this.httpClient.get(this.chat_app_api + "/chat_allowed_status", { params: params });
  }
}