import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { allCategory, CourseInfo } from 'src/app/model/course.model';
import { CourseService } from 'src/app/services/course.service';
import { ShareService } from 'src/app/services/share.service';

import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-ec-course-board',
  templateUrl: './ec-course-board.component.html',
  styleUrls: ['./ec-course-board.component.scss',
  ]
})
export class EcCourseBoardComponent implements OnInit {
  showSchBx: boolean = false;
  
  constructor(private modalService: NgbModal,private courseService: CourseService, private shareService: ShareService, rateConfig: NgbRatingConfig, private router: Router,private ns: NotificationService,) { }
  allCategory: allCategory[] = [];
  categoryVisDetails: CourseInfo[] = [];
  susbcribe_courses: CourseInfo[] = [];
  teach_on_edu: CourseInfo[] = [];
  other_course: CourseInfo[] = [];
  popupModel = null;
  role = '';
  total_pages
  current_page;
  number_of_page;

  total_pages_deactivate;
  current_page_deactivate;
  number_of_pages_deactivate = []
  totalLengthU:any
  get permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  }
  pageP: number = 1;
  //FOR BUILD FIX
  active: any = '';

  ngOnInit() {
    let val = '';
    let status = false;
    this.role = localStorage.getItem('role');
    this.get_deactivated_course(1);
  }
  loader = false;
  

  deactivated_course_list = [];
  //Fetch all deactive courses
  get_deactivated_course(page) {
    this.deactivated_course_list = [];
    this.courseService.deactivated_course(page).subscribe(get_list=> {
      this.deactivated_course_list = get_list.response.data; 
      this.totalLengthU = get_list.response.total_items
    },
    (err)=> {
      console.log(err);
    })
  }
  //show pagination current, next and previous page
  currentPageNo(pageNo) {
    this.pageP = pageNo;
    if(pageNo){
      this.get_deactivated_course(pageNo)
    }
  }

  //show course details page
  sendCourseDetails(id, course) {
    this.router.navigate(['/courses/single-course'], { queryParams: { course_id: id, course: course, tab_no: 1 } });
  }

  //show activate course if yes called
  activate_course(course_id) {
    this.loader = true
    this.courseService.activate_course(course_id).subscribe(activate_course => {
      this.closeCoursePopup()
      this.ns.showNotification('success', activate_course.message);
      this.get_deactivated_course(1)
      this.loader = false
    },
      (err) => {
        this.ns.showNotification('error', err.message);
        this.loader = false
      })
    
  }

  //Activate popup open for conformation 
  activeCourseModel(deativeCourse){
    this.popupModel = this.modalService.open(deativeCourse, { size: "sm", centered: true });
  }

  //close Activate popup
  closeCoursePopup() {
    this.popupModel.close();
    setTimeout(() => {
      this.deactivated_course_list.forEach(item => item.active = false);
    })
  }
}