import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalRef, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../services/notification.service';
import { CourseService } from '../../services/course.service';
import { CourseWorkUtilityService } from '../../services/course-work-utility.service';
import { PassionProjectService } from 'src/app/services/passion-project.service';

@Component({
  selector: 'app-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.scss']
})
export class TeamCreateComponent implements OnInit {

  @Input() viewItemId: string = '';
  @Input() btnRef: string = '';
  @Input() teamData: any = {};
  @Output() teamStatus = new EventEmitter<any>();

  //FOR STUDENT COURSE WORK
  loader: boolean = false;
  courseName: string = '';
  courseId: string = '';
  courseWorkId: string = '';
  topicId: string = '';
  courseworks: any = {};
  courseData: any = {};
  courseTitle: string = '';
  isTopicCoursework: boolean = false;	
  
  //FOR TEAM
  groupProcessing: boolean = false;
  courseworkTeaminfo: any = [];
  userDefaultPic: string = environment.imageBasePath + environment.userDefaultProfilePic;
  createGroupModal: NgbModalRef;
  addTeamByStudnet: FormGroup;
  subscribeStudentList = [];
  addedMembers: any = [];
  resetTeam: boolean = false;
  groupActionName = 'Create';
  editTeamData: any = {};
  uploadedImg: string = '';
  resourcePath: string = '';
  loggedInUserId: string = '';
  defaultSubscriber: any = {};
  showCreateGroup: boolean = false;

  role: string = '';
  avatar: string = '';
  username: string = '';
  fullName: string = '';
  resourceModuleName: string = 'passion_project';
  searchText: string = '';
  isSearchEnabled: boolean = false;
  leadMember: any = {};
  project_Publish_Status:boolean = false;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private notificationService: NotificationService, private courseService: CourseService, private cwUtilityService: CourseWorkUtilityService, private passionProjectService: PassionProjectService) { }

  ngOnInit() {
  	this.role = localStorage.getItem('role');
    this.loggedInUserId = localStorage.getItem('user_id');
    this.fullName = localStorage.getItem('first_name');
    this.username = localStorage.getItem('currentUser');
    this.avatar = localStorage.getItem('avatarURL');
    let  pp_status = localStorage.getItem('_Passion_Publish_Status');
    this.project_Publish_Status = JSON.parse(pp_status);
  }

  //CREATE AND EDIT TEAM |START
  createForm(): void {
    this.addTeamByStudnet = this.fb.group({
      name: [""],
      userlist: [""],
    });
  }

  //FOR UPLOAD TEAM PICTURE |START
  onSelectFile(event) {
    let files = event.target.files;
    //console.log(files);
    if (files.length) {
      const fileSize = files[0].size / 1024 / 1024;
      const limitSize = environment.groupProfilePicSize;
      if (fileSize > limitSize) {
        this.notificationService.showNotification('info', 'File size exceeds ' + limitSize + ' MB');
        return;
      }
      this.uploadGroupPic(files[0]);
    }
  }

  onFileInputClick(event) {
    event.target.value = null;
  }

  uploadGroupPic(file) {
    this.groupProcessing = true;
    this.notificationService.upload_resource(this.resourceModuleName, file).subscribe((response: HttpEvent<any>) => {
      switch (response.type) {
        case HttpEventType.Response:
          const resourceData = response.body.resource_info[0];
          this.resourcePath = resourceData.path;
          this.uploadedImg = resourceData.url;
          this.groupProcessing = false;
          this.notificationService.showNotification('success', 'File uploaded successfully');
      }
    },
      (err) => {
        this.groupProcessing = false;
        this.notificationService.showNotification('info', 'Please add again not able to save your file');
      });
  }
  //FOR UPLOAD TEAM PICTURE |END

  openGroupCreateModel(content, formRef, editTeam: any = {}) {
    this.addedMembers = [];
    this.groupActionName = formRef;
    this.resourcePath = '';
    this.uploadedImg = '';
    this.createForm();

    //FOR CREATE GROUP    
    if(formRef == 'Create') {
      this.setInitialData(formRef);
    }

    //FOR EDIT GROUP
    if (formRef == 'Edit') {
      this.groupProcessing = true;
      this.passionProjectService.getTeamsById(this.viewItemId, editTeam._id)
      .subscribe(res => {
          this.groupProcessing = false;
          this.editTeamData = res.teams[0];
          if(this.editTeamData.logo) {
            this.uploadedImg = this.editTeamData.logo;
          }
          if(this.editTeamData.logo_path) {
            this.resourcePath = this.editTeamData.logo_path;
          }
          this.addTeamByStudnet.controls['name'].setValue(this.editTeamData.name);
          this.setInitialData(formRef);
        }, err => {
          this.groupProcessing = false;
          this.notificationService.showNotification('error', err.message);
      });
    }

    this.createGroupModal = this.modalService.open(content, { windowClass: 'createGroupModel', centered: true, size: "xl", backdrop: 'static', keyboard: false });
  }

  closeGroupCreateModel() {
    this.addedMembers = [];
    this.subscribeStudentList = [];
    this.searchText = '';
    this.isSearchEnabled = false;
    this.createForm();
    this.createGroupModal.close();
  }

  discardChanges() {
    this.addedMembers = [];
    //this.getSubscribeStudent('Discard');
    this.setInitialData('Discard');
  }

  getSubscribeStudent(ref = '') {
    let role = 'student';
    this.groupProcessing = true;
    this.passionProjectService.searchTeamMember(this.searchText, role).subscribe((response) => {
      this.subscribeStudentList = response;
      //console.log(this.subscribeStudentList);

      //FOR DISABLE SUBSCRIBER IF ALREADY ADDED
      this.disableSubscribers(ref);

      this.groupProcessing = false;
    }, err => {
      this.groupProcessing = false;
      this.handleError(err);
    });
  }

  setInitialData(ref) {

    //FOR EDIT GROUP
    if(this.groupActionName == 'Edit') {
      this.initSubscribers(ref);
    }

    if(this.groupActionName == 'Create') {
      //FOR ADD DEFAULT MEMBER
      this.addDefaultMember(ref);
    }

    //FOR DISABLE SUBSCRIBER IF ALREADY ADDED
    this.disableSubscribers(ref);
  }

  disableSubscribers(ref) {
    if (ref == 'Create' || ref == 'Discard' || ref == 'Edit') {
      this.subscribeStudentList.forEach(item => {
        item.isAdded = false;
      });
      this.addedMembers.forEach(element => {
        let subscriberStudent = this.subscribeStudentList.find(subscriber => {
          return subscriber._id == element._id;
        });
        if(subscriberStudent) {
          subscriberStudent.isAdded = true;
        }
      });
    }
  }

  initSubscribers(ref) {
    if (ref == 'Edit' || ref == 'Discard') {
      const leadMemberId = this.editTeamData.lead._id;
      const leadMemberIndex = this.editTeamData.members.findIndex(item => item._id == leadMemberId);
      this.leadMember = this.editTeamData.members[leadMemberIndex];
      this.editTeamData.members.splice(leadMemberIndex, 1);
      this.editTeamData.members.unshift(this.leadMember);

      this.editTeamData.members.forEach((elm) => {
        this.addedMembers.push(elm);
      });
    }
  }

  addDefaultMember(ref) {
    if (ref == 'Create' || ref == 'Discard') {
      /*let subscriberStudent = this.subscribeStudentList.find(subscriber => {
        return subscriber._id == this.loggedInUserId;
      });*/
      let subscriberStudent = {avatar: this.avatar, id: this.loggedInUserId, institute_type: '', name: this.fullName, organisation_name: '', role: this.role, username: this.username, _id: this.loggedInUserId};
      
      this.defaultSubscriber = subscriberStudent;
      this.addMemberToGroup(subscriberStudent);
    }
  }

  addMemberToGroup(subscriberStudent) {
    const index = this.addedMembers.findIndex(addedMember => addedMember._id === subscriberStudent._id);
    if (index == -1) {
      this.addedMembers.push(subscriberStudent);
      subscriberStudent.isAdded = true;
    }
  }

  removeMemberFromGroup(member) {
    const index = this.addedMembers.findIndex(addedMember => addedMember._id === member._id);
    if (index > -1) {
      this.addedMembers.splice(index, 1);
    }
    const indexSubscriber = this.subscribeStudentList.find(subscriberStudent => {
      return subscriberStudent._id === member._id
    });
    if (indexSubscriber) {
      indexSubscriber.isAdded = false;
    }
  }

  saveTeams() {
    const groupName = this.addTeamByStudnet.value.name.trim();

    if (!groupName) {
      this.notificationService.showNotification('info', 'You have not entered any team name please enter it.');
      return;
    }

    if (this.addedMembers.length == 0) {
      this.notificationService.showNotification('info', 'You have not added any member in the group.');
      return;
    }

    //PREPARE POST DATA
    let usersData = [];
    let groupRoomId = '';
    let actionLabel = 'created';
    if (this.groupActionName == 'Edit') {
      if (groupName.toLowerCase() == this.editTeamData.name.toLowerCase()) {
        if (this.editTeamData.cw_group_chat) {
          groupRoomId = this.editTeamData.cw_group_chat.room_id;
        }
      }
      actionLabel = 'updated';
    }
    this.addedMembers.forEach(element => {
      usersData.push(element._id);
    });
    const finalData = { team_name: groupName, team_logo: this.resourcePath, team_members: usersData, project_id: [this.viewItemId] };
    // console.log(finalData);
    // return;

    this.groupProcessing = true;
    this.passionProjectService.addTeam(finalData, this.groupActionName, this.editTeamData._id).subscribe(getTeams => {
      this.notificationService.showNotification('success', 'Team '+ actionLabel +' successfully');
      this.addedTeam();
      this.groupProcessing = false;
    },
      (error) => {
        this.groupProcessing = false;
        this.notificationService.showNotification('error', error.message);
      });
  }

  addedTeam() {
    this.teamStatus.emit({status: true});
    this.closeGroupCreateModel();
  }
  //CREATE AND EDIT TEAM |END

  //FOR SEARCH USER |START
  onStartSearch(data) {
    this.searchText = data.searchText;
    if(this.searchText.length > 0) {
      this.isSearchEnabled = true;
      this.getSubscribeStudent(this.groupActionName);
    }
    else {
      this.isSearchEnabled = false;
      this.searchText = '';
      this.subscribeStudentList = [];
    }
  }
  //FOR SEARCH USER |END

  handleError(error) {
    console.log(error);
  }

}
