<div class="new_UIstyle">
        <!-- Banner Section start -->
    <div class="banner banner-gradient banner-course-assement">
        <div class="container">
             <div class="banner-content">
                 <div class="banner-content-content">
                     <h2 class="white-text avenirBlack mb-1">{{reportName}}</h2>
                     <p class="white-text">{{shortDes}}</p>
                 </div>

                 <!-- <div class="banner-content-action btn-groups">
                     <button class="btn btn-primary btn-w-120" (click)="go_to_url()">Cancel</button>
                 </div> -->
             </div>
        </div>
     </div>
    <!-- Banner Section End -->

    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <form class="typo-16" [formGroup]="reportForm" [ngClass]="{submitting : loader}">
                        <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                            <span class="sr-only">Loading...</span>
                        </div>
                          <div class="mb-3">
                              <h6 class="avenirRoman mb-2 heading-18">Application Type <span class="required-star">*</span></h6>
                               <select class="custom-select form-control form-control-style-2 form-control-lg form-control-caret" formControlName="applicationType">
                                   <option value="">Select</option>
                                   <option value="mobile application">Mobile Application</option>
                                   <option value="web application">Web Application</option>
                               </select>
                          </div>
                          <div class="mb-3">
                              <h6 class="avenirRoman mb-2 heading-18">Type <span class="required-star">*</span></h6>
                              <select class="custom-select form-control form-control-style-2 form-control-lg form-control-caret" formControlName="type">
                                  <option value="">Select</option>
                                  <option value="bug">Bug</option>
                                  <option value="Suggesstion">Suggesstion</option>
                                  <option value="Query">Query</option>
                              </select>
                          </div>
                          <div class="mb-3">
                            <h6 class="avenirRoman mb-2 heading-18">Your report</h6>
                                <textarea rows="4" cols="12" class="form-control form-control-textarea form-control-style-2" formControlName="description"
                                    placeholder=" "></textarea>
                          </div>
                           <div class="mb-3">
                               <div class="MLSWrraper">
                                   <div class="MLSWrraper_add">
                                       <i class="fas fa-cloud-upload-alt"></i>
                                       <p-fileUpload name="myfile[]" #fileUpload maxFileSize="1000000000" cancelLabel="Clear"
                                           customUpload="true" (uploadHandler)="myUploader($event,fileUpload)">
                                       </p-fileUpload>
                                   </div>
                               </div>
                           </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>




<!-- <div class="banner">
    <div class="container text-center">
        <h1>Report</h1>
        <p>Have you found any Bug / Error / Non-working Functionality?</p>
    </div>
</div> -->

<!-- <div class="pageBody">
    <div class="container">
        <form class="mw480 floatedInputForm" [formGroup]="reportForm" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <dl>
                <dd>
                    <select class="custom-select" formControlName="applicationType">
                        <option value="">Select</option>
                        <option value="mobile application">Mobile Application</option>
                        <option value="web application">Web Application</option>
                    </select>
                    <label class="flLabel">Application Type</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <select class="custom-select" formControlName="type">
                        <option value="">Select</option>
                        <option value="bug">Bug</option>
                        <option value="Suggesstion">Suggesstion</option>
                        <option value="Query">Query</option>
                    </select>
                    <label class="flLabel">Type</label>
                </dd>
            </dl>

            <dl>
                <dd>
                    <textarea rows="4" cols="12" class="form-control" formControlName="description"
                        placeholder=" "></textarea>
                    <label class="flLabel">Your report</label>
                </dd>
            </dl>
            <dl>
                <dd>
                    <div class="MLSWrraper">
                        <div class="MLSWrraper_add">
                            <i class="fas fa-cloud-upload-alt"></i>
                            <p-fileUpload name="myfile[]" #fileUpload maxFileSize="1000000000" cancelLabel="Clear"
                                customUpload="true" (uploadHandler)="myUploader($event,fileUpload)">
                            </p-fileUpload>
                        </div>
                    </div>
                </dd>
            </dl>
        </form>
    </div>
</div> -->